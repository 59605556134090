import React, { useState, useEffect } from "react";
import FairDetailsCard from "./FairDetailsCard";
import styled from "styled-components";

interface Props {
	salesHistory: any;
	language: string;
}
const SalesHistoryCard = ({
	salesHistory,
	language
}: Props) => {

	const [hideSection, setHideSection] = useState<Boolean>(salesHistory.filter( (s:any) => s.sales !== 0 ).length > 0 ? false : true);

	const renderFairHistory = () => {
		let fairElements = [];
		for (let i = 0; i < salesHistory.length; i++) {
			const fair = salesHistory[i];
			if (fair.sales === 0) continue;
			const [startYear, startMonth, startDay] = fair.start.split("-");
			const formattedStartDate =
				language === "en"
					? `${startMonth}/${startDay}/${startYear}`
					: `${startYear}-${startMonth}-${startDay}`;
			const [endYear, endMonth, endDay] = fair.end.split("-");
			const formattedEndDate =
				language === "en"
					? `${endMonth}/${endDay}/${endYear}`
					: `${endYear}-${endMonth}-${endDay}`;
			const formattedSales =
				language === "en"
					? `$${Intl.NumberFormat("en-EN").format(fair.sales)}`
					: `${Intl.NumberFormat("fr-FR").format(fair.sales)} $`;
			const saleHistory = (
				<Row key={i}>
					<SaleTitle>
						{formattedStartDate} - {formattedEndDate}
					</SaleTitle>

					<SaleValue>{formattedSales}</SaleValue>
				</Row>
			);
			fairElements.push(saleHistory);
			
		}
		return fairElements;
		
	};

	if ( hideSection ) return null;
	else  return (
		<FairDetailsCard title={language === "en" ? "Sales History" : "Historique des ventes"}>
			{renderFairHistory()}
		</FairDetailsCard>
	);
};

const Row = styled.div`
	margin-bottom: 16px;
`;

const SaleTitle = styled.p`
	font-family: museo-sans;
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 19px;
	color: #333333;
	flex: 1;
`;

const SaleValue = styled(SaleTitle)`
	font-weight: 400;
`;

export default SalesHistoryCard;
