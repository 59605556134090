import React from "react";
import styled from "styled-components";
import FairDetailsCard from "./FairDetailsCard";
import RedTextButton from "../RedTextButton";
import { trackPdfDownload, trackViewOnlineTools } from "../../DumbleData";
import { useConfig } from "../../providers/ConfigContext";

interface Props {
	language: string;
}

type Links = {
	[key: string]: {
		[key: string] : string
	}
};

const WrapUpCard = ({ language }: Props) => {
	const { damAssetsOrigin } = useConfig();

	const handlePDFClick = (which: string) => {
		trackPdfDownload(which);
		const pdfs:Links = {
			"pdf1" : {
				"en": `${damAssetsOrigin}canadatoolkit/onlinetools/BF_ProductRewards_en.pdf`,
				"fr": `${damAssetsOrigin}canadatoolkit/onlinetools/BF_ProductRewards_fr.pdf`
			},
			"pdf2" : {
				"en": `${damAssetsOrigin}canadatoolkit/onlinetools/BF_WrapUpChecklist_en.pdf`,
				"fr": `${damAssetsOrigin}canadatoolkit/onlinetools/BF_WrapUpChecklist_fr.pdf`
			}
		}
	
		window.open(pdfs[which][language], "_blank");
	
	};

	const handleOnlineTools = () => {
		trackViewOnlineTools();
		window.open('https://bookfairstoolkit.scholastic.ca/cgi-bin/lansaweb?procfun+F4WEB02+FSGNW01', '_blank');
	}

	return (
			<FairDetailsCard title={language === "en" ? "Wrap Up" : "Récapitulatif"}>
				<Body>{language === "en" ? "After your Fair is over, make sure you place student reorders and fill out your Rewards Worksheet to calculate Fair sales, bonuses, and how many Scholastic Dollars you earned!" : "Une fois votre Festival terminé, assurez-vous de passer les commandes prépayées des élèves et de remplir votre formulaire de récompenses pour calculer les ventes du Festival, les primes et le nombre de dollars Scholastic que vous avez gagnés!"}</Body>
				<Body><RedTextButton handleClick={handleOnlineTools}>{language === "en" ? "Visit Online Tools" : "Consulter les outils en ligne"}</RedTextButton></Body>
				<Body>{language === "en" ? "Helpful Guides" : "Guides utiles"}
					<RedTextButton handleClick={() => handlePDFClick('pdf1')} className="wrapUp">{language === "en" ? "Download PDF: Placing a Product Rewards Order Through Online Tools" : "Télécharger le PDF : Passer une commande de récompenses en produits à l’aide des outils en ligne"}</RedTextButton>
					<RedTextButton handleClick={() => handlePDFClick('pdf2')} className="wrapUp">{language === "en" ? "Download PDF: Wrap-up Checklist" : "Télécharger le PDF : Liste de vérification pour la fin de votre Festival!"}</RedTextButton>
				</Body>
			</FairDetailsCard>	
	);
};

const Body = styled.div`
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
	color: #333333;
	overflow: hidden;
	margin-bottom: 16px;
`;

export default WrapUpCard;