import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useConfig } from "../../../providers/ConfigContext";
import { Page } from "../Page";
import Heading from "../../Heading";
import PageIcon from "../PageIcon";
import VolunteerIcon from "../../../images/Volunteers.svg";
import DownloadIcon from "../../../images/Download.svg";
import BodyText from "../../BodyText";
import TextInput from "../../TextInput";
import { RedButton } from "../../Button";
import TextLink from "../../TextLink";
import axios from "axios";
import SaveChangesModal from "../SaveChangesModal";
import Prompt from "../Prompt";
import { trackDiscardChanges, trackDownloadPaperSignUpForm, trackSaveAndPublishButton, trackSkipButton, trackUrlEntry, updatePageType, updateUserLoginStatus, updateUserFairId, updateUserAccountId } from "../../../DumbleData";
import { handleGenericErrors, handleGenericSuccess } from "../../../utils/ErrorUtility";

interface Props {
	language: string;
	builderNavDisabled: boolean;
	setBuilderNavDisabled: any;
	builderNextLocation: string;
	setBuilderNextLocation: any;
}

export const VolunteerPage = ({ 
	language, 
	builderNavDisabled, 
	setBuilderNavDisabled,
	builderNextLocation,
	setBuilderNextLocation}: Props) => {
	const shareNextLocation = "/customize/share";
	setBuilderNextLocation(shareNextLocation);
	const { damAssetsOrigin } = useConfig();
	const navigate = useNavigate();	
	const [urlError, setUrlError] = useState('');
	const [attemptedToSubmit, setAttemptedToSubmit] = useState(false);
	const [saveChangesModalOpen, setSaveChangesModalOpen] = useState(false);
	const [allowNavigation, setAllowNavigation] = useState(false);

	const [volunteerUrl, setVolunteerUrl] = useState("");
	const [savedUrl, setSavedUrl] = useState(volunteerUrl);

	// get request
	useEffect(() => {
		const getVolunteerPageData = async () => {
			window.scrollTo(0, 0);
			axios({
				method: 'GET',
				withCredentials: true,
				url: `${process.env.REACT_APP_ENV_BASE_URL}/api/user/fairs/current/homepage/volunteers`
			})
			.then(function(response){
				if( response.status === 200 ) {
					setVolunteerUrl(response.data.url);
					setSavedUrl(response.data.url);
					updateUserLoginStatus(true);
					updateUserFairId(response.data.fairId);
					updateUserAccountId(response.data.bookfairAccountId)
					updatePageType("homepage-builder");
				} else {
					handleGenericSuccess(response);
				}
			})
			.catch(function(error){
				handleGenericErrors(error);
			});
		}
		getVolunteerPageData();
	}, []);

	const dataValidate = useCallback(() => {
		let isAllValid = true;

		//validates url
		var pattern = new RegExp(
			"^(https?:\\/\\/)?" + // protocol
				"((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
				"((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
				"(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
				"(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
				"(\\#[-a-z\\d_]*)?$",
			"i"
		); // fragment locator
		//Url optional so if there is no url inputted, remove error and return true for all valid
		if (!volunteerUrl) {
			setUrlError('');
			isAllValid = true;
		} else if (!pattern.test(volunteerUrl)) {
			if(language === 'en') {
				setUrlError("Please enter a valid URL link.");
			} else {
				setUrlError("Veuillez saisir un lien URL valide.");
			}
			isAllValid = false;
		} else {
			setUrlError('');
		}
		return isAllValid;
	}, [volunteerUrl, language]);

	// calls validation function and then sends post request if all inputs are valid, otherwise notifies useEffect to check for errors
	const submitVolunteerData = async () => {
		if (dataValidate()) {
			let processedUrl = volunteerUrl;
			// if url is not blank, and does not include http or https, pre-pend https
			if ( processedUrl !== "" && !(processedUrl.includes("http://") || processedUrl.includes("https://")) ) {
				processedUrl = "https://" + processedUrl;
			}
			axios({
				method: 'PUT',
				withCredentials: true,
				url: `${process.env.REACT_APP_ENV_BASE_URL}/api/user/fairs/current/homepage/volunteers`,
				data: { url: processedUrl }
			})
				.then((response) => {
					if (response.status === 200) {
						setSaveChangesModalOpen(false);
						setBuilderNavDisabled(false);
						setSavedUrl(volunteerUrl);
						setAllowNavigation(true);
					} else {
						handleGenericSuccess(response);
					}
				})
				.catch((err) => {
					console.log(err);
					handleGenericErrors(err);
				});
		} else {
			setSaveChangesModalOpen(false);
			setBuilderNavDisabled(false);
			setAttemptedToSubmit(true);
		}
	};

	//If submitted at least once, checks for input errors at every change of input field
	useEffect(() => {
		if (attemptedToSubmit) {
			dataValidate();
		}
	}, [attemptedToSubmit, dataValidate, volunteerUrl]);

	//
	// Logic to handle navigation and rendering the SaveChangesModal
	//

	//Allows for navigation to next page after clicking discard changes on save changes modal
	useEffect(() => {
		if ((volunteerUrl === savedUrl) && allowNavigation) {
			navigate(builderNextLocation);
		}
	}, [
		volunteerUrl,
		savedUrl,
		saveChangesModalOpen,
		allowNavigation,
		navigate,
		builderNextLocation,
	]);

	// when save changes modal opens, scroll to top of page
	useEffect(() => {
		if (saveChangesModalOpen) {
			window.scrollTo({
				top: 0,
				left: 0,
				behavior: 'smooth' // or 'auto' or 'instant'
			});
		}
	}, [saveChangesModalOpen]);

	///////////////////////////

	return (
		<>
			<StyledVolunteerPage>
				<PageIcon
					src={`${damAssetsOrigin}canadatoolkit/homepagebuilder/Volunteers.svg`}
					alt="Volunteer Form Icon"
					defaultImg={VolunteerIcon}
				/>
				<Heading>
					{ language === 'en' ? 'Recruit Volunteers' : 'Recruter des bénévoles' }
				</Heading>
				<Wrapper>
					<BodyText>
						{language === 'en' ? 
						`Volunteers are critical to the success of your
						Book Fair. You'll need extra hands for setup,
						promotions, staffing, and packing up. Enable a
						volunteer sign-up form to appear on your
						homepage or post a link to your own form in your
						welcome message.` : 
						`Les bénévoles sont essentiel·les au succès de votre 
						Festival du Livre. Vous aurez besoin d’aide pour 
						l’installation, les promotions, le service et le 
						remballage. Affichez un formulaire d’inscription pour 
						les bénévoles sur votre page d’accueil ou publiez un 
						lien vers votre propre formulaire dans votre message 
						de bienvenue.`}
					</BodyText>
					{/* hide download button when save changes is open */}
					{!saveChangesModalOpen ?
						<Download
							target="_blank"
							href={language === 'en' ?
									`${damAssetsOrigin}canadatoolkit/homepagebuilder/EN_Volunteer_Form.pdf` :
									`${damAssetsOrigin}canadatoolkit/homepagebuilder/FR_Volunteer_Form.pdf`	}
							download
							onClick={() => trackDownloadPaperSignUpForm()}
						>
							<DownloadImage src={DownloadIcon} />
							{ language === 'en' ? 'Download a paper sign-up form' : `Télécharger un formulaire d’inscription papier à imprimer` }
						</Download> 
					: ""}
					<TextInput
						label={ language === 'en' ? 
									"Enter a URL link to an external volunteer sign-up form (optional)" :
									"Saisissez un lien URL vers un formulaire externe d’inscription pour les bénévoles (facultatif)" }
						value={volunteerUrl}
						onChange={setVolunteerUrl}
						disabled={saveChangesModalOpen}
						error={ urlError !== '' ? urlError : undefined }
					/>
				</Wrapper>
				<StyledRedButton
					handleClick={() => {
						trackSaveAndPublishButton();
						setBuilderNextLocation(shareNextLocation);
						(savedUrl !== volunteerUrl) && trackUrlEntry();
						submitVolunteerData();
					}}
					disabled={saveChangesModalOpen}
				>
					{language === 'en' ? 'Save & Publish' : 'Enregistrer et publier' }
				</StyledRedButton>
				<TextLink
					handleClick={() => {
						trackSkipButton();
						if ( volunteerUrl !== savedUrl) {
							setBuilderNextLocation(shareNextLocation);
							setSaveChangesModalOpen(!saveChangesModalOpen);
							setBuilderNavDisabled(!builderNavDisabled);
						} else {
							navigate(shareNextLocation);
						}
					}}
					disabled={saveChangesModalOpen}
				>
					{ language === 'en' ? 'Skip' :'Ignorer' } 
				</TextLink>
			</StyledVolunteerPage>
			<Prompt
				message={() => {
					setBuilderNavDisabled(true);
					setSaveChangesModalOpen(true);
				}}
				when={volunteerUrl !== savedUrl}
			/>
			<SaveChangesModal
				saveAndPublish={submitVolunteerData}
				discardChanges={() => {
					setVolunteerUrl(savedUrl);
					setBuilderNavDisabled(false);
					setAllowNavigation(true);
					trackDiscardChanges();
				}}
				modalIsOpen={saveChangesModalOpen}
				handleModal={() => {
					setSaveChangesModalOpen(!saveChangesModalOpen);
					setBuilderNavDisabled(!builderNavDisabled);
				}}
				language={language}
			/>
		</>
	);
};

/*
	Page Specific Styles
*/
const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: -12px;
	width: 100%;
	gap: 24px;
	max-width: 440px;
	@media (max-width: 1020px) {
		max-width: 415px;
	}
`;
const Download = styled.a`
	display: inline-flex;
	flex-direction: row;
	align-items: center;
	font-size: 13px;
	font-weight: 400;
	color: #e81111;
	text-decoration: none;
	&:hover {
		cursor: pointer;
		color: #b90000;
		border-bottom: 1px solid #b90000;
	}
	&:focus,
	:focus-visible {
		color: #b90000;
		border: 2px solid #b90000;
		box-sizing: border-box;
		border-radius: 4px;
		outline: none;
		padding: 1px;
		margin-right: 10px;
	}
	&:active {
		color: #660000;
		border-bottom: 1px solid #660000;
		padding-bottom: 3px;
	}
`;
const DownloadImage = styled.img`
	padding-right: 11.5px;
	margin-top: -1px;
`;
const StyledRedButton = styled(RedButton)`
	@media (max-width: 719px) {
		width: 100%;
		max-width: 415px;
	}
`;
const StyledVolunteerPage = styled(Page)`
	padding: 48px 0;
	gap: 24px;
	@media (max-width: 1020px) {
		padding: 48px 40px;
		margin-top: 80px;
	}
	@media (max-width: 719px) {
		padding: 32px 20px;
	}
`;
