import React from "react";
import styled from "styled-components";
import { ModalContainer } from "../../ModalContainer";
import { WhiteButton } from "../../Button";

interface Props {
	language: string;
	closeModal: () => void;
}

export const EmailSuccessModal = ({ language, closeModal }: Props) => {
	return (
		<ModalContainer closeModal={closeModal}>
			<StyledContainer>
				<StyledText>{language === "en" ? "Thank you, your email has been sent." : "Merci, votre courriel a été envoyé."}</StyledText>
				<StyledButtonContainer>
					<StyledWhiteButton
						whiteBackground={true}
						handleClick={closeModal}
					>
						Close
					</StyledWhiteButton>
				</StyledButtonContainer>
			</StyledContainer>
		</ModalContainer>
	);
};

const StyledContainer = styled.div`
	width: 600px;
	padding: 24px;
	box-sizing: border-box;
	@media (max-width: 719px) {
		width: 100vw;
	}
`;

const StyledText = styled.p`
	padding-top: 40px;
	padding-bottom: 32px;
	font-weight: 700;
	font-size: 16px;
	line-height: 21px;
	color: #333;
`;

const StyledButtonContainer = styled.div`
	display: flex;
	justify-content: flex-end;
`;

const StyledWhiteButton = styled(WhiteButton)`
	float: right;
	@media (max-width: 719px){
		width: 100%;
	}
`;
