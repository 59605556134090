import React from "react";
import styled from "styled-components";
import moment from "moment";
import EllipsesIcon from "../../images/EllipsesIcon.svg";
import { useConfig } from "../../providers/ConfigContext";
import EventCategories from "../HomepageBuilder/AddEvent/EventCategories.json";
import AddToCalendarButton from "./AddToCalendarButton";
import Tooltip from "./Tooltip";
import { convertStringToDate } from "../../utils/DateConversion";
import { generateCalendarFile } from "../../utils/GenerateCalendarFile";
import { trackEventCalendarIcon } from "../../DumbleData";

type Props = {
	fairId?: string;
	scheduleDate: string;
	createDate?: Date;
	eventCategory: string;
	eventName: string;
	startTime?: string;
	endTime?: string;
	description?: string;
	id?: number;
	url?: string;
	className?: string | undefined;
	eventPassed?: boolean;
	isActive: boolean;
	handleMoreDetails?: (
		eventName: string,
		description: string,
		scheduleDate: string,
		formattedTime: string,
		startTime: string,
		endTime: string,
		eventCategory: string
	) => void;
  language: string;
};

const EventCard = ({
	scheduleDate,
	eventCategory,
	eventName,
	startTime,
	endTime,
	description,
	url,
	eventPassed,
	isActive,
	handleMoreDetails,
  language,
}: Props) => {
	const { damAssetsOrigin } = useConfig();

	const handleAddToCalendar = () => {
		trackEventCalendarIcon(eventName);
		generateCalendarFile(
			eventName,
			description,
			scheduleDate,
			startTime,
			endTime,
			isOpenOrCloseEvent
		);
	};

	const isOpenOrCloseEvent =
		eventCategory === "Opening Day" || eventCategory === "Closing Day";
	moment.locale(language);
	const formattedDate = language === "en" ? moment(scheduleDate).format("ddd MMM DD").toString() : moment(scheduleDate).format("ddd D MMM").toString().replaceAll(".",'');

	const formattedTime = language === "en" ? `${moment(startTime, "HH:mm:ss").format("h:mm A")} - ${moment(endTime, "HH:mm:ss").format("h:mm A")}` : `${moment(startTime, "HH:mm:ss").format("HH:mm")} - ${moment(endTime, "HH:mm:ss").format("HH:mm")}`;

	let iconLink;
	const eventInfo: any = EventCategories.find(
		(event) => event.englishType === eventCategory
	);
	if (eventInfo) {
		if (isOpenOrCloseEvent && language === "fr") {
			iconLink = (eventCategory === "Opening Day") ? "LiveHomepage/Schedule/90x90px/jour-1.png" : "LiveHomepage/Schedule/90x90px/dernier-jour.png";
		} else {
			iconLink = eventInfo.eventCardIcon;
		}
	}

	// Runs the modal handler from props
	const clickMoreDetails = () => {
		handleMoreDetails &&
			handleMoreDetails(
				eventName,
				description || "",
				scheduleDate,
				formattedTime || "",
				startTime || "",
				endTime || "",
				eventCategory
			);
	};

	return (
		<>
			<StyledEventCard className={eventPassed ? "eventPassed" : ""}>
				<CardHeader>
					<EventDate>{formattedDate}</EventDate>
				</CardHeader>
				<StyledIcon
					src={`${damAssetsOrigin}canadatoolkit/${iconLink}`}
				/>
				<Title>{eventName}</Title>
				{!isOpenOrCloseEvent && <EventTime>{formattedTime}</EventTime>}
				{!eventPassed && (
					<CardFooter>
						<AddToCalendarButton
							isOpenOrCloseEvent={isOpenOrCloseEvent}
							handleAddToCalendar={handleAddToCalendar}
							tabindex={isActive ? 0 : -1}
							eventName={eventName}
						/>
						{!isOpenOrCloseEvent && (
							<>
								<StyledRule />
								<MoreDetailsContainer>
									<Tooltip
										openOrCloseEvent={
											isOpenOrCloseEvent
												? "openOrCloseEvent"
												: undefined
										}
									>
										See more details
									</Tooltip>
									<MoreDetailsButton
										onClick={clickMoreDetails}
										tabIndex={isActive ? 0 : -1}
									>
										<MoreDetailsIcon
											src={EllipsesIcon}
											alt={`See more details for ${eventName}`}
										/>
									</MoreDetailsButton>
								</MoreDetailsContainer>
							</>
						)}
					</CardFooter>
				)}
			</StyledEventCard>
		</>
	);
};

const StyledEventCard = styled.div`
	display: inline-flex;
	flex-direction: column;
	align-items: center;
	position: relative;
	width: 172px;
	margin-right: 15px;
	height: 308px;
	padding: 20px 16px;
	background-color: #ffffff;
	box-sizing: border-box;
	box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
	&.eventPassed {
		filter: grayscale(1);
	}
	@media (max-width: 670px) {
		display: flex;
		align-content: flex-start;
		justify-content: center;
		flex-flow: column wrap;
		width: 335px;
		height: 126px;
		margin: 0px 0px 12px;
		padding: 16px 8px 16px 16px;
	}
`;

const CardHeader = styled.div`
	border-bottom: 1px solid #ffd7c7;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-bottom: 12px;
	@media (max-width: 670px) {
		order: 2;
		align-self: flex-start;
		flex: 0 auto;
		margin-bottom: 9px;
	}
`;

const EventDate = styled.p`
	font-family: museo-sans;
	font-weight: 600;
	font-size: 18px;
	line-height: 21px;
	text-align: center;
	color: #015e8c;
	margin-bottom: 9px;
	@media (max-width: 670px) {
		font-size: 13px;
		line-height: 15px;
		margin-bottom: 4px;
	}
	&::first-letter {
		text-transform: capitalize;
	}
`;

const Title = styled.p`
	font-family: museo-sans;
	font-weight: 600;
	font-size: 21px;
	line-height: 24px;
	text-align: center;
	color: #333333;
	white-space: break-spaces;
	display: flex;
	justify-content: center;
	@media (max-width: 670px) {
		order: 2;
		font-size: 20px;
		line-height: 23px;
		width: 173px;
		margin-bottom: 4px;
		text-align: left;
		justify-content: flex-start;
	}
`;

const EventTime = styled.p`
	font-family: museo-sans;
	font-weight: 600;
	font-size: 13px;
	line-height: 15px;
	text-align: center;
	color: #333333;
	@media (max-width: 670px) {
		order: 2;
		flex: 1 auto;
		width: 173px;
		text-align: left;
		justify-content: flex-start;
	}
`;

const StyledIcon = styled.img`
	margin-bottom: 12px;
	margin-left: auto;
	margin-right: auto;
	pointer-events: none;
	@media (max-width: 670px) {
		order: 1;
		width: 72px;
		margin-right: 16px;
		margin-bottom: 0;
		align-self: flex-start;
	}
`;

const CardFooter = styled.div`
	display: flex;
	justify-content: space-around;
	align-items: center;
	height: 40px;
	width: 100%;
	margin-top: auto;
	@media (max-width: 670px) {
		width: 48px;
		flex-direction: column;
		flex: 1;
		order: 3;
		border-left: 1px solid #ffd7c7;
		align-items: flex-end;
	}
`;

const StyledRule = styled.hr`
	width: 1px;
	height: 32px;
	border: none;
	background: #ffd7c7;
	@media (max-width: 670px) {
		width: 40px;
		height: 1px;
	}
`;

const MoreDetailsContainer = styled.div`
	border-radius: 40px;
	position: relative;
	&:hover .tooltip {
		visibility: visible;
		opacity: 1;
	}
`;

const MoreDetailsButton = styled.button`
	border: none;
	border-radius: 40px;
	width: 40px;
	height: 40px;
	background: none;
	&:hover {
		cursor: pointer;
		box-shadow: 0 0 6px #d7d7d7;
		img {
			filter: invert(24%) sepia(90%) saturate(3339%) hue-rotate(347deg)
				brightness(84%) contrast(120%);
		}
	}
	&:focus {
		outline: none;
		img {
			outline: 2px solid #e81111;
			outline-offset: 3px;
			filter: invert(24%) sepia(90%) saturate(3339%) hue-rotate(347deg)
				brightness(84%) contrast(120%);
		}
	}
	@media (max-width: 670px) {
		border-left: none;
		margin-top: 6px;
	}
`;

const MoreDetailsIcon = styled.img`
	width: 22px;
	margin-left: auto;
	margin-right: auto;
	border-radius: 2px;
`;

export default EventCard;
