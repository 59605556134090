import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import styled from "styled-components";
import moment from "moment";
import FairInformationComponent from "../../Homepage/FairInformationComponent";
import BeforeTheFairComponent from "../../Homepage/BeforeTheFairComponent";
import ChairpersonContactComponent from "../../Homepage/ChairpersonContactComponent";
import VolunteerSection from "../../Homepage/VolunteerSection";
import PreviewFlyerComponent from "../../Homepage/PreviewFlyerSection/PreviewFlyerComponent";
import SocialShareIconsComponent from "../../Homepage/SocialShareIconsComponent";
import { useConfig } from "../../../providers/ConfigContext";
import { updateFairTotalEventsCreated, updatePageType, updateUserLoginStatus } from "../../../DumbleData";

interface Props {
	language: string;
	setLanguage: any;
}

export const Homepage = ({ language, setLanguage }: Props) => {
	const { homepageURL } = useParams();
	const [homepageData, setHomepageData] = useState<any>(null);
	const [fairInfoData, setFairInfoData] = useState<any>(null);
	const [goalData, setGoalData] = useState<any>(null);

	const [isLoading, setIsLoading] = useState(true);
	const [isError, setIsError] = useState(false);
	const [isAfter, setIsAfter] = useState(false);

	// get request
	useEffect(() => {
		const getHomepageData = async() => {
			try {				
				await axios
					.get(`${process.env.REACT_APP_ENV_BASE_URL}/api/public/homepage/${homepageURL}`)
					.then((response) => {
						setHomepageData(response.data);
						setFairInfoData(response.data.onlineHomepage.fairInfo);
						setGoalData(response.data.onlineHomepage.goalsAndSales);
						updateFairTotalEventsCreated(response.data.onlineHomepage.events.length);
						updateUserLoginStatus(true);
						updatePageType("live-homepage");
						// after fair logic
						const now = moment();
						const endOfFair = moment(response.data.fairInfo.end);
						setIsAfter(endOfFair.diff(now, "days") < 0);
					})
					.catch((error) => {
						console.log(error);
						setIsError(true);
					})
					.finally(() => {
						setIsLoading(false);
					})
			} catch(error) {
				console.log(error);
				setIsError(true);
			}
		}
		if(document.location.pathname.includes('/bf-fr/')) {
			setLanguage('fr');
		} else if (document.location.pathname.includes('/bf/')) {
			setLanguage('en');
		}
		getHomepageData();
	}, [homepageURL, setLanguage]);

	const { damAssetsOrigin } = useConfig();

	if (isLoading) {
		return (<StyledHomepage></StyledHomepage>);
	} else if (isError) {
		return (<StyledHomepage>"Fair not found"</StyledHomepage>);
	} else {
		return (
			<StyledHomepage>
				{isAfter ? ( language === "en" ?
					<StyledHeaderImage
						src={`${damAssetsOrigin}canadatoolkit/LiveHomepage/intro/image_after.png`}
						alt="Thank you for supporting our Book Fair!"
					></StyledHeaderImage> : <StyledHeaderImage
						src={`${damAssetsOrigin}canadatoolkit/LiveHomepage/intro/img_homepage-after_ca.svg`}
						alt="Merci De Soutenir Notre Festival Du Livre!"
					></StyledHeaderImage>
				) : (
	        language === "en" ? 
					<StyledHeaderImage
						src={`${damAssetsOrigin}canadatoolkit/LiveHomepage/intro/image_welcome.png`}
						alt="We're having a Book Fair!"
					></StyledHeaderImage> : <StyledHeaderImage
						src={`${damAssetsOrigin}canadatoolkit/LiveHomepage/intro/image_welcome_ca.svg`}
						alt="Nous Organizons Un Festival Du Livre!"
					></StyledHeaderImage>
				)}
				<FairInformationComponent
					fairInfoData={fairInfoData}
					goalData={goalData}
					afterFair={isAfter}
					language={language}
				/>
	      {language === "en" ?
				<StyledFairInfoGraphic
					src={`${damAssetsOrigin}canadatoolkit/LiveHomepage/intro/img_hero.png`}
					alt=""
				></StyledFairInfoGraphic> : <StyledFairInfoGraphic
					src={`${damAssetsOrigin}canadatoolkit/LiveHomepage/intro/img_hero_ca.jpg`}
					alt=""
				></StyledFairInfoGraphic>}

				<SocialShareIconsComponent
					customUrl={homepageData.onlineHomepage.fairUrl}
					language={language}
				/>

				{!isAfter && (
					<BeforeTheFairComponent
						fairId={parseInt(homepageData.fairInfo.id)}
						bookfairAccountId={parseInt(homepageData.fairInfo.bookfairAccountId)}
						startDate={fairInfoData.start}
						endDate={fairInfoData.end}
						eventsData={homepageData.onlineHomepage.events}
						url={homepageData.onlineHomepage.fairUrl.english}
						language={language}
					/>
				)}

				{!isAfter && (
					<PreviewFlyerComponent
						language={language}
					/>
				)}

				{!isAfter && homepageData.onlineHomepage.volunteerUrl && (
					<VolunteerSection
						language={language}
						volunteerForm={homepageData.onlineHomepage.volunteerUrl}
					/>
				)}

				<ChairpersonContactComponent
					contactData={{
						firstName: homepageData.onlineHomepage.contactInfo.firstName,
						lastName: homepageData.onlineHomepage.contactInfo.lastName,
						displayPhoneNumber:
							homepageData.onlineHomepage.contactInfo.displayPhoneNumber,
						displayName: homepageData.onlineHomepage.contactInfo.displayName,
						phoneNumber: homepageData.onlineHomepage.contactInfo.phoneNumber,
						email: homepageData.onlineHomepage.contactInfo.email,
					}}
					fairInfoData={{
						cash: homepageData.onlineHomepage.fairInfo.paymentOptions.cash,
						checks: homepageData.onlineHomepage.fairInfo.paymentOptions.cheque,
						creditCard: homepageData.onlineHomepage.fairInfo.paymentOptions.card,
						schoolName: homepageData.onlineHomepage.fairInfo.name,
						startDate: new Date(homepageData.onlineHomepage.fairInfo.start),
						endDate: new Date(homepageData.onlineHomepage.fairInfo.end),
						payableTo: homepageData.onlineHomepage.fairInfo.name,
					}}
					afterFair={isAfter}
					fairId={parseInt(homepageData.fairInfo.id)}
					language={language}
				/>
			</StyledHomepage>
		);
	}
};

const StyledHeaderImage = styled.img`
	width: 100%;
	max-width: 920px;
	margin-top: 8px;
`;

const StyledFairInfoGraphic = styled.img`
	width: 100%;
	max-width: 920px;
	margin: 19px 0 -5px 0;
`;

const StyledHomepage = styled.div`
	grid-column: 1/24;
	box-sizing: border-box;
	background-color: #77d7e0;
	text-align: center;
`;

export default Homepage;
