import styled from "styled-components";
import { RedButton } from "../Button";
import { useNavigate } from "react-router-dom";
import { trackCoaConfirmationStartPlanningYourFairButton } from "../../DumbleData";

interface Props {
    language: string;
}

const COAConfirmed = ({language} : Props) => {
    const nextLocation = "/fair-details";
    const navigate = useNavigate();

    const handleNavigation = () => {
        trackCoaConfirmationStartPlanningYourFairButton();
        navigate(nextLocation);
    };
    
	return (
        <StyledOverlay>
            <StyledContent>
                <ConfirmationHeading>{language === "en" ? "Your Book Fair is Confirmed" : "Votre Festival du Livre est confirmé"}</ConfirmationHeading>
                <ConfirmationText>{language === "en" ? `Thanks for scheduling a Scholastic Book Fair. We're happy to be your 
                partners in bringing the Fair experience to your school. Let's start planning your Fair!` : 
                `Merci d’avoir réservé un Festival du Livre Scholastic. Nous sommes heureux de collaborer avec 
                vous afin de faire vivre l’expérience d’un Festival à votre école. Maintenant, commençons à 
                planifier votre Festival!`}</ConfirmationText>
                <StyledRedButton handleClick={handleNavigation}>
                    {language === "en" ? "Start Planning Your Fair" : "Commencer à planifier votre Festival"}
                </StyledRedButton>
            </StyledContent>
        </StyledOverlay>
    )
};

export default COAConfirmed;

const StyledOverlay = styled.div`
	position: absolute;
    z-index: 100;
    top: 132px;
    left: 0;
    bottom: 0;
    background-color: white;
    width: 100%;
    margin: 0 auto;
    @media (max-width: 719px){
    	top: 145px;
    }
`;

const StyledContent = styled.div`
	max-width: 920px;
	margin: 0 auto;
	padding-top: 58px;
	@media (max-width: 1279px) {
		padding: 0 40px;
		padding-top: 48px;
	}
	@media (max-width: 719px) {
		padding: 0 20px;
		padding-top: 32px;
	}
`;

const ConfirmationHeading = styled.p`
    font-size: 39px;
    font-weight: 400;
    line-height: 42px;
`;

const ConfirmationText = styled.p`
    margin-top: 24px;
    font-size: 17px;
    font-weight: 300;
    line-height: 25px;
`;

const StyledRedButton = styled(RedButton)`
    margin-top: 32px;
`;