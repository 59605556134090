import React, { useState, useMemo, useEffect, useCallback } from "react";
import axios from "axios";
import { useConfig } from "../../providers/ConfigContext";
import styled from "styled-components";
import { RedButton } from "../Button";
import TextInput from "../TextInput";
import { trackRequestPasswordModalSubmit, trackRequestPasswordModalExitIcon } from "../../DumbleData";

interface Props {
	saveAndPublish?(): any;
	discardChanges?(): any;
	modalIsOpen: boolean;
	handleModal(): any;
	language?: string;
}

const RequestPasswordModal = ({
	modalIsOpen,
	handleModal,
	language
}: Props) => {
	const { damAssetsOrigin } = useConfig();
	const [emailAddress, setEmailAddress] = useState("");
	const [errorEmailAddress, setErrorEmailAddress] = useState<string>();
	const [accountNum, setAccountNum] = useState("");
  	const [apiError, setApiError] = useState<string>();

	const requestPassword = () => {
		axios({
		method: 'post',
		url: '/api/user/password-reset',
		data: {
			email: emailAddress,
			accountNumber: accountNum
		}
		})
		.then(function(response) {
			if ( response?.data?.message === "Password generation successful" ) {
				setEmailAddress("");
				setAccountNum("");
				handleModal();

			}
		})
		.catch(function(error) {
		setApiError(language === "en" ? 'Invalid email address, please try again' : 'Adresse courriel non valide. Essayez à nouveau.');
		});
	};
	const isValid = useMemo(
		() =>
			/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(emailAddress) &&
			accountNum.trim().length > 0,
		[emailAddress, accountNum]
	);
	const validateNumString = (value: string) => {
		const re = /^[0-9\b]+$/;
		return value === "" || re.test(value);
	};
	const validateField = useCallback((field: string) => {
		if (field === "email") {
			if (
				/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(emailAddress)
			) {
				setErrorEmailAddress(undefined);
			} else {
				setErrorEmailAddress(
					`${
						language === "en"
							? "Please enter a valid email address."
							: "Veuillez inscrire une adresse courriel valide."
					}`
				);
			}
		}
	}, [emailAddress, language]);

  useEffect(() => {
      if ( errorEmailAddress ) {
        validateField("email");
      } else if ( apiError ) {
        setApiError(language === "en" ? 'Invalid email or account number, please try again' : 'Adresse courriel ou numéro de compte non valide. Essayez à nouveau.');

    }
	}, [language, errorEmailAddress, apiError, validateField]);

	return (
		<>
			{modalIsOpen && (
				<StyledModal>
					<Container>
						<Row $align="right">
							<StyledXButton
								$source={`${damAssetsOrigin}canadatoolkit/global/nav-close.svg`}
								onClick={() => {
									setEmailAddress("");
									setAccountNum("");
                  					setApiError(undefined);
									trackRequestPasswordModalExitIcon();
									handleModal();
								}}
							>
								X
							</StyledXButton>
						</Row>
						<StyledTextBold>
							{language === "en"
								? "Request Password"
								: "Demander un mot de passe"}
						</StyledTextBold>
						{language === "en" ? (
							<StyledText>
								To request your password, please supply both
								your email address and school account number.
								<br />
								If you do not have that information, please
								contact your Consultant.
							</StyledText>
						) : (
							<StyledText>
								Pour demander votre mot de passe, veuillez
								fournir votre adresse courriel et le numéro de
								compte de votre école.
								<br />
								Si vous ne possédez pas cette information, veuillez contacter votre conseiller·ère.
							</StyledText>
						)}
            <Row>
              {apiError && <StyledErrorText>{apiError}</StyledErrorText>}
            </Row>
						<Row>
							<TextInput
								label={
									language === "en"
										? "Email Address (Required)"
										: "Adresse courriel (obligatoire)"
								}
								value={emailAddress}
								required={true}
								onChange={(v: string) => {
									setEmailAddress(v);
								}}
								onBlur={() => {
									validateField("email");
								}}
								error={errorEmailAddress}
							/>
						</Row>
						<Row>
							<TextInput
								label={
									language === "en"
										? "Account Number (Required)"
										: "Numéro de compte (obligatoire)"
								}
								value={accountNum}
								required={true}
								onChange={(v: string) => {
									if (validateNumString(v)) {
										setAccountNum(v);
									}

									validateField("account");
								}}
							/>
						</Row>
						<RowWithButton>
							<StyledRedButton
								disabled={!isValid}
								handleClick={() => {
									requestPassword();
									trackRequestPasswordModalSubmit();
								}}
							>
								{language === "en" ? "Submit" : "Soumettre"}
							</StyledRedButton>
						</RowWithButton>
					</Container>
				</StyledModal>
			)}
		</>
	);
};

/*
  Page Specific Styles
*/
const StyledText = styled("p")`
	font-family: museo-sans;
	font-weight: 300;
	font-size: 17px;
	line-height: 25px;
	margin-bottom: 16px;
`;

const StyledTextBold = styled("p")`
	font-family: museo-sans;
	font-weight: 600;
	font-size: 16px;
	line-height: 19px;
	margin-bottom: 16px;
`;

const StyledErrorText = styled("p")`
	font-size: 14px;
	line-height: 19px;
	text-align: center;
	color: rgb(232, 17, 17);
	font-weight: 300;
`;

const RowWithButton = styled("div")`
	margin-top: 16px;
	text-align: right;
`;

const StyledRedButton = styled(RedButton)`
	width: 181px;
`;

const StyledXButton = styled.button<{ $source: string }>`
	width: 24px;
	height: 24px;
	background-image: ${(props) => `url(${props.$source})`};
	border: none;
	background-color: transparent;
	text-indent: -99999em;
	&:hover {
		cursor: pointer;
	}
`;

const StyledModal = styled.div`
	top: 0;
	position: fixed;
	z-index: 2000;
	background-color: rgba(0, 0, 0, 0.7);
	width: 100%;
	height: 100%;
`;

const Container = styled.div`
	top: 50%;
	max-width: 593px;
	position: relative;
	background: #fff;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	padding: 24px;
	margin: -212px auto 0 auto;
	grid-column: 1/24;
	box-sizing: border-box;
	z-index: 99999;
	@media (max-width: 719px) {
		top: 0;
		bottom: 0;
    margin: 0;
		max-width: 100%;
		height: 100vh;
	}
`;

const Row = styled.div<{ $align?: string }>`
	margin-bottom: 16px;
	text-align: ${(props) => (props.$align ? props.$align : "left")};
`;

export default RequestPasswordModal;
