import React from "react";
import styled from "styled-components";
import Heading from "../Heading";
import { RedButton } from "../Button";
import TextLink from "../TextLink";

interface Props {
	saveAndPublish?(): any;
	discardChanges?(): any;
	modalIsOpen: boolean;
	handleModal?(): any;
	language: string;
}

const SaveChangesModal = ({
	saveAndPublish,
	discardChanges,
	modalIsOpen,
	language
}: Props) => {
	return (
		<>
			{modalIsOpen && (
				<StyledModal>
					<Container>
						<StyledHeading>{ language === "en" ? "Would you like to save changes?" : "Aimeriez-vous enregistrer vos modifications?" }</StyledHeading>
						<StyledRedButton handleClick={saveAndPublish}>
							{ language === "en" ? "Save & Publish" : "Enregistrer et publier" }
						</StyledRedButton>
						<StyledTextLink handleClick={discardChanges}>
							{ language === "en" ? "Discard changes" : "Ignorer" }
						</StyledTextLink>
					</Container>
				</StyledModal>
			)}
		</>
	);
};

/*
	Page Specific Styles
*/

const StyledHeading = styled(Heading)`
	margin-bottom: 32px;
`

const StyledRedButton = styled(RedButton)`
	@media (max-width: 720px) {
		width: 100%;
	}
`;
const StyledTextLink = styled(TextLink)`
	margin-top: 16px;
`;

const StyledModal = styled.div`
	top: 0;
	position: absolute;
	z-index: 10;
	background: white;
	width: 100%;
	height: 100%;
`;

const Container = styled.div`
	top: 253px;
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	grid-column: 1/24;
	box-sizing: border-box;
	@media (max-width: 720px) {
		top: 0px;
		padding: 80px 20px;
	}
`;

export default SaveChangesModal;
