import React from "react";
import CopyLinkButton from "../HomepageBuilder/CopyLinkButton";
import FacebookShareButton from "../HomepageBuilder/FacebookShareButton";
import TwitterShareButton from "../HomepageBuilder/TwitterShareButton";
import styled from "styled-components";
interface Props {
	customUrl: any;
	language: string;
}

const SocialShareIconsComponent = ({ customUrl, language }: Props) => {
	const url = language === "en" ? customUrl["english"] : customUrl["french"];
	return (
		<StyledSocialShareIconsComponent>
			<StyledText>Share This Page</StyledText>
			<FacebookShareButton
				homepageURL={document.location.href}
				pageName="liveHomepage"
			/>
			<TwitterShareButton
				customUrl={url}
				pageName="liveHomepage"
				language={language}
			/>
			<CopyLinkButton customUrl={url} language={language} />
		</StyledSocialShareIconsComponent>
	);
};

const StyledSocialShareIconsComponent = styled.div`
	position: fixed;
	z-index: 120;
	left: 32px;
	top: 125px;
	display: flex;
	flex-direction: column;
	gap: 15px;

	@media (max-width: 750px) {
		padding-top: 12px;
		top: calc(100vh - 66px);
		bottom: 0px;
		width: 100%;
		left: 0px;
		display: flex;
		flex-direction: row;
		justify-content: center;
		background: #ffffff;
		box-shadow: 0px -4px 15px rgba(0, 0, 0, 0.1);
	}
`;
const StyledText = styled.div`
	font-family: museo-sans;
	font-style: normal;
	font-weight: 400;
	font-size: 11px;
	line-height: 12px;
	letter-spacing: 1.5px;
	text-transform: uppercase;
	padding: 15px 10px 0px 0px;
	color: #000000;
	visibility: hidden;
	@media (max-width: 750px) {
		visibility: visible;
	}
`;

export default SocialShareIconsComponent;
