import styled from "styled-components";
import { RedButton } from "../Button";
import CloseIcon from "../../images/Close.svg";

interface Props {
	closeModal: () => void;
	volunteerForm: string;
}

const LeavingScholasticModal = ({ closeModal, volunteerForm }: Props) => {
	return (
		<StyledOverlay>
			<StyledModal>
				<StyledModalText>
					You are now leaving the Host Hub for another site. The information you enter there will be used by the Book Fair Host and will not be shared with Scholastic.
				</StyledModalText>
				<StyledContinueButton
					href={volunteerForm}
					target="_blank"
				>
					Continue
				</StyledContinueButton>
				<StyledCloseButton onClick={closeModal}>
					<img
						alt="Close the Leaving Scholastic modal"
						src={CloseIcon}
					/>
				</StyledCloseButton>
			</StyledModal>
		</StyledOverlay>
	);
};

const StyledOverlay = styled.div`
	z-index: 1;
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	background: rgba(0, 0, 0, 0.5);
	display: flex;
	justify-content: center;
	align-items: center;
`;

const StyledModal = styled.div`
	background: #ffffff;
	position: relative;
	width: 640px;
	height: 355px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 50px;
	@media (max-width: 519px) {
		gap: 35px;
	}
`;

const StyledModalText = styled.p`
	max-width: 439px;
	text-align: center;
	font-size: 21px;
	font-family: museo-sans;
	color: #333333;
	line-height: 24px;
	@media (max-width: 519px) {
		font-size: 20px;
		margin-left: 39px;
		margin-right: 39px;
		line-height: 23px;
	}
`;

const StyledContinueButton = styled(RedButton)`
	width: 123px;
`;

const StyledCloseButton = styled.button`
	position: absolute;
	right: 20px;
	top: 22px;
	background: none;
	border: none;
	cursor: pointer;
	height: 20px;
	@media (max-width: 519px) {
		right: 18px;
		top: 18px;
	}
`;

export default LeavingScholasticModal;
