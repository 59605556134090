import React, { useState, useEffect, useMemo } from "react";
import styled from "styled-components";
import axios from "axios";
import { useConfig } from "../../providers/ConfigContext";
import { validateEmail } from "../../utils/InputValidations";
import Card from "./Card";
import BodyText from "../BodyText";
import TextInput from "../TextInput";
import { RedButton } from "../Button";
import Checkbox from "../Checkbox";
import useCustomAlert from "../../hooks/HomepageBuilder/useCustomAlert";
import { trackSignUpForEmails } from "../../DumbleData";

interface Props {
	fairId: number;
	bookfairAccountId: number;
	language: string;
}

const Notifications = ({ fairId, bookfairAccountId, language }: Props) => {
	const { damAssetsOrigin } = useConfig();
	const { activateAlert, alert } = useCustomAlert(
		language === "en" ? "Thank you for signing up." : "Merci pour votre inscription."
	);
  const [nBF, setNBF] = useState(false);
  const [nTP, setNTP] = useState(false);
	const [emailAddress, setEmailAddress] = useState<string>("");
	const [emailError, setEmailError] = useState<string>();
	const [attemptedToSubmit, setAttemptedToSubmit] = useState<boolean>(false);

	// Checks if email is valid after submitting the form
	useEffect(() => {
		if (attemptedToSubmit) {
			if (validateEmail(emailAddress)) {
				setEmailError(undefined);
			} else {
				setEmailError(language === 'en' ? "Please enter a valid email address." : "Veuillez inscrire une adresse courriel valide.");
			}
		}
	}, [emailAddress, attemptedToSubmit, language]);

	// Attempts to POST to backend if email is valid
	const handleSubmit = async (event: any) => {
		event.preventDefault();
		if (validateEmail(emailAddress)) {
			try {
				await axios.post(
					`${process.env.REACT_APP_ENV_BASE_URL}/api/parent-comm/ca-homepage/${fairId}`,
					{
					  fairId: fairId,
					  bookfairAccountId: bookfairAccountId,
					  parentEmail: emailAddress,
					  fairsAtSchoolOptIn: nBF,
					  privacyPolicyAndTermsOfUse: nTP,
					  language: language,
					  url: window.location.href
					}
				);
				activateAlert();
			} catch (error) {
				console.error(error);
			}
		} else {
			setAttemptedToSubmit(true);
		}
	};

  const isValid = useMemo(() => nBF && nTP, [nBF, nTP]);

	return (
		<Card lang={language}>
			{alert}
			<NotificationsForm onSubmit={handleSubmit}>
				<Header>
					<StyledNotificationsIcon
						src={`${damAssetsOrigin}canadatoolkit/LiveHomepage/Icons/Section/Email.png`}
					/>
				</Header>
				<StyledBodyHeading>
					{language === "en"
						? "Sign Up for Emails"
						: "Inscrivez-vous pour recevoir des notifications à propos du Festival du Livre qui aura lieu à votre école cette année"}
				</StyledBodyHeading>
				<StyledTextInput
					label={
						language === "en" ? "Email Address" : "Adresse courriel"
					}
					id="emailAddress"
					value={emailAddress}
					onChange={setEmailAddress}
					error={emailError}
				/>
				<StyledChkboxWrap>
					<Checkbox
						label={
							language === "en"
								? "Send me notifications about the Book Fairs at my school this year."
								: "Je souhaite m’inscrire pour recevoir des notifications à propos des Festivals du Livre qui auront lieu à mon école cette année."
						}
						checked={nBF}
						onChange={() => {
							setNBF(!nBF)}}
						alignItemsStart={true}
						className="liveHomeChkbox"
					></Checkbox>
					<Checkbox
						label={
							language === "en"
								? `
									Yes, I have read and agree to Scholastic's
									<a
										href="https://www.scholastic.ca/aboutscholastic/privacy.php"
										target="_blank"
									>
										Privacy Policy
									</a>
									and
									<a
										href="https://www.scholastic.ca/aboutscholastic/webterms.php"
										target="_blank"
									>
										Terms of Use
									</a>
									(Required).
								`
								: `Oui, j’ai lu et j’accepte la <a
									href="https://www.scholastic.ca/editions/lentreprise/confidentialite.php"
            target="_blank"
          >Politique de confidentialité</a> et les <a
            href="https://www.scholastic.ca/editions/lentreprise/modalites-dutilisation.php"
            target="_blank"
          >Conditions d’utilisation</a> de Scholastic (obligatoire).`
						}
						checked={nTP}
						onChange={setNTP}
						alignItemsStart={true}
						className="liveHomeChkbox"
					></Checkbox>
				</StyledChkboxWrap>
				<StyledRedButton handleClick={() => trackSignUpForEmails()} disabled={!isValid} type="submit">{language === "en" ? "Sign Up" : "M’inscrire"}</StyledRedButton>
				<StyledLegalText>{language === "en" ? "You can unsubscribe at any time." : "Vous pouvez vous désabonner à tout moment."}</StyledLegalText>
			</NotificationsForm>
		</Card>
	);
};

const StyledChkboxWrap = styled("div")`
	max-width: 280px;
	@media (max-width: 519px) {
		max-width: unset;
	}
`;

const StyledTextInput = styled(TextInput)`
	max-width: 280px;
	margin-top: 0px;
	margin-bottom: 0;
	@media (max-width: 519px) {
		max-width: unset;
	}
`;
const StyledNotificationsIcon = styled.img`
	width: 68px;
	margin-bottom: 19px;
	margin-top: 28px;
	@media (max-width: 720px) {
		margin-top: 12.6px;
	}
`;
const StyledRedButton = styled(RedButton)`
	margin-top: 20px;
	width: 136px;
	@media (max-width: 720px) {
		margin-top: 30px;
	}
	@media (max-width: 670px) {
		margin-top: 26.1px;
	}
`;
const StyledBodyHeading = styled("h3")`
	font-family: museo-sans;
	font-size: 21px;
	line-height: 24px;
	text-align: center;
	font-weight: 600;
	margin: 20px 0;
`;
const NotificationsForm = styled.form`
	max-height: 400px;
	max-width: 440px;
	display: flex;
	flex-direction: column;
	align-items: center;
	@media (max-width: 959px) {
		max-width: unset;
		max-height: unset;
	}
`;
const Header = styled.div`
	width: 100%;
	border-bottom: 1px solid #ffd7c7;
	display: flex;
	flex-direction: column;
	align-items: center;
`;

const StyledLegalText = styled(BodyText)`
	padding-top: 16px;
	color: #666;
	text-align: center;
	font-size: 16px;
	font-weight: 300;
`;
export default Notifications;
