import React from "react";
import styled from "styled-components";
import { RedButton } from "../../Button";
import { useConfig } from "../../../providers/ConfigContext";
import DigitalFlyerInfo from "./DigitalFlyerInfo.json";
import { trackExploreDigitalFlyerClick } from "../../../DumbleData";

type Props = {
	productId?: string | undefined;
	k8Flag?: string | undefined;
	language: string;
};

const PreviewFlyerComponent = ({ productId, k8Flag, language }: Props) => {
	const { damAssetsOrigin } = useConfig();
	const productIDs = "Product IDs"
	let fairType;
	let secondaryFlyer = "";

	if (productId !== undefined) {
		//added if/else for testing
		const flyerInfo = DigitalFlyerInfo.find((flyer) =>
			flyer[productIDs].includes(productId)
		);
		if (flyerInfo) {
			fairType = flyerInfo["Fair Type"];
		}
		
		if (k8Flag === "Y") {
			const flyerInfoK8 = DigitalFlyerInfo.find(
				(flyer) =>
					flyer[productIDs].includes(productId) &&
					flyer[productIDs].includes("K8 flag")
			);
			if (flyerInfoK8) {
				secondaryFlyer = flyerInfoK8["Flyer Link"];
			}
		} else if (fairType === "Tabletop Mail K8") {
			secondaryFlyer =
				"https://bookfairsfiles.scholastic.com/homepages/flyer_TT_elem/flyer_TT_elem.html";
		}
	} else {
		secondaryFlyer = "";
	}

	return (
		<PreviewFlyer>
			<SectionContainer>
				<FlyerImage
					src={language === "en" ? `${damAssetsOrigin}canadatoolkit/LiveHomepage/DigitalFlyer/BF-Flyers.png`
						: `${damAssetsOrigin}canadatoolkit/LiveHomepage/DigitalFlyer/FDL-Flyers.png`}
				/>
			</SectionContainer>
			<SectionContainer>
				<Heading>
					{language === "en"
						? "Preview Books from the Fair"
						: "Obtenez un aperçu des livres proposés au Festival"}
				</Heading>
				<BodyText>
					{language === "en"
						? "Get a sneak peek at the characters your kids will be talking about. Plus, check out our digital highlights flyers."
						: "Découvrez en primeur les personnages dont vos enfants parleront. Découvrez également nos dépliants promotionnels numériques."}
				</BodyText>
				<ButtonContainer>
					<StyledRedButton
						href={
							language === "en"
								? "https://www2.scholastic.ca/bookfairs/featured-books/"
								: "https://www2.scholastic.ca/bookfairs/livres-en-vedette/"
						}
						handleClick={() => trackExploreDigitalFlyerClick()}
						target="_blank"
					>
						{language === "en"
							? "Explore Featured Books"
							: "Découvrez les livres recommandés"}
					</StyledRedButton>
					{secondaryFlyer && (
						<StyledRedButton
							href={secondaryFlyer}
							handleClick={() =>
								trackExploreDigitalFlyerClick()
							}
							target="_blank"
						>
							Explore Grades 6+ Flyer
						</StyledRedButton>
					)}
				</ButtonContainer>
			</SectionContainer>
		</PreviewFlyer>
	);
};

const PreviewFlyer = styled.div`
	display: flex;
	justify-content: center;
	gap: 40px;
	width: 100%;
	min-height: 396px;
	padding: 48px;
	background: #ffffff;
	box-sizing: border-box;
	@media (max-width: 670px) {
		flex-flow: column;
		align-items: center;
		padding: 32px 38px;
	}
`;

const FlyerImage = styled.img`
	max-width: 100%;
`;

const SectionContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	max-width: 440px;
	flex: 1 50%;
`;

const ButtonContainer = styled.div`
	display: flex;
	flex-direction: row;
	gap: 20px;
	@media (max-width: 950px) {
		flex-direction: column;
	}
`;

const Heading = styled.p`
	font-family: museo-sans;
	font-weight: 600;
	font-size: 32px;
	line-height: 35px;
	text-align: center;
	letter-spacing: 1px;
	text-transform: uppercase;
	color: #333333;
	margin-bottom: 8px;
`;

const BodyText = styled.p`
	font-family: museo-sans;
	font-weight: 400;
	font-size: 16px;
	line-height: 19px;
	text-align: center;
	color: #333333;
	margin-bottom: 32px;
`;

const StyledRedButton = styled(RedButton)``;

export default PreviewFlyerComponent;
