import React, {useEffect, useState} from "react";
import FairDetailsCard from "./FairDetailsCard";
import styled from "styled-components";
import moment from "moment";
import "moment/min/locales";

interface Props {
	balance: number;
	balanceDate: string;
	language: string;
}
const ShareTheFairFundsCard = ({ balance, balanceDate, language }: Props) => {
	const [generatedDate, setGeneratedDate] = useState(balanceDate);
	useEffect(() => {
		moment.locale(language);
		setGeneratedDate(moment(balanceDate).format("LL").toString());
	}, [language, balanceDate, setGeneratedDate])

	return (
		<FairDetailsCard title={language === "en" ? "Scholastic Dollars Balance" : "Solde des dollars Scholastic"}>
			<Balance>{language === "en" ? `$${Intl.NumberFormat("en-EN").format(balance)}` : `${Intl.NumberFormat("fr-FR").format(balance)} $`}</Balance>
			<Body>{language === "en" ? "Balance generated on:" : "Solde généré le :"} <span>{generatedDate}</span></Body>
			<BodyDimmed>{language === "en" ? "Scholastic Dollars earned will expire 24 months after the date of issue." : "Les dollars Scholastic obtenus expireront 24 mois après leur date d’émission."}</BodyDimmed>
		</FairDetailsCard>
	);
};

const Body = styled.div`
	font-size: 16px;
	font-weight: 400;
	line-height: 19px;
	margin-bottom: 24px;
	margin-top: 17px;
	& span {
		color: #666;
		display: block;
	}
`;

const BodyDimmed = styled(Body)`
	color: #666;
	weight: 300;
	width: 100%;
	max-width: 100%;
`;

const Balance = styled.p`
	font-size: 36px;
	font-weight: 900;
	line-height: 28px;
`;

export default ShareTheFairFundsCard;