import React, { useState } from "react";
import styled from "styled-components";
import axios from "axios";
import LimitedTextArea from "../../LimitedTextArea";
import TextInput from "../../TextInput";
import { validateEmail } from "../../../utils/InputValidations";
import { RedButton, WhiteButton } from "../../Button";
import { ModalContainer } from "../../ModalContainer";
import { trackForwardDetailsModalCancel, trackForwardDetailsModalSend } from "../../../DumbleData";

interface Props {
	language: string;
	closeModal: () => void;
	openEmailSuccessModal: () => void;
	openEmailFailureModal: () => void;
}

export const EmailAgreementModal = ({
	language,
	closeModal,
	openEmailSuccessModal,
	openEmailFailureModal,
}: Props) => {
	const [message, setMessage] = useState("");
	const [email, setEmail] = useState("");
	const [emailError, setEmailError] = useState<string>();
	const [messageError, setMessageError] = useState<string>();

	const handleSend = async () => {
		let validationFlag = true;
		setEmailError(undefined);
		setMessageError(undefined);

		if (email === "") {
			setEmailError(language === "en" ? "This field is required." : "Ce champ est obligatoire.");
			validationFlag = false;
		} else if (!validateEmail(email)) {
			setEmailError(language === "en" ? "Please enter a valid email address." : "Veuillez inscrire une adresse courriel valide.");
			validationFlag = false;
		}

		if (message === "") {
			setMessageError(language === "en" ? "Your message may not be blank." : "Votre message ne peut pas être laissé vide.")
			validationFlag = false;
		}

		if (validationFlag) {
			setEmailError(undefined);
			setMessageError(undefined);
			trackForwardDetailsModalSend();
			try {
				await axios.post(`${process.env.REACT_APP_ENV_BASE_URL}/api/user/fairs/current/coa/pdf-links`, {
						email: email,
						message: message,
						language: language === "en" ? "english" : "french"
					},
					{
						withCredentials: true,
					}
				);
				closeModal();
				openEmailSuccessModal();
			} catch (e) {
				closeModal();
				openEmailFailureModal();
			}
		}
	};

	return (
		<ModalContainer closeModal={closeModal}>
			<StyledContent>
				<StyledHeadSec>
					<StyledHeading>{language === "en" ? 'Forward Details' : "Envoyer les détails"}</StyledHeading>
				</StyledHeadSec>
				<StyledHeadText>
					{language === "en" ? 
						"If others need to confirm your Fair details, add their email address below and a copy will be sent to them on your behalf." 
						: "Si une autre personne doit confirmer les détails de votre Festival, inscrivez son adresse courriel ci-dessous et une copie lui sera envoyée en votre nom." }
				</StyledHeadText>
				<RecipientEmailSec>
					<TextInput
						label={language === "en" ? "Recipient Email" : "Courriel du destinataire"}
						value={email}
						onChange={setEmail}
						error={emailError}
					/>
				</RecipientEmailSec>
				<AdditionalDetailsSec>
					<LimitedTextArea
						label={language === "en" ? "Your Message" : "Votre message"}
						language={language}
						value={message}
						onChange={setMessage}
						error={messageError}
						limit={200}
					/>
				</AdditionalDetailsSec>
				<ButtonContainer>
					<StyledWhiteButton handleClick={() => {closeModal(); trackForwardDetailsModalCancel();}}>
						{language === "en" ? "Cancel" : "Annuler"}
					</StyledWhiteButton>
					<StyledRedButton handleClick={handleSend}>
						{language === "en" ? "Send" : "Envoyer"}
					</StyledRedButton>
				</ButtonContainer>
			</StyledContent>
		</ModalContainer>
	);
};

const StyledHeading = styled.p`
	font-size: 16px;
	line-height: 19px;
	font-weight: 700;
	padding-top: 40px;
	color: #333;
`;

const StyledHeadText = styled.p`
	font-size: 17px;
	line-height: 25px;
	font-weight: 300;
	margin-bottom: 16px;
	color: #333;
`;

const StyledContent = styled.div`
	background-color: white;
	max-width: 600px;
	padding: 24px;
	box-sizing: border-box;
	@media (max-width: 719px) {
		width: 100%;
		height: 100%;
	}
`;

const StyledHeadSec = styled.div`
	box-sizing: border-box;
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	margin-bottom: 16px;
`;

const AdditionalDetailsSec = styled.div`
	margin-bottom: 32px;
	textarea {
		height: 100px;
	}
`;

const RecipientEmailSec = styled.div`
	margin-bottom: 16px;
`;

const StyledWhiteButton = styled(WhiteButton)`
	width: 130px;
	height: 40px;
	border: 1px solid #e81111;
	@media (max-width: 719px) {
		width: 100%;
	}
`;

const StyledRedButton = styled(RedButton)`
	width: 130px;
	height: 40px;
	border-radius: 100px;
	@media (max-width: 719px) {
		width: 100%;
	}
`;

const ButtonContainer = styled.div`
	gap: 16px;
	display: flex;
	justify-content: flex-end;
	@media (max-width: 719px) {
		flex-direction: column;
		width: 100%;
	}
`;
