import styled from "styled-components";

interface Props {
    language: string;
}

const EarningChart = ({language} : Props) => {
	return(
        <OverflowContainer>
            <EarningChartContainer>
            <ChartRow className={"row-1"}>
                <ChartCol className={"heading col-wide"}>
                    {language === "en" ? "Book Fair Net Sales (after tax)" : "Ventes nettes du Festival du Livre (montant après les taxes)"}
                </ChartCol>
                <ChartCol className={"heading col-wide"}>
                    {language === "en" ? "Scholastic Dollars" : "Dollars Scholastic"}
                </ChartCol>
                <ChartCol className={"heading col-short"}>&nbsp;</ChartCol>
                <ChartCol className={"heading col-wide col-5"}>
                    {language === "en" ? "Cash Value" : "Valeur en argent"}
                </ChartCol>
            </ChartRow>
            <ChartRow>
                <ChartCol className={"col-wide"}>
                    {language === "en" ? "Less than $1,999" : "Moins de 1 999 $"}
                </ChartCol>
                <ChartCol className={"col-wide"}>
                    {language === "en" ? "15%" : "15 %"}
                </ChartCol>
                <ChartCol className={"col-short"}>
                    {language === "en" ? "and/or" : "et/ou"}
                </ChartCol>
                <ChartCol className={"col-wide"}>
                    {language === "en" ? "N/A" : "S.O."}
                </ChartCol>
            </ChartRow>
            <ChartRow>
                <ChartCol className={"col-wide"}>
                    {language === "en" ? "$2,000 to $3,499" : "2 000 $ à 3 499 $"}
                </ChartCol>
                <ChartCol className={"col-wide"}>
                    {language === "en" ? "30%" : "30 %"}
                </ChartCol>
                <ChartCol className={"col-short"}>&nbsp;</ChartCol>
                <ChartCol className={"col-wide"}>
                    {language === "en" ? "15%" : "15 %"}
                </ChartCol>
            </ChartRow>
            <ChartRow>
                <ChartCol className={"col-wide"}>
                    {language === "en" ? "$3,500 to $4,999" : "3 500 $ à 4 999 $"}
                </ChartCol>
                <ChartCol className={"col-wide"}>
                    {language === "en" ? "40%" : "40 %"}
                </ChartCol>
                <ChartCol className={"col-short"}>&nbsp;</ChartCol>
                <ChartCol className={"col-wide"}>
                    {language === "en" ? "20%" : "20 %"}
                </ChartCol>
            </ChartRow>
            <ChartRow className={"row-5"}>
                <ChartCol className={"col-wide"}>
                    {language === "en" ? "$5,000 or more" : "5 000 $ ou plus"}
                </ChartCol>
                <ChartCol className={"col-wide"}>
                    {language === "en" ? "50%" : "50 %"}
                </ChartCol>
                <ChartCol className={"col-short"}>&nbsp;</ChartCol>
                <ChartCol className={"col-wide"}>
                    {language === "en" ? "25%" : "25 %"}
                </ChartCol>
            </ChartRow>
        </EarningChartContainer>
        </OverflowContainer>
    )
};

export default EarningChart;

const OverflowContainer = styled.div`
    width: 100%;
    overflow-x: auto;
`;

const EarningChartContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    min-width: 640px;
`;

const ChartRow = styled.div`
    display: flex;
    align-items: center;
    padding: 8px;
    min-height: 19px;
    border-bottom: 1px solid #d7d7d7;

    &.row-1 {
        background: #F4F4F4;
    }

    &.row-5 {
        border-bottom: none;
    }
`;

const ChartCol = styled.div`
    &.heading {
        font-weight: 550;
        padding-right: 8px;
    }

    &.col-wide {
        width: 30%;
    }

    &.col-short {
        width: 10%;
    }

    &.col-5 {
        padding: none;
    }
`;