import React, {useState, useEffect} from "react";
import axios from "axios";
import FairDetailsCard from "./FairDetailsCard";
import styled from "styled-components";
import { WhiteButton } from "../Button";
import TextInput from '../TextInput';
import { handleGenericErrors } from "../../utils/ErrorUtility";

interface Props {
	fliers: any;
	language: string;
	planningKitShipped: boolean;
	id: string;
}
const FlyersCard = ({
	fliers,
	language,
	planningKitShipped,
	id
}: Props) => {
	const [isEditing, setIsEditing] = useState<boolean>(false);
	const [fifthAndBelow, setFifthAndBelow] = useState(fliers["fifthAndBelow"]);
	const [sixthAndAbove, setSixthAndAbove] = useState(fliers["sixthAndAbove"]);
	const [fifthAndBelowError, setFifthAndBelowError] = useState<any>();
	const [sixthAndAboveError, setSixthAndAboveError] = useState<any>();

	useEffect(() => {

		if (isEditing) {
			if ((!fifthAndBelow || parseInt(fifthAndBelow) < 1) && (!sixthAndAbove || parseInt(sixthAndAbove) < 1)) {
				language === "en" ? setFifthAndBelowError("Total number of flyers can't be less than 1.") : setFifthAndBelowError("Le nombre total de dépliants ne peut être inférieur à 1.");
				language === "en" ? setSixthAndAboveError("Total number of flyers can't be less than 1.") : setSixthAndAboveError("Le nombre total de dépliants ne peut être inférieur à 1.");
			} else {
				setFifthAndBelowError(undefined);
			setSixthAndAboveError(undefined);
			}
		}
		else {
			setFifthAndBelowError(undefined);
			setSixthAndAboveError(undefined);
		}
	}, [isEditing,
		fifthAndBelow,
		sixthAndAbove,
		language
	]);

	const handleEditClick = () => {
		setIsEditing(true);
	};

	const handleSaveClick = () => {
		setIsEditing(false);
		axios.put(`${process.env.REACT_APP_ENV_BASE_URL}/api/user/fairs/${id}/fliers`,
				{
					fifthAndBelow: parseInt(fifthAndBelow),
					sixthAndAbove: parseInt(sixthAndAbove),
				},
				{ withCredentials: true, }
			)
			.then((res) => {
				console.log(res);
			})
			.catch(function (error) {
				handleGenericErrors(error);
			});
	};

	return (
		<FairDetailsCard title={language === "en" ? "Students and Flyers" : "Élèves et dépliants"}>
		
				<Body>
					{language === "en" ? "JK-Grade 5" : "Préscolaire à 5ᵉ année"}
					{ isEditing ? 
						<TextInput
						 	value={fifthAndBelow} 
							onChange={(v: string) => {
								if (v.length === 0) {
									setFifthAndBelow(0);
								} else if (v.length < 4) {
									setFifthAndBelow(v.replace(/^0+/, "").replace(/[^0-9]/g,""));
								}
							}}
							error={fifthAndBelowError}
						/>
					:
						<span>{fifthAndBelow}</span>
					}
				</Body>

				<Body>
					{language === "en" ? "Grades 6-8" : "6ᵉ à 8ᵉ année"}
					{ isEditing ? 
						<TextInput
						 	value={sixthAndAbove} 
							onChange={(v: string) => {
								if (v.length === 0) {
									setSixthAndAbove(0);
								} else if (v.length < 4) {
									setSixthAndAbove(v.replace(/^0+/, "").replace(/[^0-9]/g,""));
								}
							}}
							error={sixthAndAboveError}
						/>
					:
						<span>{sixthAndAbove}</span>
					}
				</Body>

					{ !planningKitShipped && <Buttons isEditing={isEditing} handleEditClick={handleEditClick} handleSaveClick={handleSaveClick} language={language} fifthAndBelowError={fifthAndBelowError} sixthAndAboveError={sixthAndAboveError} /> }				
		
		</FairDetailsCard>
	);
};

interface Props2 {
	isEditing: any;
	handleSaveClick: any;
	handleEditClick: any;
	language: string;
	fifthAndBelowError: any;
	sixthAndAboveError: any;
}

function Buttons({isEditing, handleSaveClick, handleEditClick, language, fifthAndBelowError, sixthAndAboveError} : Props2) {
	return isEditing ? 
	<WhiteButton handleClick={handleSaveClick} whiteBackground={true} disabled={fifthAndBelowError !== undefined || sixthAndAboveError !== undefined}>{language === "en" ? "Save Flyer Quantity" : "Enregistrer la quantité de dépliants"}</WhiteButton>
:
	<WhiteButton handleClick={handleEditClick} whiteBackground={true}>{language === "en" ? "Edit Flyer Quantity" : "Enregistrer la quantité"}</WhiteButton>
}

const Body = styled.div`
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
	margin-bottom: 16px;
	& input {
		margin-top: 5px;
	}
	& span {
		display: block;
		margin-top: 5px;
	}
`;

export default FlyersCard;
