import React from "react";
import { FairSelectorContent } from "./FairSelectorContent";
import { ModalContainer } from "../ModalContainer";

interface Props {
	closeFairSelectorModal: Function;
	language: string;
}

export const FairSelectorModal = ({ closeFairSelectorModal, language }: Props) => {
	return (
		<ModalContainer closeModal={closeFairSelectorModal} isOverflow={true}>
			<FairSelectorContent isModal={true} language={language}/>
		</ModalContainer>
	);
};