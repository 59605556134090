import React from "react";
import styled from "styled-components";

interface Props {
	className?: string;
	children: React.ReactNode;
}

const BodyText = ({ children, className }: Props) => {
	return <StyledBodyText className={className}>{children}</StyledBodyText>;
};

const StyledBodyText = styled.p`
	font-family: museo-sans;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 18px;
	text-align: center;
	color: #333333;
`;

export default BodyText;
