import React from "react";
import "../../styles/cafooter.css";


interface Props {
	language: string;
}

const CAFooter = ({ language }: Props) => {

	return (
		<div className="ca-copyright">
			{language === "en" && <footer>&copy; {new Date().getFullYear()} Scholastic Book Fairs Canada Inc.</footer>}
			{language === "fr" && <footer>&copy; Festival du Livre Scholastic Inc., {new Date().getFullYear()}.</footer>}
		</div>
	);
};

export default CAFooter;