import React, { useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import { IconButtonWhite } from "./Button";
import Print from "../images/Print.svg";
import styled from "styled-components";
import LegalCopyToPrint from "./ConfirmYourFair/LegalCopy/LegalCopy";
import RedTextButton from "./RedTextButton";
import { trackCoaPrint, trackPrintBookFairAcknowledgement } from "../DumbleData";

interface Props {
	buttonText?: string;
	buttonWidth?: number;
	redTextButton?: boolean;
  	emailedPage?: boolean;
	className?: string;
	fairInfoData?: any;
	consultantData?: any;
	chairpersonData?: any;
	organizationData?: any;
	language: string;
}

export const PrintableComponent = ({
	buttonText,
	buttonWidth,
	redTextButton,
  	emailedPage,
	className,
	fairInfoData,
	consultantData,
	chairpersonData,
	organizationData,
	language
}: Props) => {
	const componentRef = useRef<HTMLDivElement>(null);

	const [printClicked, setPrintClicked] = useState(false);

	const handlePrint = () => {
		openPrintDialogue();
		setPrintClicked(false);
	};

	const openPrintDialogue = useReactToPrint({
		content: () => componentRef.current as HTMLElement,
	});

	const handleClick = () => {
		setPrintClicked(true);
	};

	return (
		<div>
			<PdfInfo>
				<div ref={componentRef}>
					{/* render legal copy after print button is clicked */}
					{printClicked && (
						<LegalCopyToPrint
							emailedPage={emailedPage}
							handlePrint={handlePrint}
							printClicked={printClicked}
							fairInfoData={fairInfoData}
							consultantData={consultantData}
							chairpersonData={chairpersonData}
							organizationData={organizationData}
							language={language}
						/>
					)}
				</div>
			</PdfInfo>
			{!redTextButton && (
				<IconButtonWhite
					whiteBackground={true}
					handleClick={() => {
						trackCoaPrint();
						handleClick();
					}}
					iconSrc={Print}
					iconAlt={"Print"}
					iconWidth={14}
					buttonWidth={buttonWidth}
				>
					{buttonText}
				</IconButtonWhite>
			)}
			{redTextButton && (
				<RedTextButton
					handleClick={() => {
						trackPrintBookFairAcknowledgement();
						handleClick();
					}}
					className={className ? className : "header"}
				>
					{buttonText}
				</RedTextButton>
			)}
		</div>
	);
};
const PdfInfo = styled.div`
	display: none;
`;
