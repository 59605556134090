import { useState } from "react";
import CustomAlert from "../../components/CustomAlert";

const useCustomAlert = (message: string) => {
	const [showAlert, setShowAlert] = useState<string>('false');

	// Displays the alert, then hides it after 3 seconds
	const activateAlert = () => {
		setShowAlert('true');
		setTimeout(() => {
			setShowAlert('false');
		}, 3000);
	};

	const alert = (
		<CustomAlert show={showAlert} onClose={() => setShowAlert('false')}>
			{message}
		</CustomAlert>
	);

	return { activateAlert, alert };
};

export default useCustomAlert;
