import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import "../../../styles/misc.css";
import { Page } from "../Page";
import Heading from "../../Heading";
import Subheading from "../../Subheading";
import PageIcon from "../PageIcon";
import GoalIcon from "../../../images/Goal.svg";
import BodyText from "../../BodyText";
import TextInput from "../../TextInput";
import Checkbox from "../../Checkbox";
import LimitedTextArea from "../../LimitedTextArea";
import { RedButton } from "../../Button";
import TextLink from "../../TextLink";
import axios from "axios";
import SaveChangesModal from "../SaveChangesModal";
import Prompt from "../Prompt";
import { trackDiscardChanges, trackPromoteYourGoalInputEnglish, trackPromoteYourGoalInputFrench, trackSaveAndPublishButton, trackSkipButton, trackUpdateCurrentSalesBook, trackUpdateCurrentSalesDollar, updatePageType, updateUserBookDefaultAmount, updateUserDollarDefaultAmount, updateUserLoginStatus, updateUserFairId, updateUserAccountId } from "../../../DumbleData";
import { handleGenericErrors, handleGenericSuccess } from "../../../utils/ErrorUtility";

enum GoalType {
	books = "books",
	dollars = "dollars",
}

interface Props {
	language: string;
	builderNavDisabled: boolean;
	setBuilderNavDisabled: any;
	builderNextLocation: string;
	setBuilderNextLocation: any;
}

export const GoalPage = ({ 
	language, 
	builderNavDisabled, 
	setBuilderNavDisabled,
	builderNextLocation,
	setBuilderNextLocation}: Props) => {
	const goalNextLocation = "/customize/information";
	setBuilderNextLocation(goalNextLocation);
	const navigate = useNavigate();
	const [booksGoalError, setBooksGoalError] = useState<string>();
	const [goalSalesError, setGoalSalesError] = useState<string>();
	const [attemptedToSubmit, setAttemptedToSubmit] = useState(false);
	const [saveChangesModalOpen, setSaveChangesModalOpen] = useState(false);
	const [allChangesSaved, setAllChangesSaved] = useState(true);
	const [allowNavigation, setAllowNavigation] = useState(false);
	const [getRequestDone, setGetRequestDone] = useState(false);

	const [goalData, setGoalData] = useState<any>(null);
	const [savedData, setSavedData] = useState<any>(null);

	// get request
	useEffect(() => {
		const getGoalPageData = async () => {
			window.scrollTo(0, 0);
			try {
				await axios
					.get(`${process.env.REACT_APP_ENV_BASE_URL}/api/user/fairs/current/homepage/goals`, {
						withCredentials: true,
					})
					.then((response) => {
						if (response.status === 200) {
							if (response.data.goalMessage.english === "") {
								response.data = {
									...response.data,
									goalMessage: {
										...response.data.goalMessage,
										english: "Please help our school meet our goal and inspire Bookjoy in all students. Scholastic gives back a portion of every dollar spent at the Fair as rewards to our school!"
									}
								};
							}
							if (response.data.goalMessage.french === "") {
								response.data = {
									...response.data,
									goalMessage: {
										...response.data.goalMessage,
										french: "Aidez notre école à atteindre son objectif et à insuffler la joie de lire à tous les élèves. Scholastic remet à notre école une partie de chaque dollar dépensé au Festival en guise de récompense!"
									}
								};
							}
							setGoalData(response.data);
							setSavedData(response.data);
							setGetRequestDone(true);
							updateUserBookDefaultAmount(response.data.goal.books);
							updateUserDollarDefaultAmount(response.data.goal.dollars);
							updateUserFairId(response.data.fairId);
							updateUserAccountId(response.data.bookfairAccountId)
							updateUserLoginStatus(true);
							updatePageType("homepage-builder");
							return;
						} else {
							handleGenericSuccess(response);
						}
					})
					.catch((err) => {
						handleGenericErrors(err);
					});
			} catch (err: any) {
				console.log(err)
			}
		};
		getGoalPageData();
	}, []);

	const setBookFairGoalCkbox = (value: boolean) => {
		const changedGoalData = {
			...goalData,
			displayGoal:
				value !== null ? value : !goalData.displayGoal,
		};
		setGoalData(changedGoalData);
	};

	const validateNumString = (value: string) => {
		const re = /^\d+(,\d+)*$/;
		return value === "" || re.test(value);
	};

	//Adds commas after every 3 digits for larger numbers
	const formatNumber = (value: string) =>
		value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

	const updateGoalBooks = (e: any, goalType: string) => {
		//remove commas if large number
		const value = e.replace(/,/gi, "");
		if (validateNumString(value)) {
			if (goalType === GoalType.books) {
				const changedGoalData = {
					...goalData,
					goal: {
						books: formatNumber(value),
						dollars: formatNumber((value * 10).toString())
					}
				};
				setGoalData(changedGoalData);
			} else {
				const changedGoalData = {
					...goalData,
					goal: {
						books: formatNumber(Math.ceil(value / 10).toString()),
						dollars: formatNumber(value)
					}
				};
				setGoalData(changedGoalData);
			}
		}
	};

	const updateMessage = (e: any, lang: string) => {
		if (lang === "en") {
			const changedGoalData = {
				...goalData,
				goalMessage: {
					...goalData.goalMessage,
					english: e
				}
			};
			setGoalData(changedGoalData);
		} else {
			const changedGoalData = {
				...goalData,
				goalMessage: {
					...goalData.goalMessage,
					french: e
				}
			};
			setGoalData(changedGoalData);
		}
	};

	const updateCurrentBooks = (e: any, goalType: string) => {
		//remove commas if large number
		const value = e.replace(/,/gi, "");
		if (validateNumString(e)) {
			if (goalType === GoalType.books) {
				const changedGoalData = {
					...goalData,
					currentSales: {
						books: formatNumber(value),
						dollars: formatNumber((value * 10).toString())
					}
				};
				setGoalData(changedGoalData);
			} else {
				const changedGoalData = {
					...goalData,
					currentSales: {
						books: formatNumber(Math.ceil(value / 10).toString()),
						dollars: formatNumber(value)
					}
				};
				setGoalData(changedGoalData);
			}
		}
	};

	//callback to validate data
	//will first be triggered by first submit and then will run each time an input field is changed
	const dataValidate = useCallback((language: string) => {
		let isAllValid = true;
		if (goalData.displayGoal) {
			if (goalData.goal.books === "") {
				setBooksGoalError(language === "en" ? "Please enter a goal." : "Veuillez inscrire un objectif");
				isAllValid = false;
			} else {
				setBooksGoalError(undefined);
			}
			if (goalData.goal.dollars === "") {
				setGoalSalesError(language === "en" ? "Please enter a goal." : "Veuillez inscrire un objectif");
				isAllValid = false;
			} else {
				setGoalSalesError(undefined);
			}
		} else {
			setBooksGoalError(undefined);
			setGoalSalesError(undefined);
		}
		return isAllValid;
	}, [goalData]);

	const discardAllChanges = (saved: any) => {
		setGoalData({ ...goalData, ...saved });
	};

	//calls validation function and then sends post request if all inputs are valid, otherwise notifies useEffect to check for errors
	const saveAndContinueGoals = async () => {
		 if (dataValidate(language)) {
			try {
				// Ensuring all of these values are numeric
				let goalBooks, goalDollars, salesBooks, salesDollars;
				if(typeof goalData.goal.books === 'string') {
					goalBooks = parseInt(goalData.goal.books.replace(/,/g, ''));
					goalDollars = parseInt(goalData.goal.dollars.replace(/,/g, ''));
				} else {
					goalBooks = goalData.goal.books;
					goalDollars = goalData.goal.dollars;
				}

				if(typeof goalData.currentSales.books === 'string') {
					salesBooks = parseInt(goalData.currentSales.books.replace(/,/g, ''));
					salesDollars = parseInt(goalData.currentSales.dollars.replace(/,/g, ''));
				} else {
					salesBooks = goalData.currentSales.books;
					salesDollars = goalData.currentSales.dollars;
				}
				
				await axios
					.put(
						`${process.env.REACT_APP_ENV_BASE_URL}/api/user/fairs/current/homepage/goals`,
						{
							'goal': {
								'books': goalBooks,
								'dollars': goalDollars
							},
							'currentSales': {
								'books': salesBooks,
								'dollars': salesDollars
							},
							'displayGoal': goalData.displayGoal,
							'goalMessage': {
								'english': goalData.goalMessage.english === "" ? "Please help our school meet our goal and inspire Bookjoy in all students. Scholastic gives back a portion of every dollar spent at the Fair as rewards to our school!" : goalData.goalMessage.english,
								'french': goalData.goalMessage.french === "" ? "Aidez notre école à atteindre son objectif et à insuffler la joie de lire à tous les élèves. Scholastic remet à notre école une partie de chaque dollar dépensé au Festival en guise de récompense!" : goalData.goalMessage.french,
							}

						},
						{ withCredentials: true }
					)
					.then((response) => {
						if (response.status === 200) {
							setAllChangesSaved(true);
							setAllowNavigation(true);
							setBuilderNavDisabled(false);
						} else {
							handleGenericSuccess(response);
						}
					})
					.catch((err) => {
						handleGenericErrors(err);
					});
			} catch (err) {
				handleGenericErrors(err);
			}
		} else {
			setSaveChangesModalOpen(false);
			setBuilderNavDisabled(false);
			setAttemptedToSubmit(true);
		}
	};

	useEffect(() => {
		if (attemptedToSubmit) {
			dataValidate(language);
		}
	}, [language, attemptedToSubmit, dataValidate]);

	// Displays the two most recent fairs from a user's sales history
	const renderFairHistory = () => {
		let fairElements = [];
		for (let i = 0; i < 2 && i < goalData.salesHistory.length; i++) {
			const fair = goalData.salesHistory[i];
			const [startYear, startMonth, startDay] = fair.startDate.split("-");
			const formattedStartDate =
				language === "en"
					? `${startMonth}/${startDay}/${startYear}`
					: `${startYear}-${startMonth}-${startDay}`;
			const [endYear, endMonth, endDay] = fair.endDate.split("-");
			const formattedEndDate =
				language === "en"
					? `${endMonth}/${endDay}/${endYear}`
					: `${endYear}-${endMonth}-${endDay}`;
			const formattedSales =
				language === "en"
					? `$${Intl.NumberFormat("en-EN").format(fair.sales)}`
					: `${Intl.NumberFormat("fr-FR").format(fair.sales)} $`;
			const saleHistory = (
				<Row key={i}>
					<SaleTitle>
						{formattedStartDate} - {formattedEndDate}
					</SaleTitle>

					<SaleValue>{formattedSales}</SaleValue>
				</Row>
			);
			fairElements.push(saleHistory);
			if (i === 0 && goalData.salesHistory.length !== 1) {
				fairElements.push(<SaleDivider key={"divider"} />);
			}
		}
		return fairElements;
	};

	//
	// Logic to handle navigation and rendering the SaveChangesModal
	//

	//Tracks changes made
	useEffect(() => {
		if (getRequestDone && (JSON.stringify(goalData) !== JSON.stringify(savedData))) {
			setAllChangesSaved(false);
		}
	}, [goalData, savedData, getRequestDone]);

	// scroll to top when save changes modal opens
	useEffect(() => {
		if (saveChangesModalOpen) {
			window.scrollTo({
				top: 0,
				left: 0,
				behavior: 'smooth' // or 'auto' or 'instant'
			});
		}
	}, [saveChangesModalOpen]);

	//Allows for navigation to next page after clicking discard changes on save changes modal
	useEffect(() => {
		if (allChangesSaved && allowNavigation) {
			navigate(builderNextLocation);
		}
	}, [
		allChangesSaved,
		saveChangesModalOpen,
		allowNavigation,
		navigate,
		builderNextLocation,
	]);

	/////////////////////////////////////
	if (!goalData) {
		return (
			<>
				<StyledGoalPage>{language === "en" ? "Loading..." : "Chargement..."}</StyledGoalPage>
			</>
		);
	}
	return (
		<>
			<StyledGoalPage>
				<PageIcon src={GoalIcon} alt="Goal Page Icon" />
				<Heading>
					{language === "en"
						? "Set or Update Your Goal"
						: "Fixer ou modifier votre objectif"}
				</Heading>
				<StyledBodyText>
					{language === "en"
						? "Making goals visible on your homepage will inspire everyone to work together to achieve them. Choose a sales goal, or the number of books you hope to sell. Keep in mind that every $10 in sales is the equivalent to one book sold. Tell your school community how acheiving your goal would benefit the school."
						: "Le fait de rendre les objectifs visibles sur votre page d’accueil incitera les gens à coopérer pour les atteindre. Choisissez un objectif de vente, c’est-à-dire le nombre de livres que vous espérez vendre. Gardez à l’esprit que chaque 10 $ de vente équivaut à un livre vendu. Expliquez à votre communauté scolaire en quoi la réalisation de votre objectif serait bénéfique pour l’école."}
				</StyledBodyText>
				<Wrapper>
					<StyledGoalAmount order={1}>
						<StyledSubheading>
							{language === "en"
								? "Set a Goal"
								: "Fixer un objectif"}
						</StyledSubheading>
						<FlexRow>
							<InlineElement>
								<TextInput
									label={
										language === "en" ? "Books" : "Livres"
									}
									value={goalData.goal.books}
									onChange={(e) =>
										updateGoalBooks(e, GoalType.books)
									}
									disabled={saveChangesModalOpen}
									error={booksGoalError}
								></TextInput>
								<Checkbox
									label={
										language === "en"
											? "Display book goal on homepage (Dollar amount will not be displayed)"
											: "Affichez le nombre de livres vendus à atteindre sur la page d’accueil (le montant en dollars ne sera pas affiché)."
									}
									checked={goalData.displayGoal}
									onChange={setBookFairGoalCkbox}
									disabled={saveChangesModalOpen}
									className="checkboxLabel"
								></Checkbox>
							</InlineElement>
							<InlineElement>
								{goalData.dollarsGoal &&
									(language === "en" ? (
										<CurrencyPlaceholderEN>
											$
										</CurrencyPlaceholderEN>
									) : (
										<CurrencyPlaceholderFR>
											$
										</CurrencyPlaceholderFR>
									))}
								<TextInput
									label="Dollars"
									className={
										goalData.dollarsGoal && (language === "en"
											? "currencyEN"
											: "currencyFR")
									}
									value={goalData.goal.dollars}
									onChange={(e) =>
										updateGoalBooks(e, GoalType.dollars)
									}
									disabled={saveChangesModalOpen}
									error={goalSalesError}
								></TextInput>
							</InlineElement>
						</FlexRow>
					</StyledGoalAmount>
					<StyledPromoteGoal order={3}>
						<StyledSubheading>
							{language === "en"
								? "Promote Your Goal"
								: "Faites la promotion de votre objectif"}
						</StyledSubheading>
						<FlexRow language={language}>
							<InlineElement>
								<StyledLimitedTextArea
									label={
										language === "en"
											? "Customize your English message (optional)"
											: "Personnalisez votre message en anglais (facultatif)"
									}
									value={goalData.goalMessage.english ? goalData.goalMessage.english : " "}
									onChange={(e) => updateMessage(e, "en")}
									limit={350}
									disabled={saveChangesModalOpen}
									language={language}
								></StyledLimitedTextArea>
							</InlineElement>
							<InlineElement>
								<StyledLimitedTextArea
									label={
										language === "en"
											? "Customize your French message (optional)"
											: "Personnalisez votre message en français (facultatif)"
									}
									value={goalData.goalMessage.french ? goalData.goalMessage.french : " "}
									onChange={(e) => updateMessage(e, "fr")}
									limit={350}
									disabled={saveChangesModalOpen}
									language={language}
								></StyledLimitedTextArea>
							</InlineElement>
						</FlexRow>
					</StyledPromoteGoal>
					<StyledSalesAmount order={2}>
						<StyledSubheading>
							{language === "en"
								? "Update Current Sales"
								: "Mettre à jour les ventes actuelles"}
						</StyledSubheading>
						<FlexRow>
							<InlineElement>
								<TextInput
									label={
										language === "en" ? "Books" : "Livres"
									}
									value={goalData.currentSales.books}
									onChange={(e) =>
										updateCurrentBooks(e, GoalType.books)
									}
									disabled={saveChangesModalOpen}
								></TextInput>
							</InlineElement>
							<InlineElement>
								{goalData.dollarsCurrentSales &&
									(language === "en" ? (
										<CurrencyPlaceholderEN>
											$
										</CurrencyPlaceholderEN>
									) : (
										<CurrencyPlaceholderFR>
											$
										</CurrencyPlaceholderFR>
									))}
								<TextInput
									label="Dollars"
									className={
										goalData.dollarsCurrentSales && (language === "en"
											? "currencyEN"
											: "currencyFR")
									}
									value={goalData.currentSales.dollars}
									onChange={(e) =>
										updateCurrentBooks(e, GoalType.dollars)
									}
									disabled={saveChangesModalOpen}
								></TextInput>
							</InlineElement>
						</FlexRow>
					</StyledSalesAmount>
					<FlexSection order={4}>
						<StyledSubheading>
							{language === "en"
								? "Sales History"
								: "Historique des ventes"}
						</StyledSubheading>
						{renderFairHistory()}
					</FlexSection>
				</Wrapper>
				<StyledRedButton
					handleClick={() => {
						trackSaveAndPublishButton();
						setBuilderNextLocation(goalNextLocation);
						saveAndContinueGoals();
						(savedData.goalMessage.english !== goalData.goalMessage.english) && trackPromoteYourGoalInputEnglish();
						(savedData.goalMessage.french !== goalData.goalMessage.french) && trackPromoteYourGoalInputFrench();
						(savedData.currentSales.books !== goalData.currentSales.books) && trackUpdateCurrentSalesBook();
						(savedData.currentSales.dollars !== goalData.currentSales.dollars) && trackUpdateCurrentSalesDollar();
					}}
					disabled={saveChangesModalOpen}
				>
					{language === "en"
						? "Save & Publish"
						: "Enregistrer et publier"}
				</StyledRedButton>
				<StyledTextLink
					handleClick={() => {
						trackSkipButton();
						if (!allChangesSaved) {
							setBuilderNextLocation(goalNextLocation);
							setBuilderNavDisabled(!builderNavDisabled);
							setSaveChangesModalOpen(!saveChangesModalOpen);
						} else {
							navigate(goalNextLocation);
						}
					}}
					disabled={saveChangesModalOpen}
				>
					{language === "en" ? "Skip" : "Ignorer"}
				</StyledTextLink>
			</StyledGoalPage>

			<Prompt
				message={() => {
					setSaveChangesModalOpen(true);
					setBuilderNavDisabled(true);
				}}
				when={!allChangesSaved}
			/>
			<SaveChangesModal
				saveAndPublish={saveAndContinueGoals}
				discardChanges={() => {
					discardAllChanges(savedData);
					setAllChangesSaved(true);
					setBuilderNavDisabled(false);
					setAllowNavigation(true);
					trackDiscardChanges();
				}}
				modalIsOpen={saveChangesModalOpen}
				handleModal={() => {
					setSaveChangesModalOpen(!saveChangesModalOpen);
				}}
				language={language}
			/>
		</>
	);
};

/*
  Page Specific Styles
*/
const StyledGoalPage = styled(Page)`
	padding-bottom: 105px;
	@media (max-width: 374px) {
		padding-bottom: 80px;
	}
`;

const Wrapper = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
	gap: 40px;
	width: 100%;
	max-width: 600px;
	@media (max-width: 719px) {
		gap: 0;
	}
`;

const StyledRedButton = styled(RedButton)`
	margin-top: 71.5px;
`;

const StyledTextLink = styled(TextLink)`
	margin-top: 23px;
	text-transform: capitalize;
`;

const StyledSubheading = styled(Subheading)`
	margin-bottom: 19px;
`;

const StyledBodyText = styled(BodyText)`
	font-size: 14px;
	line-height: 18px;
	font-weight: 400;
	max-width: 360px;
	padding-bottom: 32px;
	@media (max-width: 719px) {
		padding-bottom: 47px;
		padding-left: 20px;
		padding-right: 20px;
	}
	@media (max-width: 374px) {
		padding-bottom: 40px;
	}
`;

const StyledLimitedTextArea = styled(LimitedTextArea)`
	textarea {
		height: 279px;
		padding: 12px 19.88px 8px 18.12px;
	}
`;

interface FlexSectionProps {
	order?: number;
}

const FlexSection = styled.div<FlexSectionProps>`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	width: 45%;
	min-width: 280px;
	min-height: 160px;
	@media (max-width: 719px) {
		width: 100%;
		min-height: min-content;
		padding: 0 20px;
		margin-bottom: 16px;
	}
`;

const StyledGoalAmount = styled(FlexSection)`
	width: 100%;
`;

const StyledSalesAmount = styled(FlexSection)``;

const StyledPromoteGoal = styled(FlexSection)`
	width: 100%;
`;

const FlexRow = styled.div<{ language?: string }>`
	display: inline-flex;
	flex-direction: ${(props) =>
		props.language === "fr" ? "row-reverse" : "row"};
	width: 100%;
	gap: 40px;
	@media (max-width: 719px) {
		flex-direction: ${(props) =>
			props.language === "fr" ? "column-reverse" : "column"};
		gap: 0;
	}
`;

const Row = styled.div``;

const InlineElement = styled.div`
	position: relative;
	flex: 1 auto;
	width: calc(50% - 20px);
	@media (max-width: 719px) {
		width: 100%;
		margin-bottom: 16px;
	}
`;

const SaleTitle = styled.p`
	font-family: museo-sans;
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 19px;
	color: #333333;
	flex: 1;
`;

const SaleValue = styled(SaleTitle)`
	font-weight: 400;
`;

const SaleDivider = styled.hr`
	width: 100%;
	margin: 12px 0px;
	background: #e3e3e3;
	height: 1px;
	border: none;
`;

const CurrencyPlaceholder = styled.span`
	position: absolute;
	font-family: "museo-sans";
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 19px;
	color: #333333;
	left: 0;
	top: 33px;
	z-index: 1;
`;

const CurrencyPlaceholderEN = styled(CurrencyPlaceholder)``;

const CurrencyPlaceholderFR = styled(CurrencyPlaceholder)`
	left: auto;
	right: 0;
`;

export default GoalPage;
