import React from "react";
import styled from "styled-components";
import moment from "moment";
import "moment/min/locales";
import Heading from "../Heading";
import BodyText from "../BodyText";
import GoalComponent from "./GoalComponent";

interface Props {
	afterFair: boolean;
	fairInfoData: {
		name: string;
		end: string;
		start: string;
		location: string;
		address: {
			line1: string;
        	city: string;
        	province: string;
        	provinceCode: string;
        	postalCode: string;
		};
		welcomeMessage: any;
   		thankYouMessage: any;
	};
	goalData: {
		goal: {
			books: string;
			dollars: string;
		};
		currentSales: {
			books: string;
			dollars: string;
		};
		goalMessage: any;
		displayGoal: boolean;
	};
	language: string;
}

const FairInformationComponent = ({
	fairInfoData,
	goalData,
	afterFair,
	language,
}: Props) => {
	const {
		name,
		address,
		location,
		start,
		end,
		welcomeMessage,
    	thankYouMessage,
	} = fairInfoData;
	const { goal, currentSales, goalMessage, displayGoal } = goalData;
	moment.locale(language);
	const formattedStart = moment(start).format("LL").toString();
	const formattedEnd = moment(end).format("LL").toString();

	return (
		<FairInformationSection>
			<SectionContainer>
				<StyledHeading>{name}</StyledHeading>
				<Address>
					{address.line1}, {address.city}, {address.province} {address.postalCode}
				</Address>
				<Address>{location}</Address>
				<FairDatesLabel>{language === "en" ? "Fair Dates" : "Dates du Festival"}</FairDatesLabel>
				<FairDates>
					{formattedStart} - {formattedEnd}
				</FairDates>
				<Message showingGoal={displayGoal}>
          {afterFair ? (language === "en"
						? thankYouMessage["english"]
						: thankYouMessage["french"]) :
(language === "en"
						? welcomeMessage["english"]
						: welcomeMessage["french"]
          )}
				</Message>
				{displayGoal && (
					<GoalComponent
						goalBooks={parseInt(goal.books)}
						soldBooks={parseInt(currentSales.books)}
						goalMessage={goalMessage}
						language={language}
					/>
				)}
			</SectionContainer>
		</FairInformationSection>
	);
};

const SectionContainer = styled.div`
	padding: 32px 30px 0 30px;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 760px;
	@media (max-width: 800px) {
		box-sizing: border-box;
		padding: 32px 20px;
		width: 100%;
	}
`;

const Message = styled(BodyText)<{ showingGoal: boolean }>`
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 19px;
	letter-spacing: 0px;
	text-align: center;
	max-width: 453px;
	margin: 21px 0 ${(props) => (props.showingGoal ? "83px" : "57px")} 0;
	@media (max-width: 375px) {
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		line-height: 19px;
		width: 325px;
		margin-bottom: 21px 0 31px 0;
	}
`;
const FairDates = styled(BodyText)`
	font-size: 32px;
	font-style: normal;
	font-weight: 600;
	line-height: 35px;
	letter-spacing: 0px;
	text-align: center;
	color: #333333;
	margin-top: 12px;
	@media (max-width: 375px) {
		font-weight: 600;
		font-size: 28px;
		line-height: 32px;
	}
`;

const Address = styled(BodyText)`
	font-size: 13px;
	font-style: normal;
	font-weight: 600;
	line-height: 20px;
	letter-spacing: 1.5px;
	text-align: center;
	text-transform: uppercase;
	@media (max-width: 375px) {
		font-weight: 600;
		font-size: 13px;
		line-height: 20px;
		width: 315px;
	}
`;

const FairDatesLabel = styled(BodyText)`
	font-size: 11px;
	font-style: normal;
	font-weight: 600;
	line-height: 12px;
	letter-spacing: 1.5px;
	text-align: center;
	background: #015e8c;
	color: #ffffff;
	padding: 4px 12px;
	text-transform: uppercase;
	margin-top: 34px;
`;
const StyledHeading = styled(Heading)`
	text-transform: uppercase;
	font-size: 44px;
	font-style: normal;
	font-weight: 600;
	line-height: 47px;
	letter-spacing: 2px;
	text-align: center;
	max-width: 760px;
	color: #333333;
	margin-top: 32px;
	@media (max-width: 375px) {
		font-size: 34px;
		font-weight: 600;
		line-height: 37px;
	}
`;

const FairInformationSection = styled.div`
	width: 100%;
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
`;
export default FairInformationComponent;
