import React from "react";
import styled from "styled-components";
import BodyText from "./BodyText";

interface Props {
	className?: any;
	value: any;
	onChange: (value: string) => void;
	label?: string;
	disabled?: boolean;
	limit?: number;
	error?: string;
	language?: string;
}

const LimitedTextArea = ({
	className,
	value,
	onChange,
	label,
	disabled,
	limit,
	error,
	language,
}: Props) => {
	return (
		<StyledContainer data-disabled={disabled} className={className}>
			{label && <StyledLabel id="inputLabel">{label}</StyledLabel>}
			<StyledTextArea
				value={value}
				onChange={(e) => {
					onChange(
						e.target.value
							.slice(0, limit)
							.replace(/ {2}|\r\n|\n|\r/gm, "")
					);
				}}
				disabled={disabled}
				data-error={error}
			/>
			{limit && (
				<StyledBodyText>
					{limit - value.length}{" "}
					{language === "fr"
						? `${
								limit - value.length === 1
									? "caractère"
									: "caractères"
						  } restants`
						: `${
								limit - value.length === 1
									? "character"
									: "characters"
						  } remaining`}
				</StyledBodyText>
			)}
			{error && <ErrorLabel id="textInputErrorLabel">{error}</ErrorLabel>}
		</StyledContainer>
	);
};

const StyledContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	&[data-disabled=true] > #inputLabel {
		opacity: 0.2;
	}
`;

const StyledTextArea = styled.textarea`
	height: 166px;
	resize: none;
	background: #ffffff;
	border: 1px solid #919191;
	box-sizing: border-box;
	border-radius: 5px;
	font-family: museo-sans;
	font-size: 16px;
	line-height: 19px;
	color: #333333;
	padding: 16.5px 28.5px 13.5px;
	margin-bottom: 8px;
	&:focus {
		outline: 2px auto #116eee;
	}
	&:disabled {
		opacity: 0.2;
		border: 1px solid #333333;
	}
	&[data-error] {
		border: 2px solid #e81111;
	}
`;

const StyledLabel = styled.label`
	display: block;
	font-family: museo-sans;
	font-style: normal;
	font-weight: normal;
	font-size: 13px;
	line-height: 15px;
	color: #333333;
	padding-bottom: 8px;
`;

const StyledBodyText = styled(BodyText)`
	margin: 0;
	text-align: left;
	font-size: 11px;
	font-style: normal;
	font-weight: 400;
	line-height: 12px;
	letter-spacing: 0px;
`;
const ErrorLabel = styled(StyledLabel)`
	padding: 0;
	margin-top: 8px;
	color: #e81111;
`;

export default LimitedTextArea;
