declare global {
	interface Window {
		dumbleData: any;
		_satellite: any;
	}
}

export function initializeScripts() {
	const scriptSrc = process.env.REACT_APP_ADOBE_HEADER_SCRIPT || "";
	console.log(scriptSrc);
	const headerScript = document.createElement("script");
	headerScript.setAttribute("src", scriptSrc);
	headerScript.addEventListener("load", function () {
		window._satellite.pageBottom();
	});
	document.head.appendChild(headerScript);
}

export function initDumbleData() {
	window.dumbleData = {
		siteInfo: {
			name: "Canada Toolkit",
			environment: process.env.REACT_APP_ENV,
			language: "en"
		},
		page: {
			domain: window.location.hostname,
			destinationURL: window.location.href,
			isError: false,
			errorCode: "",
			prevPage: "",
			pageName: document.title,
			pageInfo: {
				breadcrumb: "",
				title: document.title,
			},
			siteSection: "",
			pageType: "", // Homepage Builder, Setup (COA, Details & Settings), Live Homepage
		},
		user: {
			loginStatus: false,
			fairID: "", // HPB ask backend !!
			accountID: "", // HPB ask backend !!
			schoolName: "",
			fairStart: "",
			fairEnd: "",
			flyerCount: [],
			fairDates: "",
			setUpDate: "",
			dollarBalance: "",
			bookDefaultAmount: "",
			dollarDefaultAmount: "",
			totalEventsCreated: "",
		},
		fair: {
			totalScheduledFairs: "",
			totalPastFairs: "",
			totalUnconfirmedFairs: "",
		},
	};
}

export function updatePageType(type: string) {
	window.dumbleData.page.pageType = type;
}

export function updateIsError(isError: boolean) {
	window.dumbleData.page.isError = isError;
}

export function updateErrorCode(code: string | null) {
	window.dumbleData.page.errorCode = code;
}

export function updatePrevPage(page: string | null) {
	window.dumbleData.page.prevPage = page;
}

export function updateUserLoginStatus(loginStatus: boolean) {
	window.dumbleData.user.loginStatus = loginStatus;
}

export function updateUserFairId(fairID: number) {
	window.dumbleData.user.fairID = fairID;
}

export function updateUserAccountId(accountID: number) {
	window.dumbleData.user.accountID = accountID;
}

export function updateUserSchoolName(schoolName: string) {
	window.dumbleData.user.schoolName = schoolName;
}

export function updateUserFairStart(fairStart: string) {
	window.dumbleData.user.fairStart = fairStart;
}

export function updateUserFairEnd(fairEnd: string) {
	window.dumbleData.user.fairEnd = fairEnd;
}

export function updateUserFlyerCount(fifthAndBelow: number, sixthAndAbove: number) {
	window.dumbleData.user.flyerCount = [fifthAndBelow, sixthAndAbove];
}

export function updateFairAccountID(accountID: number) {
	window.dumbleData.user.accountID = accountID;
}

export function updateFairTotalScheduledFairs(total: number) {
	window.dumbleData.user.totalScheduledFairs = total;
}

export function updateFairTotalPastFairs(total: number) {
	window.dumbleData.user.totalPastFairs = total;
}

export function updateFairTotalUnconfirmedFairs(total: number) {
	window.dumbleData.user.totalUnconfirmedFairs = total;
}

export function updateUserSetUpDate(setUpDate: number) {
	window.dumbleData.user.setUpDate = setUpDate;
}

export function updateUserDollarBalance(dollarBalance: number) {
	window.dumbleData.user.dollarBalance = dollarBalance;
}

export function updateUserBookDefaultAmount(amount: number) {
	window.dumbleData.user.bookDefaultAmount = amount;
}

export function updateUserDollarDefaultAmount(amount: number) {
	window.dumbleData.user.dollarDefaultAmount = amount;
}

export function updateUserTotalEventsCreated(total: number) {
	window.dumbleData.user.totalEventsCreated = total;
}

export function updateFairTotalEventsCreated(total: number) {
	window.dumbleData.fair.totalEventsCreated = total;
}

/* Top Nav */

export function trackTopNavLanguageToggle() {
	window._satellite.track("top-nav-language-toggle", {
		"button-text": "language-toggle",
	});
}

/* Bottom Nav */

export function trackBottomNavSocialButton() {
	window._satellite.track("bottom-nav-social-button", {
		"button-text": "social-button",
	});
}

export function trackBottomNavReturnToBookFairs() {
	window._satellite.track("bottom-nav-return-to-book-fairs", {
		"button-text": "return-to-book-fairs-link",
	});
}

export function trackBottomNavContactUs() {
	window._satellite.track("bottom-nav-contact-us", {
		"button-text": "contact-us-email-link",
	});
}

export function trackBottomNavLink(linkName: string) {
	window._satellite.track("bottom-nav-link", {
		"button-text": linkName,
	});
}

/* Login Page */

export function trackLoginPageRequestPassword() {
	window._satellite.track('login-page-request-password', {
		"button-text": "request-password",
	});
}

export function trackLoginPageSubmit() {
	window._satellite.track('login-page-submit', {
		"button-text": "submit-button",
	});
}

/* Request Password Modal */

export function trackRequestPasswordModalSubmit() {
	window._satellite.track('request-password-modal-submit', {
		"button-text": "submit-button",
	});
}

export function trackRequestPasswordModalExitIcon() {
	window._satellite.track('request-password-modal-exit-icon', {
		"button-text": "exit-icon",
	});
}

/* COA Page */

export function trackCoaChangeFair() {
	window._satellite.track("coa-change-fair", {
		"button-text": "change-fair",
	});
}

export function trackCoaPrint() {
	window._satellite.track("coa-print", {
		"button-text": "print",
	});
}

export function trackCoaEmail() {
	window._satellite.track("coa-email", {
		"button-text": "email",
	});
}

export function trackCoaAccept() {
	window._satellite.track("coa-accept", {
		"button-text": "accept-button",
	});
}

export function trackCoaRequestDateChange() {
	window._satellite.track("coa-rest-date-change", {
		"button-text": "request-date-change-button",
	});
}

export function trackCoaFlyerCountFinalAmount(gradesjk5amount: number, grades68amount: number) {
	window._satellite.track("coa-flyer-count-final-amount", {
		"flyer-count": [gradesjk5amount, grades68amount]
	});
}

/* Forward Details Email Modal */

export function trackForwardDetailsModalCancel() {
	window._satellite.track('forward-details-modal-cancel', {
		"button-text": "cancel-exit-icon",
	});
}

export function trackForwardDetailsModalSend() {
	window._satellite.track('forward-details-modal-send', {
		"button-text": "send",
	});
}

/* COA Confirmation Page */

export function trackCoaConfirmationChangeFair() {
	window._satellite.track('coa-confirmation-change-fair', {
		"button-text": "change-fair",
	});
}

export function trackCoaConfirmationStartPlanningYourFairButton() {
	window._satellite.track('coa-confirmation-start-planning-your-fair-button', {
		"button-text": "start-planning-your-fair-button",
	});
}

/* Fair Selector */

export function trackFairConfirm() {
	window._satellite.track('fair-confirm', {
		"button-text": "confirm",
	});
}

export function trackFairSelect() {
	window._satellite.track('fair-select', {
		"button-text": "select",
	});
}

/* Fair Details and Settings */

export function trackChangeFair() {
	window._satellite.track('change-fair', {
		"button-text": "change-fair",
	});
}

export function trackPrintBookFairAcknowledgement() {
	window._satellite.track('print-book-fair-acknowledgement', {
		"button-text": 'print-book-fair-acknowledgement',
	});
}

export function trackCopyUrl(languageChosen: string) {
	window._satellite.track('copy-url', {
		"button-text": languageChosen,
	});
}

export function trackCustomizeHomepage() {
	window._satellite.track('customize-homepage', {
		"button-text": "customize-homepage",
	});
}

export function trackViewHomepage(languageChosen: string) {
	window._satellite.track('view-homepage', {
		"button-text": languageChosen,
	});
}

export function trackClickToEmail() {
	window._satellite.track('click-to-email', {
		"button-text": "click-to-email",
	});
}

export function trackViewOnlineTools() {
	window._satellite.track('view-online-tools', {
		"button-text": "view-online-tools",
	});
}

export function trackPdfDownload(downloadName: string) {
	window._satellite.track('pdf-download', {
		"pdf-download": downloadName,
	});
}

/* HPB */

export function trackSaveAndPublishButton() {
	window._satellite.track('save-and-publish-button', {
		"button-text": 'save-and-publish',
	});
}

export function trackSkipButton() {
	window._satellite.track('skip-button', {
		"button-text": 'skip',
	});
}

export function trackHomepageBuilderContinueButton() {
	window._satellite.track('homepage-builder-continue-button', {
		"button-text": 'continue',
	});
}

export function trackHomepageBuilderIconClick(icon: string) {
	window._satellite.track('homepage-builder-icon-click', {
		"button-text": icon,
	});
}

/* HPB Side Nav */

export function trackSideNavClick(linkName: string) {
	window._satellite.track('side-nav-click', {
		"button-text": linkName,
	});
}

/* HPB Set or Update Goal */

export function trackPromoteYourGoalInputEnglish() {
	window._satellite.track('promote-your-goal-input-english', {
		"button-text": 'promote-your-goal-input-english',
	});
}

export function trackPromoteYourGoalInputFrench() {
	window._satellite.track('promote-your-goal-input-french', {
		"button-text": 'promote-your-goal-input-french',
	});
}

export function trackUpdateCurrentSalesBook() {
	window._satellite.track('update-current-sales-book', {
		"button-text": 'update-current-sales-books',
	});
}

export function trackUpdateCurrentSalesDollar() {
	window._satellite.track('update-current-sales-dollar', {
		"button-text": 'update-current-sales-dollars',
	});
}

export function trackSaveandPublishButton() {
	window._satellite.track('save-and-publish-button', {
		"button-text": 'save-and-publish',
	});
}

/* HPB Fair Information */

export function trackCustomizeYourMessageEnglish() {
	window._satellite.track('customize-your-message-english', {
		"button-text": 'customize-your-message-english',
	});
}

export function trackCustomizeYourMessageFrench() {
	window._satellite.track('customize-your-message-french', {
		"button-text": 'customize-your-message-french',
	});
}

export function trackCustomizeYourMessageThankYouEnglish() {
	window._satellite.track('customize-your-message-thank-you-english', {
		"button-text": 'customize-your-message-thank-you-english',
	});
}

export function trackCustomizeYourMessageThankYouFrench() {
	window._satellite.track('customize-your-message-thank-you-french', {
		"button-text": 'customize-your-message-thank-you-french',
	});
}

export function trackInSchoolPaymentOptions(cash: boolean, checks: boolean, card: boolean) {
	window._satellite.track('in-school-payment-options', [cash, checks, card]);
}

/* HPB Schedule Activities */

export function trackAddEventDropdown(eventType: string) {
	window._satellite.track('add-event-dropdown', {
		"button-text": eventType,
	});
}

/* HPB Recruit Volunteers */

export function trackDownloadPaperSignUpForm() {
	window._satellite.track('download-paper-sign-up-form', {
		"button-text": 'download-a-paper-sign-up-form',
	});
}

export function trackUrlEntry() {
	window._satellite.track('url-entry', {
		"button-text": 'url-entry',
	});
}

/* HPB Share Your Homepage */

export function trackCustomizeUrlLink() {
	window._satellite.track('customize-url-link', {
		"button-text": 'customize-url-link',
	});
}

export function trackCopyHomepageUrlEnglish() {
	window._satellite.track('copy-homepage-url-english', {
		"button-text": 'copy-homepage-url-english',
	});
}

export function trackViewLiveHomepageUrlEnglish() {
	window._satellite.track('view-live-homepage-url-english', {
		"button-text": 'view-live-homepage-url-english',
	});
}

export function trackCopyHomepageUrlFrench() {
	window._satellite.track('copy-homepage-url-french', {
		"button-text": 'copy-homepage-url-french',
	});
}

export function trackViewLiveHomepageUrlFrench() {
	window._satellite.track('view-live-homepage-url-french', {
		"button-text": 'view-live-homepage-url-french',
	});
}

export function trackSocialButtonEnglish() {
	window._satellite.track('social-button-english', {
		"button-text": 'social-button-english',
	});
}

export function trackSocialButtonFrench() {
	window._satellite.track('social-button-french', {
		"button-text": 'social-button-french',
	});
}

/* HPB Save Changes Page */

export function trackDiscardChanges() {
	window._satellite.track('discard-changes', {
		"button-text": 'discard-changes',
	});
}

/* Live Homepage */

export function trackSocialButtonClick() {
	window._satellite.track('social-button-click', {
		"button-text": 'social-button-click',
	});
}

export function trackLearnMore() {
	window._satellite.track('learn-more', {
		"button-text": 'learn-more',
	});
}

export function trackSignUpForEmails() {
	window._satellite.track('sign-up-for-emails', {
		"button-text": 'sign-up-for-emails-checkboxes',
	});
}

export function trackSignUpVolunteersButton() {
	window._satellite.track('sign-up-volunteers-button', {
		"button-text": 'sign-up-volunteers-button',
	});
}

export function trackExploreDigitalFlyerClick() {
	window._satellite.track('explore-digital-flyer-click', {
		"button-text": 'explore-digital-flyer-click',
	});
}

export function trackContactChairpersonButton() {
	window._satellite.track('contact-chairperson-button', {
		"button-text": 'contact-chairperson-button',
	});
}

export function trackBookFairSchedule() {
	window._satellite.track('book-fair-schedule', {
		"button-text": 'book-fair-schedule',
	});
}

export function trackEventCalendarIcon(eventTitle: string) {
	window._satellite.track('track-event-calendar-icon', {
		"button-text": eventTitle,
	});
}