import React from "react";
import CopyLinkIcon from "../../images/CopyLink.svg";
import styled from "styled-components";
import useCustomAlert from "../../hooks/HomepageBuilder/useCustomAlert";

interface Props {
	customUrl: string;
	language?: string;
}

const CopyLinkButton = ({ customUrl, language }: Props) => {
	const msg =
		language === "en"
			? "Homepage URL is copied to your clipboard"
			: "L’URL de la page d’accueil est copiée dans votre presse-papiers.";
	const { activateAlert, alert } = useCustomAlert(msg);

	const handleCopyToClipboard = async () => {
		try {
			await navigator.clipboard.writeText(document.location.href);
			activateAlert();
		} catch (err) {
			console.error(err);
		}
	};

	return (
		<>
			{alert}
			<StyledCopyLinkButton onClick={handleCopyToClipboard}>
				<StyledCopyLinkIcon
					src={CopyLinkIcon}
					alt="Copy Homepage URL to your clipboard"
				/>
			</StyledCopyLinkButton>
		</>
	);
};

const StyledCopyLinkButton = styled.button`
	padding: 0;
	background: none;
	border: none;
	border-radius: 56px;
	display: flex;
	width: 40px;
	height: 40px;
	&:focus {
		outline: 2px solid #002d70;
		outline-offset: 2px;
	}
`;

const StyledCopyLinkIcon = styled.img`
	cursor: pointer;
	width: 40px;
`;

export default CopyLinkButton;
