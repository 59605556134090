import React from "react";
import styled from "styled-components";
import { useConfig } from "../../providers/ConfigContext";
import Card from "./Card";
import { RedButton } from "../Button";
import { trackLearnMore } from "../../DumbleData";

interface Props {
	language: string;
}

const Parent = ({ language }: Props) => {
	const { damAssetsOrigin } = useConfig();
	return (
		<Card lang={language}>
			<Header>
				<StyledNotificationsIcon
					src={`${damAssetsOrigin}canadatoolkit/LiveHomepage/Icons/Section/Email.png`}
				/>
			</Header>
			<StyledBodyHeading>
				{language === "en"
					? "How to Be a Book Fair Parent"
					: "Comment être un parent du Festival du Livre"}
			</StyledBodyHeading>
			<StyledBodyText>
				{language === "en" ? (
					<ul>
						<li>
							Keep an eye out for the highlights flyers to see our
							selection of featured books.
						</li>

						<li>
							Save the date! Mark the big days on your calendar!
						</li>

						<li>
							Ask your school how you can be a volunteer at the
							Fair.
						</li>

						<li>
							Check out the Classroom Wish List displays at the
							Fair.
						</li>

						<li>
							Enter your child into the Book Fair Family Night
							Draw! (Only at participating schools)
						</li>
					</ul>
				) : (
					<ul>
						<li>
							Consultez les dépliants promotionnels pour découvrir
							notre sélection de livres.
						</li>

						<li>Notez la date du Festival sur votre calendrier!</li>

						<li>
							Demandez à votre école comment travailler comme
							bénévole au Festival.
						</li>

						<li>
							Consultez la Liste des souhaits de la classe
							affichée au Festival.
						</li>

						<li>
							Inscrivez votre enfant au Tirage de la soirée des
							familles du Festival du Livre! (Seulement dans les
							écoles participantes)
						</li>
					</ul>
				)}
			</StyledBodyText>
			<StyledRedButton
				href={
					language === "en"
						? "https://www2.scholastic.ca/bookfairs/parents-and-families/"
						: "https://www2.scholastic.ca/bookfairs/parents-et-familles/"
				}
				handleClick={() => trackLearnMore()}
			>
				{language === "en" ? "Learn More" : "En savoir plus"}
			</StyledRedButton>
		</Card>
	);
};

const StyledNotificationsIcon = styled.img`
	width: 68px;
	margin-bottom: 19px;
	margin-top: 28px;
	@media (max-width: 720px) {
		margin-top: 12.6px;
	}
`;
const StyledRedButton = styled(RedButton)`
	width: 136px;
	margin: 0 auto;
	display: block;
`;
const StyledBodyHeading = styled("h3")`
	font-family: museo-sans;
	font-size: 21px;
	line-height: 24px;
	text-align: center;
	font-weight: 600;
	margin: 20px 0;
`;

const StyledBodyText = styled("span")`
	font-style: normal;
	font-weight: 300;
	font-size: 17px;
	line-height: 25px;
	color: #000;
	margin-bottom: 20px;
	text-align: left;
`;
const Header = styled.div`
	border-bottom: 1px solid #ffd7c7;
	display: flex;
	flex-direction: column;
	align-items: center;
`;
export default Parent;
