import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useConfig } from "../../../providers/ConfigContext";
import { Page } from "../Page";
import Heading from "../../Heading";
import PageIcon from "../PageIcon";
import BodyText from "../../BodyText";
import { RedButton } from "../../Button";
import TextLink from "../../TextLink";
import RedArrowDown from "../../../images/RedArrow.svg";
import RedArrowUp from "../../../images/RedArrowUp.svg";
import ActivitiesIcon from "../../../images/Activities.svg";
import { AddEventComponent } from "../AddEvent/AddEvent";
import { ScheduledEvent } from "../AddEvent/ScheduledEvent";
import axios from "axios";
import SaveChangesModal from "../SaveChangesModal";
import Prompt from "../Prompt";
import { convertStringToDate } from "../../../utils/DateConversion";
import EventCategories from "../AddEvent/EventCategories.json";
import { trackAddEventDropdown, trackDiscardChanges, trackSaveAndPublishButton, trackSkipButton, updatePageType, updateUserLoginStatus, updateUserFairId, updateUserAccountId, updateUserTotalEventsCreated } from "../../../DumbleData";
import { handleGenericErrors, handleGenericSuccess } from "../../../utils/ErrorUtility";

interface Props {
	language: string;
	builderNavDisabled: boolean;
	setBuilderNavDisabled: any;
	builderNextLocation: string;
	setBuilderNextLocation: any;
}

export const ScheduleActivitiesPage = ({ 
	language,
	builderNavDisabled,
	setBuilderNavDisabled,
	builderNextLocation,
	setBuilderNextLocation}: Props) => {
	const [schedule, setSchedule] = useState<any>();
	const scheduleNextLocation = "/customize/volunteer";
	setBuilderNextLocation(scheduleNextLocation);
	const { damAssetsOrigin } = useConfig();
	const [event, setEvent] = useState(0);
	const [eventMenuIsOpen, setEventMenuIsOpen] = useState(false);
	const [addEventOpened, setAddEventOpened] = useState(false);
	const [saveChangesModalOpen, setSaveChangesModalOpen] = useState(false);
	const [editEventOpened, setEditEventOpened] = useState(false);
	const [editEventInfo, setEditEventInfo] = useState<any>([{}, 0]);
	const navigate = useNavigate();
	const isActivities = schedule && schedule.length > 0;
	const [allChangesSaved, setAllChangesSaved] = useState(true);
	const [getRequestDone, setGetRequestDone] = useState(false);
	const [allowNavigation, setAllowNavigation] = useState(false);
	const [savedData, setSavedData] = useState<any>();
	const [fairInfo, setFairInfo] = useState<any>();

	const menuToggle = () => {
		setEventMenuIsOpen(!eventMenuIsOpen);
	};

	const eventChosen = (index: number) => {
		setAddEventOpened(true);
		setEventMenuIsOpen(false);
		setEvent(index);
		trackAddEventDropdown(EventCategories[index].englishType);
	};

	const editEvent = (index: number) => {
		setEditEventOpened(true);
		setEditEventInfo([schedule[index], index]);
	};

	// GET request
	useEffect(() => {
		window.scrollTo(0, 0);
		axios.get(`${process.env.REACT_APP_ENV_BASE_URL}/api/user/fairs/current/homepage/events`, {
		  withCredentials: true
		}).then((res) => {
			if (res.status === 200){
				let tempSchedule: any[] = [];
				res.data.events.map((event: any, index: number) => (
					tempSchedule.push({
						date: event.date,
						category: event.category,
						title: event.title,
						startTime: event.startTime,
						endTime: event.endTime,
						detailMessage: {
							english: event.detailMessage.english,
							french: event.detailMessage.french
						}
					})
				));
				let tempSortedSchedule = sortEvents(tempSchedule);
				setSavedData(tempSortedSchedule);
				setSchedule(tempSortedSchedule);
				setFairInfo(res.data.fairInfo);
				setGetRequestDone(true);
				updateUserLoginStatus(true);
				updateUserFairId(res.data.fairId);
				updateUserAccountId(res.data.bookfairAccountId)
				updatePageType("homepage-builder");
			} else {
				handleGenericSuccess(res);
			}
		})
		.catch((error) => {
			handleGenericErrors(error);
		})
	}, []);

	const sortEvents = (events: any) => {
		return events.sort(function(a: any, b: any) { return new Date(`${a.date} ${a.startTime}`).valueOf() - new Date(`${b.date} ${b.startTime}`).valueOf() });
	};

	//Adds newly created event to schedule
	const addNewEvent = (newEvent: any) => {
		let events = [...schedule, newEvent];
		setSchedule(sortEvents(events));
	};

	// Adds updated event to schedule
	const updateEvent = (newEvent: any, eventPreEdit: any, index: number) => {
		schedule[index] = newEvent;
		let events = [...schedule];
		setSchedule(sortEvents(events));
		setEditEventOpened(false);
	};

	// Removes event from schedule
	const deleteEvent = (index: number) => {
		const tempSchedule = schedule;
		tempSchedule.splice(index, 1);
		let events = [...tempSchedule];
		setSchedule(sortEvents(events));
	};

	// Posts updated schedule to backend
	const postEvents = async () => {
		let tempEvents : Array<{
			date: string,
			category: string,
			title: string,
			startTime: string,
			endTime: string,
			detailMessage: {
				english: string,
				french: string,
			}
		}> = [];

		schedule.forEach((event: any, index: number) => {
			tempEvents.push({
				date: event.date,
				category: event.category,
				title: event.title,
				startTime: event.startTime,
				endTime: event.endTime,
				detailMessage: {
					english: event.detailMessage.english,
					french: event.detailMessage.french,
				}
			})
		})

		await axios
			.put(
				`${process.env.REACT_APP_ENV_BASE_URL}/api/user/fairs/current/homepage/events`,
				tempEvents,
				{ withCredentials: true }
			)
			.then((response) => {
				if (response.status === 200) {
					setAllChangesSaved(true);
					setBuilderNavDisabled(false);
					setAllowNavigation(true);
					updateUserTotalEventsCreated(schedule.length);
				} else {
					handleGenericSuccess(response);
				}
			})
			.catch((err) => {
				handleGenericErrors(err);
			});
	};

	useEffect(() => {
		const handleFocusOut = (event: any) => {
			const target = event.relatedTarget;
			if (target === null || !collection.includes(target)) {
				setEventMenuIsOpen(false);
			}
		};
		const eventMenu = document.getElementById("eventOption0");
		const eventMenuContainer = document.getElementById("eventOptions");

		if (eventMenu) {
			eventMenu.focus();
		}

		const collection = Array.from(
			document.getElementsByClassName("eventOptions")
		);
		eventMenuContainer &&
			eventMenuContainer.addEventListener("focusout", handleFocusOut);
		return () => {
			// Unbind the event listener on clean up
			eventMenuContainer &&
				eventMenuContainer.removeEventListener(
					"focusout",
					handleFocusOut
				);
		};
	}, [eventMenuIsOpen]);

	// Tracks changes made
	useEffect(() => {
		if ( getRequestDone && (JSON.stringify(schedule) !== JSON.stringify(savedData)) ) {
			setAllChangesSaved(false);
		}
	}, [schedule,
		savedData,
		getRequestDone]);

	// Allows for navigation to next page after clicking discard changes on save changes modal
	useEffect(() => {
		if (allChangesSaved && allowNavigation) {
			navigate(builderNextLocation);
		}
	}, [
		allChangesSaved,
		saveChangesModalOpen,
		allowNavigation,
		navigate,
		builderNextLocation,
	]);

	// When save changes modal opens, scroll to top of page
	useEffect(() => {
		if (saveChangesModalOpen) {
			window.scrollTo({
				top: 0,
				left: 0,
				behavior: 'smooth'
			});
		}
	}, [saveChangesModalOpen]);

	return (
		<>
			<Page>
				<PageIcon
					src={`${damAssetsOrigin}canadatoolkit/homepagebuilder/Activities.svg`}
					alt="Schedule Activities Icon"
					defaultImg={ActivitiesIcon}
				/>
				<StyledHeading>{language === "en" ? "Schedule Activities" : "Planifier des activités"}</StyledHeading>
				<StyledBodyText>
				{language === "en" ?
					`Holding Book Fair events is a great way to generate
					excitement and invite additional shoppers to your
					Fair. See below for a variety of activities to host
					as well as planning tips and ideas.` :
					`L’organisation d’événements pour le Festival du Livre 
					est un excellent moyen de susciter l’enthousiasme et 
					d’inviter des acheteur·ses supplémentaires à votre Festival. 
					Vous trouverez ci-dessous une variété d’activités à organiser 
					ainsi que des conseils et des idées pour la planification.`
				}
				</StyledBodyText>
				{isActivities && !editEventOpened && (
					<ScheduleSection>
						<StyledSubHeading>{language === "en" ? "Your Schedule" : "Votre horaire"}</StyledSubHeading>
						{schedule.map(
							(event: any, index: number) => (
								<ScheduledEvent
									eventDate={event.date}
									startTime={event.startTime}
									endTime={event.endTime}
									title={event.title}
									description={language === "en" ? event.detailMessage.english : event.detailMessage.french}
									index={index}
									editEvent={editEvent}
									category={event.category}
									createDate={event.createDate}
									key={event.id}
									deleteEvent={deleteEvent}
									language={language}
								/>
							)
						)}
					</ScheduleSection>
				)}
				<AddEventSection>
					{!addEventOpened && !editEventOpened && (
						<AddEvent onClick={menuToggle}>
							<p>{language === "en" ? "Add Event" : "Ajouter un événement"}</p>
							{eventMenuIsOpen ? <DownArrow src={RedArrowUp} /> : <DownArrow src={RedArrowDown} />}
						</AddEvent>
					)}
					<CenteredContainer>
						{(eventMenuIsOpen && language === "en") && (
							<EventsContainer id="eventOptions">
								{EventCategories.filter((c)=> c.englishType !== 'Opening Day' && c.englishType !== 'Closing Day').map((event: any, index: number) => (
										<EventOption
											id={`eventOption${index}`}
											onClick={() => {
												eventChosen(index);
											}}
											onKeyPress={() => {
												eventChosen(index);
											}}
											tabIndex={0}
											className={
												"eventOptions"
											}
											key={index}
										>
											{event.englishType}
										</EventOption>
									)
								)} 
							</EventsContainer>
						)}
						{(eventMenuIsOpen && language !== "en") && (
							<EventsContainer id="eventOptions">
								{EventCategories.filter((c)=> c.englishType !== 'Opening Day' && c.englishType !== 'Closing Day').map((event: any, index: number) => (
										<EventOption
											id={`eventOption${index}`}
											onClick={() => {
												eventChosen(index);
											}}
											onKeyPress={() => {
												eventChosen(index);
											}}
											tabIndex={0}
											className={
												"eventOptions"
											}
											key={index}
										>
											{event.frenchType}
										</EventOption>
									)
								)} 
							</EventsContainer>
						)}
					</CenteredContainer>
					{addEventOpened && (
						<AddEventComponent
							language={language}
							setMenuOpen={menuToggle}
							setAddEventOpen={() => {
								setAddEventOpened(!addEventOpened);
							}}
							fairStartDate={convertStringToDate(fairInfo.start)}
							fairEndDate={convertStringToDate(fairInfo.end)}
							eventTypeIndex={event}
							isMenuOpen={eventMenuIsOpen}
							saveEvent={addNewEvent}
						/>
					)}
					{editEventOpened && (
						<AddEventComponent
							language={language}
							setMenuOpen={menuToggle}
							setAddEventOpen={() => {
								setEditEventOpened(!editEventOpened);
							}}
							fairStartDate={convertStringToDate(fairInfo.start)}
							fairEndDate={convertStringToDate(fairInfo.end)}
							isMenuOpen={eventMenuIsOpen}
							saveEvent={updateEvent}
							editEventInfo={editEventInfo}
						/>
					)}
				</AddEventSection>
				<RedButton
					disabled={addEventOpened || editEventOpened}
					handleClick={() => {
						trackSaveAndPublishButton();
						setBuilderNextLocation(scheduleNextLocation);
						postEvents();
					}}
				>
					{language === "en" ? "Save & Publish" : "Enregistrer et publier"}
				</RedButton>
				<StyledTextLink
					disabled={addEventOpened}
					handleClick={() => {
						trackSkipButton();
						if (!allChangesSaved) {
							setBuilderNextLocation(scheduleNextLocation);
							setBuilderNavDisabled(!builderNavDisabled);
							setSaveChangesModalOpen(!saveChangesModalOpen);
						} else {
							navigate(scheduleNextLocation);
						}
					}}
				>
					{language === "en" ? "Skip" : "Ignorer"}
				</StyledTextLink>
			</Page>

			<Prompt
				message={() => {
					setSaveChangesModalOpen(true);
					setBuilderNavDisabled(true);
				}}
				when={!allChangesSaved}
			/>
			<SaveChangesModal
				language={language}
				saveAndPublish={postEvents}
				discardChanges={() => {
					setAllChangesSaved(true);
					setBuilderNavDisabled(false);
					setAllowNavigation(true);
					setSchedule(savedData);
					trackDiscardChanges();
				}}
				modalIsOpen={saveChangesModalOpen}
				handleModal={() => {
					setSaveChangesModalOpen(!saveChangesModalOpen);
				}}
			/>
		</>
	);
};

/*
	Page Specific Styles
*/

const CenteredContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	z-index: 103;
`;
const ScheduleSection = styled.div`
	margin-top: 24px;
	width: 100%;
	display: flex;
    flex-direction: column;
    align-items: center;
`;
const StyledHeading = styled(Heading)`
	margin: 0;
	padding-top: 24px;
	padding-bottom: 12px;
`;
const StyledSubHeading = styled(Heading)`
	font-size: 24px;
	font-style: normal;
	font-weight: 600;
	line-height: 28px;
	letter-spacing: 0px;
	text-align: center;
	text-transform: uppercase;
	margin: 0;
	padding-bottom: 6px;
`;
const AddEventSection = styled.div`
	position: relative;
	width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`;
const EventOption = styled.div`
	padding: 11px 0px 10px 16px;
	font-size: 16px;
	font-style: normal;
	font-weight: 300;
	line-height: 19px;
	letter-spacing: 0px;
	cursor: pointer;
	font-size: 16px;
`;

const EventsContainer = styled.div`
	background: #ffffff;
	box-sizing: border-box;
	box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 0px 1px #919191 inset;
	width: 200px;
	position: absolute;
	top: 92px;
	z-index: 2;
	padding: 8px;
	display: flex;
	flex-direction: column;
	gap: 8px;
`;
const StyledBodyText = styled(BodyText)`
	max-width: 360px;
`;
const StyledTextLink = styled(TextLink)`
	margin-top: 16px;
	margin-bottom: 120px;
`;

const AddEvent = styled.button`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 600px;
	height: 92px;
	background: #fff5f5;
	border-radius: 16px;
	border: none;
	padding: 32px 80px;
	margin: 32px 0px;
	font-size: 24px;
	font-style: normal;
	font-weight: 600;
	line-height: 28px;
	letter-spacing: 0px;
	text-align: center;
	color: #e81111;
	text-transform: uppercase;
	position: relative;
	@media (max-width: 582px) {
		padding: 24px 20px;
		margin: 32px 20px;
		width: 280px;
		font-size: 21px;
		line-height: 24px;
	}
`;
const DownArrow = styled.img`
	padding-left: 8px;

	@media (max-width: 582px) {
		position: absolute;
		left: 208px;
	}
`;
