import React from "react";
import styled from "styled-components";
import Heading from "../../Heading";
import BodyText from "../../BodyText";

interface Props {
	language: string;
}

export const DateChangeSubmitPage = ({
	language
}: Props) => {
	return (
		<StyledOverlay>
			<StyledContent>
				<StyledHeading>{language === 'en' ? "We'll Be in Touch!" : "Nous communiquerons bientôt avec vous!"}</StyledHeading>
				<StyledBodyText>{language === 'en' ? "Your Book Fair Consultant will reach out soon to address your questions. In the meantime, start planning the Fair with free resources to get your students excited about reading." : "Votre conseiller·ère du Festival du Livre vous contactera sous peu. Entre-temps, commencez à planifier votre Festival avec des ressources gratuites qui éveilleront l'intérêt de vos élèves pour la lecture." }</StyledBodyText>
			</StyledContent>
		</StyledOverlay>
	)
};

const StyledOverlay = styled.div`
	position: absolute;
    z-index: 100;
    top: 132px;
    left: 0;
    bottom: 0;
    background-color: white;
    width: 100%;
    margin: 0 auto;
    @media (max-width: 719px){
    	top: 145px;
    }
`

const StyledContent = styled.div`
	max-width: 920px;
	margin: 0 auto;
	padding-top: 58px;
	@media (max-width: 1279px) {
		padding: 0 40px;
		padding-top: 48px;
	}
	@media (max-width: 719px) {
		padding: 0 20px;
		padding-top: 32px;
	}
`;

const StyledHeading = styled(Heading)`
	text-align: left;
	font-weight: 400;
	margin-bottom: 24px;
`

const StyledBodyText = styled(BodyText)`
	text-align: left;
	font-size: 17px;
	line-height: 25px;
	font-weight: 300;
	margin-bottom: 32px;
`