import React, { useState } from "react";
import styled from "styled-components";
import BodyText from "../../BodyText";
import EditButton from "../../../images/Edit.svg";
import DeleteButton from "../../../images/Delete.svg";
import DeleteModal from "./DeleteModal";
import { convertToStandardTime } from "./CleanTime";
import { convertToMilitaryTime }  from "./CleanTime";

interface Props {
	eventDate: string;
	startTime: string;
	endTime: string;
	title: string;
	description?: string;
	eventType?: string;
	editEvent(x: any): any;
	index: number;
	category: string;
	createDate: Date;
	deleteEvent: (index: number) => void;
	language: string;
}

export const ScheduledEvent = ({
	eventDate,
	startTime,
	endTime,
	title,
	description,
	editEvent,
	index,
	deleteEvent,
	language
}: Props) => {
	const [clickedDelete, setClickedDelete] = useState<boolean>(false);
	let dateArray: any[] = [];
	const startTimeClean = language === "en" &&  (!startTime.includes("AM") ||  !startTime.includes("PM")) ? convertToStandardTime(startTime) : convertToMilitaryTime(startTime);
	const endTimeClean = language === "en" && (!endTime.includes("AM") ||  !endTime.includes("PM")) ? convertToStandardTime(endTime) : convertToMilitaryTime(endTime);
	if (eventDate) {
		dateArray = eventDate.split("-");
	}
	const cleanedDateEnglish = `${parseInt(dateArray[1])}/${parseInt(dateArray[2])}/${dateArray[0]}`;
	const cleanedDateFrench = `${dateArray[0]}-${dateArray[1]}-${parseInt(dateArray[2])}`;

	// Opens delete modal
	const handleDeleteClick = () => {
		setClickedDelete(true);
	};

	// Removes event from UI
	const confirmDelete = () => {
		deleteEvent(index);
		setClickedDelete(false);
	};

	return (
		<ScheduledEventContainer>
			{clickedDelete ? (
				<DeleteModal
					clickCancel={setClickedDelete}
					clickDelete={confirmDelete}
				/>
			) : (
				<>
					<EventDetailsLeft>
						<StyledBodyText>{language === "en" ? cleanedDateEnglish : cleanedDateFrench}</StyledBodyText>
						<StyledBodyText>
							{startTimeClean} - {endTimeClean}
						</StyledBodyText>
					</EventDetailsLeft>
					<EventDetailsRight>
						<StyledBodyTextTitle>{title}</StyledBodyTextTitle>
						{description && (
							<StyledBodyTextDesc>{description}</StyledBodyTextDesc>
						)}
					</EventDetailsRight>
					<EventButtons>
						<Edit
							onClick={() => {
								editEvent(index);
							}}
						>
							<img
								alt={`Edit ${title} event`}
								src={EditButton}
							></img>
						</Edit>
						<Delete onClick={handleDeleteClick}>
							<img
								alt={`Remove ${title} from schedule`}
								src={DeleteButton}
							></img>
						</Delete>
					</EventButtons>
				</>
			)}
		</ScheduledEventContainer>
	);
};
const EventButtons = styled.div`
	position: absolute;
	width: 64px;
	left: calc(100% - 104px);
	top: calc(50% - 12px);

	@media (max-width: 620px) {
		top: 20px;
	}
`;
const Edit = styled.button`
	padding: 0;
	margin-right: 16px;
	cursor: pointer;
	border: none;
	background-color: transparent;
`;
const Delete = styled.button`
	padding: 0;
	cursor: pointer;
	border: none;
	background-color: transparent;
`;
const StyledBodyTextDesc = styled(BodyText)`
	font-size: 13px;
	text-align: left;
	line-height: 16px;
	padding-top: 4px;
`;
const StyledBodyText = styled(BodyText)`
	font-size: 13px;
	text-align: left;
	line-height: 16px;
`;
const StyledBodyTextTitle = styled(BodyText)`
	ont-size: 13px;
	text-align: left;
	font-weight: bold;
	line-height: 15px;
`;
const EventDetailsLeft = styled.div`
	margin-right: 24px;
	width: 132px;
`;
const EventDetailsRight = styled.div`
	margin-right: 80px;
	min-width: 240px;
	max-width: 260px;

	@media (max-width: 620px) {
		min-width: unset;
		max-width: unset;
		width: 100%;
	}
`;
const ScheduledEventContainer = styled.div`
	width: 100%;
	max-width: 600px;
	padding: 24px 40px;
	position: relative;
	border: 1px solid #949494;
	box-sizing: border-box;
	border-radius: 16px;
	margin-top: 24px;
	display: flex;
	flex-wrap: wrap;
	
	@media (max-width: 620px) {
		flex-direction: column;
		row-gap: 16px;
		padding: 20px 30px;
	}
`;
