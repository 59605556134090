import React, { useRef } from "react";
import styled from "styled-components";
import Close from "../images/Close.svg";

interface Props {
	closeModal: any;
	children: React.ReactNode;
	isOverflow?: boolean;
}

export const ModalContainer = ({ children, closeModal, isOverflow }: Props) => {
	const modalRef = useRef<HTMLDivElement>(null);
	return (
		<Wrapper>
			<Overlay />
			<Container className={isOverflow ? 'overflow-enabled' : ''}>
				<StyledModal ref={modalRef} className={isOverflow ? 'overflow-enabled' : ''}>
					<CloseButton onClick={closeModal}>
						<CloseImage src={Close} alt="Close Modal" />
					</CloseButton>
					<StyledChildren>{children}</StyledChildren>
				</StyledModal>
			</Container>
		</Wrapper>
	);
};

const Container = styled.div`
	height: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	&.overflow-enabled {
		margin: 80px 0;
		height: calc(100% - 160px);
	}
	@media (max-width: 1279px) {
		&.overflow-enabled {
			margin: 120px 0;
			height: calc(100% - 240px);
		}
	}
	@media (max-width: 719px) {
		width: 100vw;
		&.overflow-enabled {
			margin: auto;
			height: auto;
		}
	}
`;

const CloseButton = styled.button`
	border: none;
	z-index: 1;
	background-color: transparent;
	cursor: pointer;
	position: absolute;
	top: 27px;
	right: 27px;
`;

const CloseImage = styled.img`
	width: 18px;
	height: 18px;
`;

const StyledModal = styled.div`
	z-index: 150;
	display: grid;
	background-color: white;
	position: relative;
	grid-column-start: 4;
	grid-column-end: 21;
	top: 0px;
	max-height: 100%;
	box-sizing: border-box;
	overflow: visible;
	&.overflow-enabled {
		overflow-x: hidden;
		overflow-y: auto;
		&::-webkit-scrollbar {
			width: 16px;
		}
		&::-webkit-scrollbar-thumb {
			border: 4px solid transparent;
			background-clip: padding-box;
			-webkit-border-radius: 10px;
			background-color: #666666;
		}
	}
	@media (max-width: 719px) {
		height: 100vh;
	}
`;

const Overlay = styled.div`
	z-index: 140;
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	overflow: auto;
	background-color: rgba(0, 0, 0, 0.7);
	@media (max-width: 719px) {
		background-color: white;
	}
`;

const Wrapper = styled.div`
	z-index: 140;
	width: 100vw;
	height: 100vh;
	position: fixed;
	top: 0;
	left: 0;
	@media (max-width: 719px) {
		height: 100vh;
		top: 0;
	}
`;

const StyledChildren = styled.div`
	overflow-x: visible;
`;
