import { getFairIdFromUrl } from "./HubUtility";
import Cookies from "js-cookie";

export const handleCOASuccess = (msg: any) => {
  const currentFair = getFairIdFromUrl();
  if (
    msg.status === 204 &&
    msg.headers["sbf-jarvis-reason"] === "NEEDS_COA_CONFIRMATION"
  ) {
    if (currentFair !== "current") {
      window.open(`/content/fairs/hub/coa.html?fairID=${currentFair}`, "_top");
    } else {
      window.open(`/content/fairs/hub/coa.html`, "_top");
    }
    return true;
  }
  return false;
};

export const handleCOAErrors = (err: any) => {
  sessionStorage.setItem("errorCode", err.response?.status);
  sessionStorage.setItem(
    "bfSelectedFairId",
    err.response?.headers["sbf-jarvis-resource-id"],
  );
  if (err.response?.status === 404) {
    window.open(`/content/fairs/hub/db-error.html`, "_top");
    console.error(err);
    return true;
  }
  return false;
};

export const handleGenericSuccess = (msg: any) => {
  if (msg.status === 204 && Cookies.get('userEmail') === undefined) {
    // User is not logged in
    window.sessionStorage.removeItem('bf_profile');
    window.open("/login", "_top");
    return true;
  }
  return false;
};

export const handleGenericErrors = (err: any) => {
  if (err.response?.status === 401 || err.response?.status === 403) {
    // User is trying to access a fair that they do not have on their account or this fair has expired.
    localStorage.setItem("errorCode", err.response?.status);
    localStorage.setItem("prevPage", window.location.href);
    window.open(`/error`, "_top");
    return true;
  } else {
    //Something went wrong.
    localStorage.setItem("errorCode", err.response?.status);
    localStorage.setItem("prevPage", window.location.href);
    console.log(err);
    window.open("/error", "_top");
  }
};
