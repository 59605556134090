import { useConfig } from "../../providers/ConfigContext";
import styled from "styled-components";
import ContactIcon from "../../images/Contact.svg";
import ActivitiesIcon from "../../images/Activities.svg";
import InfoNotepadIcon from "../../images/InfoNotepad.svg";
import PencilIcon from "../../images/InfoPencil.svg";
import ShareIcon from "../../images/ViewAndShare.svg";
import GoalTargetIcon from "../../images/GoalTarget.svg";
import ArrowIcon from "../../images/GoalArrow.svg";
import VolunteersIcon from "../../images/Volunteers.svg";
import PageIcon from "./PageIcon";
import IconCaption from "../IconCaption";
import { trackHomepageBuilderIconClick } from "../../DumbleData";

interface Props {
	afterFair?: boolean;
	language: string
}

export const IconList = ({ afterFair, language }: Props) => {
	const { damAssetsOrigin } = useConfig();
	return (
		<>
			<StyledIconList>
				<IconComponent onClick={() => trackHomepageBuilderIconClick("contact")}>
					<StyledLink href="/customize/contact">
						<PageIcon
							src={`${damAssetsOrigin}canadatoolkit/homepagebuilder/Contact.svg`}
							alt="Contact Form Icon"
							defaultImg={ContactIcon}
						/>
						<IconCaption>{language === 'en' ? 'Your Contact Information' : 'Vos coordonnées'}</IconCaption>
					</StyledLink>
				</IconComponent>
				<IconComponent onClick={() => trackHomepageBuilderIconClick("goals")}>
					<StyledLink href="/customize/goals">
						<StyledArrowIcon
							src={`${damAssetsOrigin}canadatoolkit/homepagebuilder/GoalArrow.svg`}
							defaultImg={ArrowIcon}
						/>
						<PageIcon
							src={`${damAssetsOrigin}canadatoolkit/homepagebuilder/GoalTarget.svg`}
							alt="Goal Icon"
							defaultImg={GoalTargetIcon}
						/>
						<IconCaption>{language === 'en' ? 'Set or Update Your Fair Goal' : "Fixer ou modifier l'objectif de votre Festival"}</IconCaption>
					</StyledLink>
				</IconComponent>
				<IconComponent onClick={() => trackHomepageBuilderIconClick("information")}>
					<StyledLink href="/customize/information">
						<StyledPencilIcon
							src={`${damAssetsOrigin}canadatoolkit/homepagebuilder/InfoPencil.svg`}
							defaultImg={PencilIcon}
						/>
						<PageIcon
							src={`${damAssetsOrigin}canadatoolkit/homepagebuilder/InfoNotepad.svg`}
							alt="Fair Information Icon"
							defaultImg={InfoNotepadIcon}
						/>
						<IconCaption>{language === 'en' ? 'Fair Information' : 'Informations sur le Festival'}</IconCaption>
					</StyledLink>
				</IconComponent>
				{!afterFair && (
					<>
						<IconComponent onClick={() => trackHomepageBuilderIconClick("schedule")}>
							<StyledLink href="/customize/schedule">
								<PageIcon
									src={`${damAssetsOrigin}canadatoolkit/homepagebuilder/Activities.svg`}
									alt="Activities Icon"
									defaultImg={ActivitiesIcon}
								/>
								<IconCaption>{language === 'en' ? 'Schedule Activities' : 'Planifier des activités'}</IconCaption>
							</StyledLink>
						</IconComponent>
						<IconComponent onClick={() => trackHomepageBuilderIconClick("volunteer")}>
							<StyledLink href="/customize/volunteer">
								<PageIcon
									src={`${damAssetsOrigin}canadatoolkit/homepagebuilder/Volunteers.svg`}
									alt="Volunteers Icon"
									defaultImg={VolunteersIcon}
								/>
								<IconCaption>{language === 'en' ? 'Recruit Volunteers' : 'Recruter des bénévoles'}</IconCaption>
							</StyledLink>
						</IconComponent>
					</>
				)}
				<IconComponent onClick={() => trackHomepageBuilderIconClick("share")}>
					<StyledLink href="/customize/share">
						<PageIcon
							src={`${damAssetsOrigin}canadatoolkit/homepagebuilder/ViewAndShare.svg`}
							alt="View and Share Icon"
							defaultImg={ShareIcon}
						/>
						<IconCaption>{language === 'en' ? "Share Your Homepage" : "Partager votre page d'accueil"}</IconCaption>
					</StyledLink>
				</IconComponent>
			</StyledIconList>
		</>
	);
};

const StyledArrowIcon = styled.img<{ defaultImg: string }>`
	position: absolute;
	padding-bottom: 117px;
	padding-left: 70px;
	background-image: ${(props) => `url(${props.defaultImg})`};
	background-repeat: no-repeat;
	background-position-x: 70px;
`;
const StyledPencilIcon = styled.img<{ defaultImg: string }>`
	position: absolute;
	padding-bottom: 112px;
	padding-left: 70px;
	background-image: ${(props) => `url(${props.defaultImg})`};
	background-repeat: no-repeat;
	background-position-x: 70px;
`;
const StyledLink = styled.a`
	text-decoration: none;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 17px;
`;
const StyledIconList = styled.div`
	display: flex;
	flex-direction: row;
	padding-top: 42px;
	padding-bottom: 80px;
	grid-column: 1/24;
	flex-wrap: wrap;
	gap: 40px;
	position: relative;
	justify-content: center;
	@media (max-width: 959px) {
		margin: 0 100px;
	}
	@media (max-width: 719px) {
		max-width: 614px;
		margin: 0;
		align-content: flex-start;
		column-gap: 27px;
		row-gap: 29px;
	}
`;
const IconComponent = styled.div``;
