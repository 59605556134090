import { RedButton } from "../../Button";
import React from "react";
import styled from "styled-components";

interface Props {
	clickCancel: (value: boolean) => void;
	clickDelete: () => void;
}

export default function DeleteModal({ clickCancel, clickDelete }: Props) {
	// Closes delete modal
	const cancelDelete = () => {
		clickCancel(false);
	};

	return (
		<StyledDeleteModal>
			<StyledDeleteMessage>
				Are you sure you want to delete?
			</StyledDeleteMessage>
			<StyledCancelButton onClick={cancelDelete}>
				Cancel
			</StyledCancelButton>
			<RedButton handleClick={clickDelete}>Delete</RedButton>
		</StyledDeleteModal>
	);
}

const StyledDeleteModal = styled.div`
	display: flex;
	height: 100%;
	align-items: center;
	justify-content: center;
	@media (max-width: 582px) {
		flex-wrap: wrap;
		align-content: center;
	}
`;

const StyledDeleteMessage = styled.p`
	margin-right: 89px;
	font-family: "museo-sans";
	color: #333333;
	@media (max-width: 582px) {
		width: 100%;
		margin-right: 0;
		margin-bottom: 28px;
		text-align: center;
	}
`;

const StyledCancelButton = styled.button`
	border: none;
	background-color: transparent;
	cursor: pointer;
	color: #e81111;
	margin-right: 28px;
	text-transform: uppercase;
	letter-spacing: 2px;
	font-family: "museo-sans";
	font-size: 11px;
	font-weight: 600;
	&:hover {
		color: #b90000;
		text-decoration: underline;
		text-underline-offset: 3px;
	}
	&:focus {
		outline: 2px solid #b90000;
		border-radius: 2px;
		color: #b90000;
	}
	&:active {
		color: #660000;
		outline: none;
	}
`;
