import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import axios from "axios";
import { convertYMDStringToDate, convertYMDToMDY } from "../../utils/DateConversion";
import { RedButton, WhiteButton } from "../Button";
import { handleGenericErrors, handleGenericSuccess } from "../../utils/ErrorUtility";
import { trackFairConfirm, trackFairSelect, updateFairAccountID, updateFairTotalPastFairs, updateFairTotalScheduledFairs, updateFairTotalUnconfirmedFairs, updatePageType, updateUserLoginStatus } from "../../DumbleData";

interface Props {
	isModal?: boolean;
	language: string;
}

export const FairSelectorContent = ({ isModal, language }: Props) => {
	const [fairData, setFairData] = useState<any[]>([]);

	const handleSetFairId = useCallback(
		async (fairId: string) => {
			try {
				await axios.get(
					`${process.env.REACT_APP_ENV_BASE_URL}/api/user/fairs/${fairId}?mode=SELECT`,
					{ withCredentials: true, }
				);
			} catch (e: any) {
				handleGenericErrors(e);
			}
		},
		[]
	);

	// get request that populates fair selector
	useEffect(() => {
		const fetchFairs = async () => {
			try {
				const response = await axios.get(
					`${process.env.REACT_APP_ENV_BASE_URL}/api/user/fairs`,
					{ withCredentials: true }
				);
				if (response.status === 200) {
					setFairData(response.data.schools);
					updateFairAccountID(response.data.schools.bookfairAccountId);
					updateUserLoginStatus(true);
					updatePageType("setup");

					let scheduledFairCount = 0;
					let pastFairCount = 0;
					let unconfirmedFairCount = 0;

					for (var school of response.data.schools) {
						scheduledFairCount += school.scheduledFairInfoList.length;
						pastFairCount += school.pastFairInfoList.length;
						unconfirmedFairCount += Object.values(school.scheduledFairInfoList).reduce((a: number, fair: any) => a + (fair.coaAccepted === false ? 1 : 0), 0) + 
						Object.values(school.pastFairInfoList).reduce((a: number, fair: any) => a + (fair.coaAccepted === false ? 1 : 0), 0);
					}

					updateFairTotalScheduledFairs(scheduledFairCount);
					updateFairTotalPastFairs(pastFairCount);
					updateFairTotalUnconfirmedFairs(unconfirmedFairCount);

				} else {
					handleGenericSuccess(response);
				}
			} catch (e: any) {
				handleGenericErrors(e);
			}
		};
		fetchFairs();

	}, []);

	async function handleClickConfirmFair(fairId: string) {
		trackFairConfirm();
		await handleSetFairId(fairId);
		window.open("/confirm-fair", "_top");
	}

	async function handleClickSelectFair(fairId: string) {
		trackFairSelect();
		await handleSetFairId(fairId);
		window.open("/fair-details", "_top");
	}

	// Prints a list of fairs
	const renderFairList = (fairList: any) => {
		return (
			<div>
				{fairList.map((fair: any) => {
					return (
						<StyledFairContainer key={fair.fairId}>
							<div>
								<StyledFairHeading>
									{convertYMDToMDY(fair.startDate)} -{" "}
									{convertYMDToMDY(fair.endDate)}
								</StyledFairHeading>
								<StyledFairInfo>
									{language === "en" ? `Fair ID: ${fair.fairId}` : `Numéro du Festival : ${fair.fairId}`}<br />
									{fair.language === "French" ? "Festival du Livre" : ""}
									{fair.language === "English" && language === "en" ? "English Book Fair" : ""}
									{fair.language === "English" && language === "fr" ? "Book Fair en anglais" : ""}
								</StyledFairInfo>
							</div>
							{fair.coaAccepted ? (
								<StyledRedButton
									handleClick={() =>
										handleClickSelectFair(fair.fairId)
									}
								>
									{language === "en" ? "Select Fair" : "Sélectionner le Festival"}
								</StyledRedButton>
							) : (
								<StyledWhiteButton
									whiteBackground={true}
									handleClick={() =>
										handleClickConfirmFair(fair.fairId)
									}
								>
									{language === "en" ? "Confirm Fair" : "Confirmer le Festival"}
								</StyledWhiteButton>
							)}
						</StyledFairContainer>
					);
				})}
			</div>
		);
	};

	// Separates fairs into scheduled and past fairs and prints them
	const renderFairs = (school: any) => {
		const todaysDate = new Date();
		todaysDate.setHours(0, 0, 0, 0);
		// Includes upcoming or current fairs
		const scheduledFairs = school.scheduledFairInfoList.filter((fair: any) => {
			const fairEndDate = convertYMDStringToDate(fair.endDate);
			return fairEndDate >= todaysDate;
		});
		// Includes fairs that have ended
		const pastFairs = school.pastFairInfoList.filter((fair: any) => {
			const fairEndDate = convertYMDStringToDate(fair.endDate);
			return fairEndDate < todaysDate;
		});
		return (
			<>
				{/* Only show if there are upcoming/current fairs */}
				{scheduledFairs.length > 0 && (
					<>
						<StyledScheduledFairs>
							{language === "en" ? "Scheduled Fairs" : "Festivals programmés"}
						</StyledScheduledFairs>
						{renderFairList(scheduledFairs)}
					</>
				)}
				{/* Only show if there are ended fairs */}
				{pastFairs.length > 0 && (
					<>
						<StyledScheduledFairs>
							{language === "en" ? "Past Fairs" : "Festivals passés"}
						</StyledScheduledFairs>
						{renderFairList(pastFairs)}
					</>
				)}
			</>
		);
	};

	return (
		<>
			{/* do not render page until multiple fairs are counted */}
			{fairData.length > 0 && (
				<Container isModal={isModal}>
					<StyledHeading>
						{language === "en" ? "Please Select a Fair" : "Sélectionner un Festival"}
					</StyledHeading>
					<StyledSchoolList isModal={isModal}>
						{fairData.map((school) => {
							return (
								<StyledSchoolContainer key={school.bookfairAccountId}>
									<StyledSchoolHeadingContainer>
										<StyledSchoolName>
											{school.name}
										</StyledSchoolName>
										<StyledAccountNumber>
											{language === "en" ? `Account: ${school.bookfairAccountId}` : `Compte : ${school.bookfairAccountId}`}
										</StyledAccountNumber>
									</StyledSchoolHeadingContainer>
									{renderFairs(school)}
								</StyledSchoolContainer>
							);
						})}
					</StyledSchoolList>
				</Container>
			)}
		</>
	);
};

const StyledRedButton = styled(RedButton)`
	@media (max-width: 719px) {
		width: 100%;
	}
`;

const StyledWhiteButton = styled(WhiteButton)`
	@media (max-width: 719px) {
		width: 100%;
	}
`;

interface ContainerProps {
	isModal?: boolean;
}
const Container = styled.div<ContainerProps>`
	display: flex;
	align-items: center;
	flex-direction: column;
	width: ${(props) => props.isModal ? '840px' : 'auto'};
	padding: 72px 40px 24px 40px;
	margin: 0 auto;
	@media (max-width: 1279px) {
		width: ${(props) => props.isModal ? '545px' : 'auto'};
		padding: 72px 24px 24px 24px;
	}
	@media (max-width: 719px) {
		box-sizing: border-box;
		width: calc(100vw - 40px);
		padding: 64px 20px 20px 20px;
	}
`;

const StyledHeading = styled.h1`
	margin: 0;
	font-size: 28px;
	line-height: 32px;
	font-weight: 500;
	color: #002d70;
`;

interface SchoolListProps {
	isModal?: boolean;
}
const StyledSchoolList = styled.div<SchoolListProps>`
	width: 100%;
	padding-top: 48px;
	padding-bottom: ${(props) => (props.isModal ? "30px" : "0px")};
`;

const StyledSchoolContainer = styled.div`
	&:not(:first-child) {
		margin-top: 48px;
	}
`;

const StyledSchoolHeadingContainer = styled.div`
	display: flex;
	flex-direction: column;
	@media (max-width: 719px) {
		flex-direction: column;
		align-items: flex-start;
	}
`;

const StyledSchoolName = styled.h2`
	margin: 0;
	font-weight: 500;
	font-size: 24px;
	line-height: 28px;
	color: #002d70;
`;

const StyledAccountNumber = styled.p`
	font-weight: 300;
	font-size: 14px;
	line-height: 18px;
	color: #666666;
	@media (max-width: 719px) {
		margin-top: 4px;
	}
`;

const StyledScheduledFairs = styled.p`
	font-weight: 500;
	font-size: 21px;
	line-height: 24px;
	padding-top: 44px;
	padding-bottom: 12px;
	border-bottom: 1px solid #b5b5b5;
	color: #b90000;
`;

const StyledFairContainer = styled.div`
	border-top: 1px solid #d7d7d7;
	padding-top: 16px;
	padding-bottom: 16px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	@media (max-width: 719px) {
		flex-direction: column;
		align-items: flex-start;
		row-gap: 16px;
	}
`;

const StyledFairHeading = styled.h3`
	margin: 0;
	font-weight: 500;
	font-size: 18px;
	line-height: 21px;
	color: #002d70;
`;

const StyledFairInfo = styled.p`
	font-weight: 300;
	font-size: 14px;
	line-height: 18px;
	margin-top: 8px;
	color: #666666;
`;