import Navigation from "../Navigation/Navigation";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { Outlet } from "react-router-dom";
import { handleGenericErrors, handleGenericSuccess } from "../../../utils/ErrorUtility";
import { convertDateToString } from "../../../utils/DateConversion";

interface Props {
	language: string;
	builderNavDisabled: boolean;
	builderNextLocation: string;
	setBuilderNextLocation: any;
}

export const Builder = ({
	language,
	builderNavDisabled,
	setBuilderNextLocation,
}: Props) => {
	const [after, setAfter] = useState<boolean>(false);

	useEffect(() => {
		const getFairSettingsPageData = async () => {
				try {
					await axios
						.get(
							`${process.env.REACT_APP_ENV_BASE_URL}/api/user/fairs/current/settings`,
							{
								withCredentials: true,
							}
						)
						.then((response) => {
							if (response.status === 200) {
								const { fairInfo } = response.data;
								if ( fairInfo ) {
									if ( convertDateToString(new Date()) > fairInfo.end ) {
										setAfter(true);
									}
								}
							} 
							else {
								handleGenericSuccess(response);
							}
						})
						.catch((err) => {
							handleGenericErrors(err);
						});
				} catch (err: any) {
					handleGenericErrors(err);
				}
		};
		getFairSettingsPageData();

	}, [
		setAfter
	]);

	return (
		<React.Fragment>
			<Navigation
				language={language}
				handleNextLocation={setBuilderNextLocation}
				isDisabled={builderNavDisabled}
				isVirtual={false}
				fairType={after ? "after" : ""}
			/>
			<Outlet context={{after}} />
		</React.Fragment>
	);
};