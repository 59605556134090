import React, { useState } from "react";
import styled from "styled-components";
import Heading from "../Heading";
import BodyText from "../BodyText";
import { WhiteButton } from "../Button";
import ContactChairpersonForm from "./ContactChairpersonForm";
import { disableScrolling, enableScrolling } from "../../utils/ToggleScrolling";
import { trackContactChairpersonButton } from "../../DumbleData";

interface Props {
	afterFair: boolean;
	contactData: {
		firstName: string;
		lastName: string;
		displayName: boolean;
		displayPhoneNumber: boolean;
		phoneNumber: string;
		email: string;
	};
	fairInfoData: {
		cash: boolean;
		checks: boolean;
		creditCard: boolean;
		payableTo: string;
		schoolName: string;
		startDate: Date;
		endDate: Date;
	};
	fairId: number;
	language: string;
}

const ChairpersonContactComponent = ({
	contactData,
	fairInfoData,
	afterFair,
	fairId,
	language,
}: Props) => {
	const [contactFormOpen, setContactFormOpen] = useState(false);

	const handleModal = () => {
		if (contactFormOpen) {
			enableScrolling();
		} else {
			disableScrolling();
		}
		setContactFormOpen(!contactFormOpen);
		trackContactChairpersonButton();
	};

	// Create a list of payment methods
	const paymentMethods: string[] = [];
	if (fairInfoData.cash) {
		paymentMethods.push(language === "en" ? "cash" : "en espèces");
	}
	if (fairInfoData.creditCard) {
		paymentMethods.push(language === "en" ? "debit & credit cards, Mydoh" : "par carte de débit ou de crédit, par Mydoh");
	}
	if (fairInfoData.checks) {
		paymentMethods.push(language === "en" ? `cheques (payable to the school)` : `et par chèque (à l’ordre de l’école)`);
	}

	// Build a string based on number of payment methods
	const generatePaymentText = () => {
		let paymentText = language === "en" ? "We accept " : "Nous acceptons les paiements ";
		for (let i = 0; i < paymentMethods.length; i++) {
			paymentText += paymentMethods[i];
			if (i < paymentMethods.length - 1) {
				paymentText += ", ";
			}
		}
		paymentText += language === "en" ? " as payment methods at the Fair." : ".";
		return paymentText;
	};

	return (
		<ChairpersonContact>
			<SectionContainer>
				<FlexRow>
					{contactData.displayName && (
						<InlineElement>
							<StyledHeading>{`${contactData.firstName} ${contactData.lastName}`}</StyledHeading>
						</InlineElement>
					)}
					<StyledWhiteButton handleClick={handleModal}>
						{language === "en"
							? "Contact Host"
							: "Contacter le/la responsable"}
					</StyledWhiteButton>
				</FlexRow>
				{contactData.displayPhoneNumber && (
					<FlexRow>
						<PhoneNumber>{contactData.phoneNumber}</PhoneNumber>
					</FlexRow>
				)}
				{!afterFair && paymentMethods.length > 0 && (
					<FlexRow>
						<StyledBodyText>{generatePaymentText()}</StyledBodyText>
					</FlexRow>
				)}
			</SectionContainer>
			{contactFormOpen && (
				<ContactChairpersonForm
					language={language}
					contactData={contactData}
					fairInfoData={fairInfoData}
					fairId={fairId}
					handleModal={handleModal}
				/>
			)}
		</ChairpersonContact>
	);
};

const StyledBodyText = styled(BodyText)`
	color: white;
	align-items: center;
	width: 485.7px;
	font-size: 16px;
	line-height: 19px;
	letter-spacing: 0px;
	@media (max-width: 550px) {
		width: 302.29px;
	}
`;

const PhoneNumber = styled(StyledBodyText)`
	font-size: 16px;
	font-weight: 400;
	line-height: 19px;
	letter-spacing: 0px;
`;

const SectionContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	max-width: 492.3px;
	width: 100%;
	gap: 16px;
`;

const FlexRow = styled.div`
	display: inline-flex;
	align-items: center;
	gap: 32px;
	@media (max-width: 719px) {
		flex-direction: column;
		align-items: center;
		width: 100%;
	}
`;

const StyledWhiteButton = styled(WhiteButton)`
	width: 201px;
	@media (max-width: 719px) {
		width: 100%;
	}
`;

const InlineElement = styled.div`
	flex: 1 auto;
`;

const ChairpersonContact = styled.div`
	padding: 48px 40px;
	position: relative;
	background: #681032;
	display: flex;
	flex-direction: column;
	align-items: center;
`;

const StyledHeading = styled(Heading)`
	margin: 0;
	font-size: 21px;
	font-weight: 500;
	line-height: 24px;
	letter-spacing: 0px;
	text-align: right;
	color: white;
`;

export default ChairpersonContactComponent;
