import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import BlackDropdownArrow from "../images/BlackDropdownArrow.svg";
import BodyText from "./BodyText";

interface Props {
	className?: any;
	onChange: (value: string) => void;
	label?: string;
	options: any;
	disabled?: boolean;
	error?: string;
	isOpen?: boolean;
	optionChosen: string | null;
	defaultText: string;
	defaultValue?: string;
	tabIndex?: number;
}

const SelectInput = ({
	className,
	onChange,
	label,
	options,
	disabled,
	error,
	optionChosen,
	defaultText,
	defaultValue,
	tabIndex,
}: Props) => {
	const [isOpen, setIsOpen] = useState(false);
	const [focusedOption, setFocusedOption] = useState(-1);

	let arrayOfOptionsRefs: any[] = [];
	//arrayOfId holds the ids of all elements in drop down to help with closing the drop down on unfocus
	const arrayOfId: string[] = useMemo(() => {
		let temp = [];
		for (let i = 0; i < options.length; i++) {
			temp.push(`dropDown${i}`);
		}
		return temp;
	}, [options.length]);

	const toggling = () => {
		if (!disabled) {
			setIsOpen(!isOpen);
		}
	};

	const setOptionRef = (element: null) => {
		if (element !== null) {
			arrayOfOptionsRefs.push(element);
		}
	};

	//calculates index of the desired default value
	const defaultIndex = -1;
	//calculates index in list of the chosen option
	const optionChosenIndex = optionChosen ? options.indexOf(optionChosen) : 0;
	//calculates bound of options list
	const optionBoundIndex = options.length;

	useEffect(() => {
		const handleClickOutside = (event: any) => {
			if (!(event.target.id.includes("dropDown")) && !(event.target.parentElement.id.includes("dropDown")) ) {
				setIsOpen(false);
			}
		};

		document.addEventListener("mousedown", handleClickOutside);
		
		return () => {
				document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [
		isOpen,
		arrayOfId,
		defaultIndex,
		optionChosenIndex,
		optionBoundIndex,
		optionChosen,
	]);

	const handleOptionsEvents = (indexString: any, event: any) => {
		const index = parseInt(indexString);
		if (event.code === "Tab") {
			setIsOpen(false);
		}

		switch (event.type) {
			case "keydown":
				if (event.key === "Enter" || event.key === " ") {
					onChange(options[focusedOption]);
					setIsOpen(!isOpen);
					arrayOfOptionsRefs[index].focus();
				}
				if (event.key === "ArrowUp") {
					event.preventDefault();
					if (index - 1 >= 0) {
						arrayOfOptionsRefs[index - 1].focus();
						setFocusedOption(index - 1);
					} else {
						setIsOpen(false);
					}
				}
				if (event.key === "ArrowDown") {
					event.preventDefault();

					if (index + 1 < optionBoundIndex) {
						arrayOfOptionsRefs[index + 1].focus();
						setFocusedOption(index + 1);
					} else {
						break;
					}
				}
				if (event.key === "Escape") {
					setIsOpen(!isOpen);
					arrayOfOptionsRefs[index].focus();
				}
				break;
			default:
		}
	};

	return (
		<React.Fragment>
			<StyledLabel className={disabled ? "disabled" : ""}>
				{label}
			</StyledLabel>
			<StyledContainer className={className}>
				<DropDownHeader
					tabIndex={tabIndex || 0}
					onClick={toggling}
					onKeyPress={toggling}
					className={`${className}
					dropDownElements ${disabled ? "disabled" : ""}`}
					error={error}
					isOpen={isOpen}
				>
					<StyledSpan className={!optionChosen ? "notSelected" : ""}>
						{optionChosen || defaultText}
					</StyledSpan>
					<StyledImg
						isOpen={isOpen}
						src={BlackDropdownArrow}
						className={className}
					/>
				</DropDownHeader>
				{isOpen && (
					<DropDownListContainer
						className={"dropDownElements"}
						id={"dropDownElementsContainer"}
					>
						<DropDownList>
							{options.map((option: string, index: number) => (
								<ListItem
									tabIndex={option === options[0] ? 0 : -1}
									onClick={(e: any) => {
										onChange(option);
										setIsOpen(false);
									}}
									onKeyDown={(e) => {
										handleOptionsEvents(index, e);
									}}
									key={index}
									className={"dropDownElements"}
									id={`dropDown${index}`}
									role="option"
									ref={setOptionRef}
									aria-posinset={index}
								>
									{option}
								</ListItem>
							))}
						</DropDownList>
					</DropDownListContainer>
				)}
				{error && <ErrorMessage>{error}</ErrorMessage>}
			</StyledContainer>
		</React.Fragment>
	);
};
interface ListProps {
	isFocusedOption?: boolean;
}
const ErrorMessage = styled(BodyText)`
	font-style: normal;
	font-weight: normal;
	font-size: 13px;
	line-height: 115%;
	color: #e81111;
	text-align: left;
	padding: 0;
	margin-top: 8px;
`;

const StyledContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	position: relative;
	&.virtual {
		pointer-events: none;
	}
`;

const StyledLabel = styled.label`
	display: block;
	font-family: museo-sans;
	font-style: normal;
	font-weight: normal;
	font-size: 13px;
	line-height: 15px;
	color: #333333;
	padding-bottom: 8px;
	&.disabled {
		opacity: 0.2;
	}
`;

interface HeaderProps {
	error?: string;
	isOpen?: boolean;
}

const DropDownHeader = styled.div<HeaderProps>`
	font-size: 16px;
	line-height: 19px;
	color: #333333;
	height: 40px;
	background: #ffffff;
	box-sizing: border-box;
	padding: 11px 16px 10px;
	position: relative;
	border-radius: ${(props) => (props.isOpen ? "5px 5px 0 0 " : "5px")};
	border: ${(props) =>
		props.error ? "2px solid #e81111" : "1px solid #919191"};
	&:focus,
	&:active {
		outline: 2px auto #116eee;
	}
	&:disabled {
		opacity: 0.2;
		border: 1px solid #333333;
	}

	&.disabled {
		opacity: 0.2;
	}

	&.virtual {
		border: none;
		padding: 0;
		pointer-events: none;
	}
`;

const DropDownListContainer = styled("div")`
	position: relative;
	z-index: 100;
	width: 100%;
`;

const DropDownList = styled("ul")`
	background: #ffffff;
	border: 1px solid #919191;
	box-sizing: border-box;
	border-radius: 0 0 5px 5px;
	width: 100%;
	border-top: none;
	position: absolute;
	top: -16px;
	overflow-y: scroll;
	max-height: 156px;
	padding: 7px 19px 7px 5.6px;
	font-size: 16px;
	line-height: 19px;
	-webkit-scrollbar {
		width: 1em;
		padding-left: 7px;
		color: #d7d7d7;
	}
	-webkit-scrollbar-thumb {
		outline: 6px solid #d7d7d7;
		height: 100px;
		color: #d7d7d7;
	}
	-webkit-scrollbar-track {
		box-shadow: inset 0 0 6px #d7d7d7;
	}
`;

const ListItem = styled.div<ListProps>`
	list-style: none;
	padding: 8px 0 8px 10.4px;
	color: #666666;
	&:hover {
		color: white;
		background: #116eee;
		border-radius: 5px;
	}
	&:focus {
		color: white;
		background: #116eee;
		outline: 2px solid #116eee;
		border-radius: 7px;
		outline-offset: 2px;
	}
	&:active {
		color: white;
		background: #004EB9;
		border-radius: 5px;
		outline: none;
	}
`;

interface ImgProps {
	isOpen?: boolean;
}

const StyledImg = styled.img<ImgProps>`
	position: absolute;
	right: 14.29px;
	top: ${(props) => (props.isOpen ? "16px" : "17px")};
	transform: ${(props) => (props.isOpen ? "rotate(180deg)" : "none")};
	&.virtual {
		display: none;
	}
`;

const StyledSpan = styled.span`
	&.notSelected {
		color: #666666;
	}
`;

export default SelectInput;
