import React from "react";
import styled from "styled-components";
import Heading from "./Heading";
import BodyText from "./BodyText";
import ErrorDivider from "../images/ErrorDivider.svg";
import ErrorQuestion from "../images/ErrorQuestion.png";

import { updateIsError, updateErrorCode, updatePrevPage } from "../DumbleData";

interface Props {
	language: string;
}

export const ErrorPage = ({ language }: Props) => {
	updateIsError(true);
	if(localStorage.getItem("errorCode") !== null && localStorage.getItem("errorCode") !== "undefined") {
		updateErrorCode(localStorage.getItem("errorCode"));
		localStorage.removeItem("errorCode");
	}
	if(localStorage.getItem("prevPage") !== null) {
		updatePrevPage(localStorage.getItem("prevPage"));
		localStorage.removeItem("prevPage");
	}

	return (
		<StyledErrorPage>
			<StyledErrorHeading>
				{language === "en" ? "Our apologies..." : "Toutes nos excuses..."}
			</StyledErrorHeading>
			<StyledBodyText>
				{language === "en" ? 
					"Sorry, something isn't working right. The logged-in features of the Host Hub are only available if:" 
					: "Toutes nos excuses, quelque chose ne fonctionne pas correctement. Les fonctions de connexion du portail du Festival du Livre ne sont disponibles que si :"}
				<ul>
					<li>{language === "en" ? 
						"You've hosted a Fair within the past year." 
						: "Vous avez organisé un Festival au cours de la dernière année."}</li>
					<li>{language === "en" ? 
						"Your email address is associated with an upcoming Fair." 
						: "Votre adresse courriel est associée à un Festival à venir."}</li>
				</ul>
			</StyledBodyText>
			<StyledBodyText>
				{language === "en" ? 
					"For other issues, clear your brower's cache, close your browser, then try logging in again. We recommend the Google Chrome browser for best results." 
					: " En cas d'autres problèmes, effacez la mémoire cache de votre navigateur, fermez votre navigateur, puis essayez de vous connecter à nouveau. Pour de meilleurs résultats, nous recommandons d'utiliser le navigateur Google Chrome."}
			</StyledBodyText>
			{/*Links complicate things, this is the easiest way to switch the language*/}
			{language === "en" ? 
				<StyledBodyText>Need more help? Contact <a href="https://www2.scholastic.ca/bookfairs/contact/">Customer Service</a>.</StyledBodyText>
				: <StyledBodyText>Besoin d'aide supplémentaire? <a href="https://www2.scholastic.ca/bookfairs/communiquez/">Communiquez avec le Service à la clientèle</a>.</StyledBodyText> }
			<img className="error-divider" alt="divider" src={ErrorDivider}/>
			<MoreResources>
				<img className="error-question" alt="question mark" src={ErrorQuestion}/>
				<MoreResourcesText>
					<MoreResourcesHeader>
						{language === "en" ? "More Resources:" : "Plus de ressources :"}
					</MoreResourcesHeader>
					<StyledBodyText className="resources">
						{language === "en" ? 
							<ul>
								<li>Review our most popular <a href="https://www2.scholastic.ca/bookfairs/faq/">FAQs</a>.</li>
								<li>Looking for Book Fair tips and resources? Visit the new <a href="https://www2.scholastic.ca/bookfairs/learning-lab/">Learning Lab</a>!</li>
								<li><a href="https://www2.scholastic.ca/bookfairs/contact/">Contact</a> the Book Fair Branch that services your school. </li>
							</ul>
							: <ul>
								<li>Consultez notre <a href="https://www2.scholastic.ca/bookfairs/questions/">FAQ</a> pour voir les questions les plus populaires.</li>
								<li>Vous cherchez des conseils et des ressources pour le Festival du Livre? Visitez la nouvelle section <a href="https://www2.scholastic.ca/bookfairs/info-festival/">Info-Festival</a>!</li>
								<li><a href="https://www2.scholastic.ca/bookfairs/communiquez/">Communiquez</a> avec la succursale du Festival du Livre qui dessert votre école.</li>
							</ul>}
					</StyledBodyText>
				</MoreResourcesText>
			</MoreResources>
		</StyledErrorPage>
	);

};

const StyledErrorPage = styled.div`	
	max-width: 760px;
	display: block;
	margin: 0 auto;
	margin-top: 96px;
	margin-bottom: 64px;
	overflow: hidden;
	.error-divider {
		margin: 32px 0;
	}
	@media (max-width: 959px) {
		margin: 64px 40px;
		max-width: 100%;
	}
	@media (max-width: 719px) {
		margin: 32px 20px;
		max-width: 100%;
	}
`;

const StyledErrorHeading = styled(Heading)`
	font-size: 54px;
	line-height: 56px;
	font-weight: 500;
	margin-bottom: 32px;
`;

const StyledBodyText = styled(BodyText)`
	text-align: left;
	font-size: 18px;
	font-weight: 300;
	line-height: 22px;
	margin-bottom: 24px;
	ul {
		padding-inline-start: 20px;
	}
	a {
		text-decoration: underline;
	}
	&.resources {
		margin-bottom: 0;
	}
`;

const MoreResources = styled.div`
	display: flex;
	gap: 40px;
	.error-question {
		max-height: 120px;
		max-width: 120px;
	}
	@media (max-width: 719px) {
		flex-direction: column;
		align-items: center;
	}
`

const MoreResourcesText = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: 8px;
	ul {
		margin: 0;
		padding-inline-start: 32px;
	}
`;

const MoreResourcesHeader = styled(Heading)`
	text-align: left;
	margin: 0;	
	font-size: 24px;
	font-weight: 500;
	line-height: 28px;
`;

export default ErrorPage;