import React from "react";
import CAFooter from "./CAFooter";
import FatFooter from "./FatFooter";
import GlobalFooter from "./GlobalFooter";

interface Props {
	language: string;
}

const Footer = ({ language }: Props) => {
	const printCOA = document.location.pathname.includes("print-coa.html");

	if(printCOA) {
		return null;
	}
	return (
		<div className="ca-footer">
			<CAFooter language={language} />
			<FatFooter language={language} />
			<GlobalFooter language={language} />
		</div>
	);
};

export default Footer;
