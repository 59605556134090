import React from "react";
import styled from "styled-components";
import Heading from "../Heading";
import BodyText from "../BodyText";

interface Props {
	goalBooks: number;
	soldBooks: number;
	goalMessage: any;
	language: string;
}
const GoalComponent = ({
	goalBooks,
	soldBooks,
	goalMessage,
	language,
}: Props) => {
	let tempPercent = Math.floor((soldBooks / goalBooks) * 100);
	if (tempPercent < 1) {
		tempPercent = 1;
	}
	if (tempPercent > 100) {
		tempPercent = 100;
	}
	const stringPercent = `${tempPercent}%`;
	return (
		<GoalComponentSection>
			<StyledHeading>
				{language === "en" ? "School Goal" : "Objectif de l’école"}
			</StyledHeading>
			<FlexRow>
				<InlineElement>
					<Container>
						<GoalTracker>
							<ProgressBar percent={stringPercent} complete={soldBooks >= goalBooks}>
								{tempPercent >= 35 ? `${soldBooks} ${language === 'en' ? ' Books' : ' Livres'}`: ""}
							</ProgressBar>
						</GoalTracker>
						<Row>
							<StyledBodyText className={"Sold"}>
								0
							</StyledBodyText>
							<StyledBodyText className={`Goal ${soldBooks > goalBooks ? "goalCompleted" : ""}`}>
								{goalBooks}{" "}{language === 'en' ? " Books" : " Livres"}
							</StyledBodyText>
						</Row>
					</Container>
				</InlineElement>
				<InlineElement>
					<Message>
						{language === "en"
							? goalMessage["english"]
							: goalMessage["french"]}
					</Message>
				</InlineElement>
			</FlexRow>
		</GoalComponentSection>
	);
};

interface ProgressBarProps {
	percent: string;
	complete: boolean;
}

const StyledBodyText = styled(BodyText)`
	display: inline-flex;
	font-size: 18px;
	font-style: normal;
	font-weight: 600;
	line-height: 21px;
	letter-spacing: 0px;
	text-align: right;
	color: #333;
	position: relative;
	&.Sold {
		left: 0;
	}
	&.Goal {
		right: 0;
		position: absolute;
	}
`;

const Container = styled.div`
	min-width: 360px;
	position: relative;
	@media (max-width: 719px){
		min-width: 100%;
	}
`;
const Row = styled.div`
	display: inline-flex;
	width: 100%;
	top: 48px;
	position: relative;
	overflow: hidden;
	white-space: nowrap;
`;
const ProgressBar = styled.div<ProgressBarProps>`
	position: absolute;
	width: ${(props) => props.percent};
	height: 40px;
	background: #e02c39;
	border-top-left-radius: 8px;
	border-bottom-left-radius: 8px;
	border-top-right-radius: ${(props) => (props.complete ? "8px" : "0")};
	border-bottom-right-radius: ${(props) => (props.complete ? "8px" : "0")};
	color: white;
	text-align: right;
	box-sizing: border-box;
	padding: 10px;
	font-weight: 700;
`;
const GoalTracker = styled.div`
	position: absolute;
	width: 360px;
	height: 40px;
	background: #ffffff;
	border-radius: 8px;
	@media (max-width: 719px) {
		width: 100%;
	}
`;

const FlexRow = styled.div`
	display: inline-flex;
	width: 100%;
	gap: 40px;
	@media (max-width: 959px) {
		display: flex;
		flex-direction: column;
		width: 100%;
		gap: 25px;
		align-items: center;
	}
`;

const InlineElement = styled.div`
	flex: 1 auto;
	width: calc(50% - 20px);
	@media (max-width: 959px) {
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100%;
	}
`;

const Message = styled(BodyText)`
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 19px;
	letter-spacing: 0px;
	text-align: left;
	max-width: 340px;
	@media (max-width: 959px) {
		max-width: 315px;
		margin-top: 40px;
	}
	@media (max-width: 719px) {
		min-width: 100%;
	}
`;

const StyledHeading = styled(Heading)`
	font-size: 21px;
	font-style: normal;
	font-weight: 600;
	line-height: 24px;
	letter-spacing: 0px;
	text-align: center;
	color: #333333;
`;

const GoalComponentSection = styled.div`
	width: 100%;
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-bottom: 71px;
	min-height: 112px;
	@media (max-width: 959px) {
		padding-bottom: 51px;
	}

	@media (max-width: 719px) {		
        padding: 0 20px;
        box-sizing: border-box;
	}
`;
export default GoalComponent;
