import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import BodyText from "../../BodyText";
import { RedButton } from "../../Button";
import RedArrowDown from "../../../images/RedArrow.svg";
import RedArrowUp from "../../../images/RedArrowUp.svg";
import { DatePickerComponent } from "../../DatePicker";
import TextInput from "../../TextInput";
import TimeSelect from "../../DropDownSelect";
import LimitedTextArea from "../../LimitedTextArea";
import TextLink from "../../TextLink";
import { useConfig } from "../../../providers/ConfigContext";
import TimeList from "./TimeList.json";
import MilitaryTimeList from "./MilitaryTimeList.json";
import EventCategories from "./EventCategories.json";
import { convertToMilitaryTime, frenchConvertToMilitaryTime, convertToStandardTime, toISODate } from "./CleanTime";
import { convertStringToDate } from "../../../utils/DateConversion";

interface Props {
	setMenuOpen(): any;
	setAddEventOpen(): any;
	fairStartDate: Date;
	fairEndDate: Date;
	eventTypeIndex?: number;
	isMenuOpen: boolean;
	saveEvent(newEvent: object, eventPreEdit?: object, index?: number): any;
	//list of two elements that are defined if editing an existing event
	//editEventInfo[0] is object of event that is being edited
	//editEventInfo[1] is index of event in schedule list
	editEventInfo?: any;
	language: string;
}

export const AddEventComponent = ({
	setMenuOpen,
	setAddEventOpen,
	fairStartDate,
	fairEndDate,
	eventTypeIndex,
	isMenuOpen,
	saveEvent,
	editEventInfo,
	language,
}: Props) => {
	const { damAssetsOrigin } = useConfig();

	const calculatedIndex = editEventInfo
		? EventCategories.findIndex(
				(item) => item.englishType === editEventInfo[0].category
		  )
		: 0;
	// If creating a new event, use eventTypeIndex to find event type, if updating an event, use the calculated index to find eventType
	const index = eventTypeIndex ? eventTypeIndex : calculatedIndex;

	let tempDate;
	if (editEventInfo) {
		// If updating event then use the event date from the save event info.
		tempDate = convertStringToDate(editEventInfo[0].date);
	} else {
		if (fairStartDate <= new Date()) {
			// If new event, use current date as default if fair is active.
			tempDate = new Date();
		} else {
			// If fair has not started yet, default date is first day of the fair.
			tempDate = fairStartDate;
		}
	}
	const [eventDate, setEventDate] = useState<Date>(tempDate);
	const [title, setTitle] = useState<string>(
		editEventInfo ? editEventInfo[0].title : ''
	);
	const [startTime, setStartTime] = useState<string>(
		editEventInfo ? language === "en" ? convertToStandardTime(editEventInfo[0].startTime) : convertToMilitaryTime(editEventInfo[0].startTime) : ""
	);
	const [endTime, setEndTime] = useState<string>(
		editEventInfo ? language === "en" ?  convertToStandardTime(editEventInfo[0].endTime) : convertToMilitaryTime(editEventInfo[0].endTime) : ""
	);
	const [englishDetailsMessage, setEnglishDetailsMessage] = useState(
		editEventInfo ? editEventInfo[0].detailMessage.english : "Please help our school meet our goal and inspire a love of reading in our students."
	);
	const [frenchDetailsMessage, setFrenchDetailsMessage] = useState(
		editEventInfo ? editEventInfo[0].detailMessage.french : "Aidez notre école à atteindre son objectif et à insuffler l’amour de la lecture à nos élèves."
	);
	const [titleError, setTitleError] = useState<string>();
	const [startTimeError, setStartTimeError] = useState<string>();
	const [endTimeError, setEndTimeError] = useState<string>();
	const [attemptedToSubmit, setAttemptedToSubmit] = useState<boolean>(false);
	const addEventRef = useRef<HTMLDivElement>(null);
	const [isCalendarOpen, setIsCalendarOpen] = useState<boolean>(false);

	// When opening modal, modal scrolls into center of screen
	useEffect(() => {
		if (addEventRef.current !== null) {
			addEventRef.current.scrollIntoView({
				behavior: "smooth",
			});
		}
	}, []);

	// Callback to validate data
	// Will first be triggered by first submit and then will run each time an input field is changed
	const dataValidate = useCallback((language: string) => {
		let isAllValid = true;
		let startMilitaryTime;
		let endMilitaryTime;
		if (title === null || title === "" || title.match(/^ *$/) !== null) {
			language === "en" ? setTitleError("Please enter an event title.") : setTitleError("Veuillez inscrire un nom pour votre événement.");
			isAllValid = false;
		} else {
			setTitleError(undefined);
		}
		if (startTime === "") {
			language === "en" ? setStartTimeError("Please enter a start time.") : setStartTimeError("Veuillez inscrire une heure de début.");
			isAllValid = false;
			return;
		} else if (endTime === "") {
			language === "en" ? setEndTimeError("Please enter an end time.") : setEndTimeError("Veuillez inscrire une heure de fin.");
			isAllValid = false;
			return;
		} else {
			startMilitaryTime = startTime.includes("AM") ||  startTime.includes("PM") ?  convertToMilitaryTime(startTime) : frenchConvertToMilitaryTime(startTime);
			endMilitaryTime = endTime.includes("AM") ||  endTime.includes("PM") ? convertToMilitaryTime(endTime) : frenchConvertToMilitaryTime(endTime);
			if (startMilitaryTime >= endMilitaryTime) {
				language === "en" ? setStartTimeError("Event start time should be before the end time.") : setStartTimeError("L'heure de début de l’événement doit être avant l'heure de fin.");
				language === "en" ? setEndTimeError("Event end time should be after the start time.") : setEndTimeError("L'heure de fin de l’événement doit être après l'heure de début.");
				isAllValid = false;
				return;
			} else {
				setStartTimeError(undefined);
				setEndTimeError(undefined);
			}
		}
		return isAllValid;
	}, [startTime, endTime, title]);

	// Adds or edits event
	const submitEvent = () => {
		if (dataValidate(language)) {
			// Creates JSON of new event object
			const newEventObj = {
				date: toISODate(eventDate),
				category: EventCategories[index].englishType,
				title: title,
				startTime: startTime.includes("AM") ||  startTime.includes("PM") ? convertToMilitaryTime(startTime) : frenchConvertToMilitaryTime(startTime),
				endTime:  endTime.includes("AM") ||  endTime.includes("PM") ? convertToMilitaryTime(endTime) : frenchConvertToMilitaryTime(endTime),
				detailMessage: {
					english: englishDetailsMessage,
					french: frenchDetailsMessage,
				}
			};
			
			if (editEventInfo) {
				// First parameter is passing in event object
				// Second is passing in index of event in event list if being updated
				saveEvent(newEventObj, editEventInfo[0], editEventInfo[1]);
			} else {
				saveEvent(newEventObj);
			}
			setAddEventOpen();
			window.scrollTo({
				top: 0,
				left: 0,
				behavior: 'smooth'
			});
		} else {
			setAttemptedToSubmit(true);
		}
	};

	// Allows for the dataValidate callback to be executed anytime the inputs are changed after first submission attempt
	useEffect(() => {
		if (attemptedToSubmit) {
			dataValidate(language);
		}
	}, [language, attemptedToSubmit, dataValidate, title]);

	const submitButtonText = language === "en" ? "Add Event" : "Ajouter un événement";

	return (
		<AddEventContainer id="AddEvent" ref={addEventRef}>
			<InnerContainer>
				<EventHeading onClick={setMenuOpen}>
					{language === "en" ? EventCategories[index].englishType : EventCategories[index].frenchType}
					{isMenuOpen ? <EventDownArrow src={RedArrowUp}/> : <EventDownArrow src={RedArrowDown}/>}
				</EventHeading>
				<CenteredContainer>
					<StyledBodyText>
					{language === "en" ? EventCategories[index].englishDescription : EventCategories[index].frenchDescription}
					</StyledBodyText>
				</CenteredContainer>
				<CenteredContainer>
					<StyledImg
						src={`${damAssetsOrigin}canadatoolkit/homepagebuilder/${EventCategories[index].imageLink}`}
					/>
				</CenteredContainer>
				<FlexRow className={"title-date"}>
					<InlineElement onFocus={() => setIsCalendarOpen(false)}>
						<TextInput
							label={language === "en" ? "Event Title" : "Nom de l’événement"}
							placeholder={language === "en" ? EventCategories[index].englishType : EventCategories[index].frenchType}
							value={title}
							onChange={setTitle}
							error={titleError}
						/>
					</InlineElement>
					<InlineElement onFocus={() => setIsCalendarOpen(true)}>
						<DatePickerComponent
							language={language}
							label={language === "en" ? "Event Date" : "Date de l’événement"}
							value={eventDate}
							onChange={setEventDate}
							defaultStart={fairStartDate}
							defaultEnd={fairEndDate}
							isOpen={isCalendarOpen}
							onClick={() => setIsCalendarOpen(true)}
						/>
					</InlineElement>
				</FlexRow>
				<FlexRow onFocus={() => setIsCalendarOpen(false)}>
					<InlineElement>
						<TimeSelect
							label={language === "en" ? "Start Time" : "Heure de début"}
							defaultText={language === "en" ? "Select" : "Sélectionner"}
							onChange={setStartTime}
							disabled={false}
							options={language === "en" ? TimeList : MilitaryTimeList}
							optionChosen= {(language !== "en" && (startTime.includes("AM") ||  startTime.includes("PM"))) ? convertToMilitaryTime(startTime) : frenchConvertToMilitaryTime(startTime)}
							error={startTimeError}
							defaultValue={language === "en" ? "7:00 AM" : "7:00"}
						/>
					</InlineElement>
					<InlineElement>
						<TimeSelect
							label={language === "en" ? "End Time" : "Heure de fin"}
							defaultText={language === "en" ? "Select" : "Sélectionner"}
							onChange={setEndTime}
							disabled={false}
							options={language === "en" ? TimeList : MilitaryTimeList}
							optionChosen={(language !== "en" && (endTime.includes("AM") ||  endTime.includes("PM"))) ? convertToMilitaryTime(endTime) : frenchConvertToMilitaryTime(endTime)}
							error={endTimeError}
							defaultValue={language === "en" ? "7:00" : "7:30"}
						/>
					</InlineElement>
				</FlexRow>
				<StyledLimitedTextArea
					value={englishDetailsMessage}
					onChange={setEnglishDetailsMessage}
					label={language === "en" ? "Add more English details to display on homepage (Optional)" : "Ajoutez plus de détails en anglais sur la page d’accueil (facultatif)"}
					limit={350}
					language={language}
				/>
				<StyledLimitedTextArea
					value={frenchDetailsMessage}
					onChange={setFrenchDetailsMessage}
					label={language === "en" ? "Add more French details to display on homepage (Optional)" : "Ajoutez plus de détails en français sur la page d’accueil (facultatif)"}
					limit={350}
					language={language}
				/>
				<CenteredContainer>
					<StyledRedButton handleClick={submitEvent}>
						{submitButtonText}
					</StyledRedButton>
				</CenteredContainer>
				<CenteredContainer
					onClick={() => {
						setAddEventOpen();
						if (isMenuOpen) {
							setMenuOpen();
						}
					}}
				>
					<StyledTextLink>{language === "en" ? "Cancel" : "Annuler"}</StyledTextLink>
				</CenteredContainer>
			</InnerContainer>
		</AddEventContainer>
	);
};

/*
	Page Specific Styles
*/
const StyledLimitedTextArea = styled(LimitedTextArea)`
	margin-top: 24px;
`;

const CenteredContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`;

const InnerContainer = styled.div`
	padding: 32px 80px;
	@media (max-width: 620px) {
		padding: 24px 20px;
	}
`;
const FlexRow = styled.div`
	.title-date {
		margin-top: 32px;
	}
	margin-top: 24px;
	display: inline-flex;
	width: 100%;
	gap: 16px;

	@media (max-width: 620px) {
		flex-direction: column;
	}
`;
const StyledTextLink = styled(TextLink)`
	margin-top: 16px;
`;
const InlineElement = styled.div`
    width: calc(50% - 16px);
	min-width: 212px;

	@media (max-width: 640px) {
		width: 100%;
	}
`;
const StyledImg = styled.img`
	width: 100%;
	height: 176px;
	max-width: 440px;
	border-radius: 0px;
	margin-bottom: 33px;
`;
const EventHeading = styled.button`
	display: flex;
	justify-content: center;
	border: none;
	background: none;
	font-style: normal;
	font-weight: 600;
	font-size: 24px;
	line-height: 28px;
	text-align: center;
	width: 100%;
	color: #e81111;
	text-transform: uppercase;
	position: relative;
	padding-bottom: 8px;
`;

const AddEventContainer = styled.div`
	max-width: 600px;
	background: #fff5f5;
	border-radius: 16px;
	margin-top: 24px;
	margin-bottom: 32px;
	align-self: center;
	@media (max-width: 375px) {
		max-width: 375px;
		border-radius: 0;
	}
	display: flex;
`;

const StyledBodyText = styled(BodyText)`
	max-width: 440px;
	margin-bottom: 32px;
	font-size: 13px;
	font-weight: 400;
	line-height: 16px;
`;
const StyledRedButton = styled(RedButton)`
	margin-top: 32px;
`;

const EventDownArrow = styled.img`
	padding-left: 8px;
	padding-top: 2px;
`;
