import React, { useEffect, useRef, useState, useCallback } from "react";
import axios from "axios";
import styled from "styled-components";
import { validateEmail, validatePhoneNumber } from "../../../utils/InputValidations";
import { useNavigate } from "react-router-dom";
import { useConfig } from "../../../providers/ConfigContext";
import { Page } from "../Page";
import Heading from "../../Heading";
import PageIcon from "../PageIcon";
import ContactIcon from "../../../images/Contact.svg";
import BodyText from "../../BodyText";
import TextInput from "../../TextInput";
import Checkbox from "../../Checkbox";
import { RedButton } from "../../Button";
import TextLink from "../../TextLink";
import SaveChangesModal from "../SaveChangesModal";
import Prompt from "../Prompt";
import { trackDiscardChanges, trackSaveAndPublishButton, trackSkipButton, updatePageType, updateUserLoginStatus, updateUserFairId, updateUserAccountId } from "../../../DumbleData";
import { handleGenericErrors, handleGenericSuccess } from "../../../utils/ErrorUtility";

interface Props {
	language: string;
	builderNavDisabled: boolean;
	setBuilderNavDisabled: any;
	builderNextLocation: string;
	setBuilderNextLocation: any;
}

export const ContactPage = ({ 
	language,
	builderNavDisabled,
	setBuilderNavDisabled,
	builderNextLocation,
	setBuilderNextLocation }: Props ) => {

	const contactNextLocation = "/customize/goals";
	setBuilderNextLocation(contactNextLocation);
	const { damAssetsOrigin } = useConfig();
	const navigate = useNavigate();
	const [attemptedToSubmit, setAttemptedToSubmit] = useState<boolean>(false);
	const [saveChangesModalOpen, setSaveChangesModalOpen] = useState(false);
	const [allChangesSaved, setAllChangesSaved] = useState(true);
	const getRequestDone = useRef(false);
	const [firstNameError, setFirstNameError] = useState<string>();
	const [lastNameError, setLastNameError] = useState<string>();
	const [emailError, setEmailError] = useState<string>();
	const [phoneNumberError, setPhoneNumberError] = useState<string>();
	const [allowNavigation, setAllowNavigation] = useState(false);
	const [savedData, setSavedData] = useState<any>();
	const [firstName, setFirstName] = useState<string>();
	const [lastName, setLastName] = useState<string>();
	const [email, setEmail] = useState<string>();
	const [phoneNumber, setPhoneNumber] = useState<string>();
	const [displayName, setDisplayName] = useState<boolean>(false);
	const [displayPhoneNumber, setDisplayPhoneNumber] = useState<boolean>(false);

	// GET request
	useEffect(() => {
		window.scrollTo(0, 0);
		axios.get(`${process.env.REACT_APP_ENV_BASE_URL}/api/user/fairs/current/homepage/contact-info`, {
		  withCredentials: true
		}).then((res) => {
			if(res.status === 200) {
				setSavedData(res.data);
				setFirstName(res.data.firstName);
				setLastName(res.data.lastName);
				setEmail(res.data.email);
				setPhoneNumber(res.data.phoneNumber);
				setDisplayName(res.data.displayName);
				setDisplayPhoneNumber(res.data.displayPhoneNumber);
				updateUserLoginStatus(true);
				updateUserFairId(res.data.fairId);
				updateUserAccountId(res.data.bookfairAccountId)
				updatePageType("homepage-builder");
				getRequestDone.current = true;
			} else {
				handleGenericSuccess(res);
			}
		})
		.catch((error) => {
		  handleGenericErrors(error);
		})
		
	}, [])

	// Error handling
	const dataValidate = useCallback((language: string) => {
		let valid = true;
		if (!firstName) {
			language === "en" ? setFirstNameError("This field is required.") : setFirstNameError("Ce champ est obligatoire.")
			valid = false;
		} else {
			setFirstNameError(undefined);
		}
		if (!lastName) {
			language === "en" ? setLastNameError("This field is required.") : setLastNameError("Ce champ est obligatoire.")
			valid = false;
		} else {
			setLastNameError(undefined);
		}
		if (!email || (email && !validateEmail(email))) {
			language === "en" ? setEmailError("Please enter a valid email address."): setEmailError("Veuillez inscrire une adresse courriel valide.")
			valid = false;
		} else {
			setEmailError(undefined);
		}
		if (displayPhoneNumber || (phoneNumber && phoneNumber.length)) {
			if (phoneNumber && !validatePhoneNumber(phoneNumber)) {
				language === "en" ? setPhoneNumberError("Please enter a valid 10 digit phone number.") : setPhoneNumberError("Veuillez inscrire un numéro de téléphone à 10 chiffres.")
				valid = false;
			} else {
				setPhoneNumberError(undefined);
			}
		}
		return valid;
	}, [displayPhoneNumber,
		email,
		firstName,
		lastName,
		phoneNumber]);

	// PUT request
	const submitContactData = async () => {
			try { 
				if (dataValidate(language)) {
					await axios
						.put(
							`${process.env.REACT_APP_ENV_BASE_URL}/api/user/fairs/current/homepage/contact-info`,
							{
								firstName: firstName,
								lastName: lastName,
								email: email,
								phoneNumber: phoneNumber,
								displayName: displayName,
								displayPhoneNumber: displayPhoneNumber,
							},
							{ withCredentials: true }
						)
						.then((response) => {
							if (response.status === 200) {
								setAllChangesSaved(true);
								setBuilderNavDisabled(false);
								setAllowNavigation(true);
							} else {
								handleGenericSuccess(response);
							}
						})
						.catch((err) => {
							handleGenericErrors(err);
						});						
				} else {
					setSaveChangesModalOpen(false);
					setBuilderNavDisabled(false);
					setAttemptedToSubmit(true);
				}
			} catch (err) {
				console.log(err);
				handleGenericErrors(err);
			}
		
	};

	// Tracks changes made
	useEffect(() => {
		if (getRequestDone.current && 
			(firstName !== savedData.firstName || lastName !== savedData.lastName ||
				email !== savedData.email || phoneNumber !== savedData.phoneNumber ||
				displayName !== savedData.displayName || displayPhoneNumber !== savedData.displayPhoneNumber)) {
			setAllChangesSaved(false);
		}
	}, [
		firstName,
		lastName,
		email,
		phoneNumber,
		displayName,
		displayPhoneNumber,
		savedData
	]);

	// Validates input after every change (but not on page load)
	useEffect(() => {
		if (attemptedToSubmit) {
			dataValidate(language);
		}
	}, [language, attemptedToSubmit, dataValidate]);

	// Allows for navigation to next page after clicking discard changes on save changes modal
	useEffect(() => {
		if (allChangesSaved && allowNavigation) {
			navigate(builderNextLocation);
		}
	}, [
		allChangesSaved,
		saveChangesModalOpen,
		allowNavigation,
		navigate,
		builderNextLocation,
	]);

	// When save changes modal opens, scroll to top of page
	useEffect(() => {
		if (saveChangesModalOpen) {
			window.scrollTo({
				top: 0,
				left: 0,
				behavior: 'smooth'
			});
		}
	}, [saveChangesModalOpen]);
	
	return (
		<>
			<Page>
				<PageIcon
					src={`${damAssetsOrigin}canadatoolkit/homepagebuilder/Contact.svg`}
					alt="Contact Form Icon"
					defaultImg={ContactIcon}
				/>
				<StyledHeading>{language === "en" ? "Your Contact Information" : "Vos coordonnées"}</StyledHeading>
				<Wrapper>
					<StyledBodyText>
						{language === "en" ? 
							`Visitors to your Book Fair homepage will not see
							your private contact information, but the site
							will allow them to send email messages to you.` :
							`Les visiteur·ses de la page d’accueil de votre Festival 
							du Livre ne verront pas vos coordonnées privées, mais 
							le site leur permettra de vous envoyer des messages par courriel.`}
					</StyledBodyText>
					<FlexRow>
						<InlineElement>
							<TextInput
								label={language === "en" ? "First Name" : "Prénom"}
								value={firstName}
								onChange={setFirstName}
								disabled={saveChangesModalOpen}
								error={firstNameError}
							></TextInput>
						</InlineElement>
						<InlineElement>
							<TextInput
								label={language === "en" ? "Last Name" : "Nom"}
								value={lastName}
								onChange={setLastName}
								disabled={saveChangesModalOpen}
								error={lastNameError}
							></TextInput>
						</InlineElement>
					</FlexRow>
					<LeftAlignWrapper className="display-name">
						<Checkbox
							label={language === "en" ? "Display name on homepage" : "Afficher le nom sur la page d’accueil"}
							checked={displayName}
							disabled={saveChangesModalOpen}
							onChange={setDisplayName}
						/>
					</LeftAlignWrapper>
					<StyledRow>
						<TextInput
							label={language === "en" ? "Email" : "Courriel"}
							type="email"
							value={email}
							onChange={setEmail}
							disabled={saveChangesModalOpen}
							error={emailError}
						></TextInput>
					</StyledRow>
					<StyledRow>
						<TextInput
							label={language === "en" ? "Phone Number (optional)" : "Numéro de téléphone (facultatif)"}
							value={phoneNumber}
							onChange={setPhoneNumber}
							disabled={saveChangesModalOpen}
							error={phoneNumberError}
						></TextInput>
					</StyledRow>
					<LeftAlignWrapper>
						<Checkbox
							label={language === "en" ? "Display phone number on homepage" : "Afficher le numéro de téléphone sur la page d’accueil"}
							checked={displayPhoneNumber}
							onChange={setDisplayPhoneNumber}
							disabled={saveChangesModalOpen}
						/>
					</LeftAlignWrapper>
				</Wrapper>
				<StyledRedButton
					handleClick={() => {
						trackSaveAndPublishButton();
						setBuilderNextLocation(contactNextLocation);
						submitContactData();
					}}
					disabled={saveChangesModalOpen}
				>
					{language === "en" ? "Save & Publish" : "Enregistrer et publier"}
				</StyledRedButton>
				<StyledTextLink
					handleClick={() => {
						trackSkipButton();
						if (!allChangesSaved) {
							setBuilderNextLocation(contactNextLocation);
							setBuilderNavDisabled(!builderNavDisabled);
							setSaveChangesModalOpen(!saveChangesModalOpen);
						} else {
							navigate(contactNextLocation);
						}
					}}
					disabled={saveChangesModalOpen}
				>
					{language === "en" ? "Skip" : "Ignorer"}
				</StyledTextLink>
			</Page>

			<SaveChangesModal
				saveAndPublish={submitContactData}
				discardChanges={() => {
					setFirstName(savedData.firstName);
					setLastName(savedData.lastName);
					setEmail(savedData.email);
					setPhoneNumber(savedData.phoneNumber);
					setDisplayPhoneNumber(savedData.displayPhoneNumber);
					setAllChangesSaved(true);
					setAllowNavigation(true);
					setBuilderNavDisabled(false);
					trackDiscardChanges();
				}}
				modalIsOpen={saveChangesModalOpen}
				handleModal={() => {
					setSaveChangesModalOpen(!saveChangesModalOpen);
				}}
				language={language}
			/>
			<Prompt
				message={() => {
					setSaveChangesModalOpen(true);
					setBuilderNavDisabled(true);
				}}
				when={!allChangesSaved}
			/>
		</>
	);
};

/*
	Page Specific Styles
*/
const StyledHeading = styled(Heading)`
	margin-top: 24px;
	margin-bottom: 16px;
`;

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	max-width: 280px;
`;

const FlexRow = styled.div`
	display: inline-flex;
	width: 100%;
	gap: 40px;
	padding-bottom: 16px;
`;

const StyledRow = styled.div`
	margin-bottom: 16px;
	width: 100%;
`;

const InlineElement = styled.div`
	flex: 1 auto;
	width: calc(50% - 20px);
`;

const LeftAlignWrapper = styled.div`
	height: 20px;
	align-self: flex-start;

	&.display-name {
		margin-bottom: 16px;
	}
`;

const StyledBodyText = styled(BodyText)`
	padding-bottom: 48px;
	width: 360px;
	@media (max-width: 719px) {
		padding-bottom: 47px;
	}
	@media (max-width: 374px) {
		padding-bottom: 40px;
	}
`;

const StyledRedButton = styled(RedButton)`
	margin-top: 48px;
`;

const StyledTextLink = styled(TextLink)`
	margin-top: 16px;
`;
