import React, { useEffect } from "react";
import styled from "styled-components";
import BookFair from "./BookFair";
import DisplayFair from "./DisplayFair";
import { convertDateToMDY, convertStringToDate, convertDateToYMD } from "../../../utils/DateConversion";
import { FairInfoData, ChairpersonData, OrganizationData} from "../ConfirmYourFair";
import COApdf from '../../../images/COApdf.png';
import COApdf_FR from "../../../images/COApdf_FR.jpg";

interface Props {
  emailedPage?: boolean;
	handlePrint?: Function;
	printClicked?: boolean;
	fairInfoData: FairInfoData;
	consultantData?: any;
	chairpersonData: ChairpersonData;
	organizationData: OrganizationData;
	language: string;
}
const LegalCopyToPrint = ({
  emailedPage,
	handlePrint,
	printClicked,
	fairInfoData,
	consultantData,
	chairpersonData,
	organizationData,
	language
}: Props) => {

	useEffect(() => {
		// open print dialogue after page content is populated
		if (printClicked && handlePrint) {
			if (fairInfoData) {
				handlePrint();
			}
		}
	}, [
		printClicked,
		handlePrint,
		fairInfoData
	]);

	return (
		<PDF>
			{!emailedPage ? <StyledCOAImg src={language === "en" ? COApdf : COApdf_FR} /> : ''}
			{fairInfoData?.type === "Book Fair" && (
				<>
				<Heading>
					{language === "en" ? 
					<p>{fairInfoData?.type} Host Acknowledgement</p>
					: <p>Accord du Festival du Livre</p>}
				</Heading>
				<BookFair language={language} emailedPage={emailedPage} />
				</>
			)}
			{fairInfoData?.type === "Display Fair" && (
				<>
				<Heading>
				{language === "en" ? 
					<p>Display Book Fair Host Acknowledgement</p>
					: <p>Accord du Mini-Festival</p>}
				</Heading>
				<DisplayFair language={language} emailedPage={emailedPage}/>
				</>
			)}
			{!emailedPage ? <PageBreak /> : ''}
			{!emailedPage ? <StyledCOAImg src={language === "en" ? COApdf : COApdf_FR} /> : ''}
			<Receipt>
				{fairInfoData?.coaAccepted && (
					<>
						<ReceiptText>
							<b>{ language === "en" ? "A submitted copy of this Agreement confirms and secures your Book Fair dates, reserving product for your Fair." : "L'envoi d'une copie de cet accord confirme et garantit les dates de votre Festival du Livre en réservant des produits pour votre Festival." }</b>
						</ReceiptText>
						<Row>
							<ChairInfo>
								<InfoTextHeading>
									{language === "en" ? "Book Fair Host Information" : "Responsable du Festival du Livre"}
								</InfoTextHeading>
								<InfoText>
									{chairpersonData?.firstName}{" "}
									{chairpersonData?.lastName}
								</InfoText>
								<InfoText>{chairpersonData?.email}</InfoText>
								<InfoText>
									{chairpersonData?.phoneNumber}
								</InfoText>
							</ChairInfo>
							{consultantData && (<ConsultantInfo>
								<InfoTextHeading>
									{language === "en" ? "Book Fair Consultant" : "Conseiller·ère du Festival du Livre"}
								</InfoTextHeading>
								<InfoText>
									{consultantData.firstName}{" "}{consultantData.lastName}
								</InfoText>
								<InfoText>{consultantData.email}</InfoText>
							</ConsultantInfo>)}
						</Row>
					</>
				)}
				<Row>
					<SchoolInfo>
						<InfoTextHeading>{language === "en" ? "School Information" : "Informations sur l'école"}</InfoTextHeading>
						<InfoText>{organizationData?.name}</InfoText>
						{organizationData?.address && (<><InfoText>{organizationData?.address.line1}</InfoText>
						<InfoText>
							{organizationData?.address.city}, {organizationData?.address.province}
						</InfoText>
						<InfoText>{organizationData?.address.postalCode}</InfoText></>)}
						<InfoText>
							{language === "en" ? "Account #:" : "No de compte"} {organizationData?.bookfairAccountId}
						</InfoText>
					</SchoolInfo>
					<FairInfo>
						<InfoTextHeading>{language === "en" ? "Fair Information" : "Informations sur le Festival"}</InfoTextHeading>
						<InfoText>
							{language === "en" ? `Fair Dates: ${convertDateToMDY(convertStringToDate(fairInfoData?.start))} to ${convertDateToMDY(convertStringToDate(fairInfoData?.end))}` : `Dates du Festival ${convertDateToYMD(convertStringToDate(fairInfoData?.start))} to ${convertDateToYMD(convertStringToDate(fairInfoData?.end))}`}
						</InfoText>
						<InfoText>{language === "en" ? "Fair ID: #" : "No de Festival "}{fairInfoData?.id}</InfoText>
					</FairInfo>
				</Row>
				{fairInfoData?.coaAccepted && (
					<>
						<AgreementText>
							<b>{language === "en" ?  `Accepted on: ${convertDateToMDY(convertStringToDate(fairInfoData?.coaAcceptedDate))} ` : `Date de l'accord : ${convertDateToYMD(convertStringToDate(fairInfoData?.coaAcceptedDate))}`}</b>
						</AgreementText>
					</>
				)}
				<ReceiptFooterText><i><b>
				{language === "en" ?  `Note: Changes to this Services Agreement may be made solely at the discretion of Scholastic Book Fairs as permissible by
law and/or as business conditions deem appropriate. We will notify you of any such changes by posting the revised Services
Agreement in your Host Hub account. Under certain circumstances, we may also notify you of changes to this Services
Agreement by additional means, including, but not limited to, posting a notice on the Scholastic Book Fairs homepage,
sending you an email if we have your email address, or by contacting you through your Book Fair consultant. At that time,
you will have the opportunity to review and accept a new Services Agreement.` : `Remarque : Des modifications au présent accord de services peuvent être apportées à la seule discrétion du Festival du Livre
Scholastic, dans la mesure où la loi l'autorise ou si les conditions commerciales le justifient. Nous vous informerons de ces
modifications en publiant l'accord de services révisé dans votre portail du Festival du Livre. Dans certaines circonstances, nous
pouvons également vous informer des modifications apportées au présent accord de services par d'autres moyens, y compris, mais
sans s'y limiter, en publiant un avis sur la page d'accueil du Festival du Livre Scholastic, en vous envoyant un courriel si nous avons
votre adresse, ou en vous contactant par l'intermédiaire de votre conseiller·ère du Festival du Livre. Vous aurez alors la possibilité
d'examiner et d'accepter un nouvel accord de services.`}
				</b></i></ReceiptFooterText>
			</Receipt>
		</PDF>
	);
};

const PageBreak = styled.span`
	height: 600px;
`;

const StyledCOAImg = styled.img`
	width: 50%;
	padding-bottom: 25px;
`;

const AgreementText = styled.div`
	font-size: 9px;
	font-weight: 500;
	margin-bottom: 15px;
	margin-top: 30px;
`;

interface PDFProps {
	isEmailed?: boolean;
}

const PDF = styled.div<PDFProps>`
	display: flex;
	flex-direction: column;
	margin: 1in;
	align-items: center;
`;

const Heading = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	font-size: 12px;
	font-weight: 700;
	gap: 10px;
`;

const Receipt = styled.div`
	width: 6.5in;
`;

const ReceiptText = styled.div`
	font-size: 9px;
	font-weight: 700;
	margin-bottom: 15px;
`;

const ReceiptFooterText = styled.div`
	font-size: 9px;
	font-weight: 400;
`;

const Row = styled.div`
	display: flex;
	flex-direction: row;
	margin-bottom: 10px;
	gap: 150px;
`;
const ChairInfo = styled.div`
	width: 200px;
`;
const ConsultantInfo = styled.div`
	width: 200px;
	border-left: 1px solid black;
	padding-left: 10px;
`;
const InfoText = styled.div`
	font-size: 10.5px;
`;
const InfoTextHeading = styled.div`
	font-size: 10.5px;
	font-weight: 700;
`;
const SchoolInfo = styled.div`
	width: 200px;
`;
const FairInfo = styled.div`
	width: 200px;
	border-left: 1px solid black;
	padding-left: 10px;
`;

export default LegalCopyToPrint;
