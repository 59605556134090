import React from "react";
import styled from "styled-components";

interface Props {
	children: React.ReactNode;
}

const IconCaption = ({ children }: Props) => {
	return <StyledIconCaption>{children}</StyledIconCaption>;
};

const StyledIconCaption = styled.p`
	font-family: museo-sans;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 19px;
	text-align: center;
	color: #333333;
	max-width: 120px;
	margin: 0;
`;

export default IconCaption;
