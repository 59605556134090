import React from "react";
import styled from "styled-components";
import { ModalContainer } from "../../ModalContainer";
import { IconButtonWhite } from "../../Button";
import RedArrowLeft from "../../../images/RedArrowLeft.svg";

interface Props {
	language: string;
	closeModal: () => void;
	openEmailAgreementModal: () => void;
}

export const EmailFailureModal = ({
	language,
	closeModal,
	openEmailAgreementModal,
}: Props) => {
	const goBack = () => {
		closeModal();
		openEmailAgreementModal();
	};

	return (
		<ModalContainer closeModal={closeModal}>
			<StyledContainer>
				<StyledText>
					{language === "en" ? "Something went wrong... please try again." : "Une erreur est survenue... essayez à nouveau."}
				</StyledText>
				<StyledButtonContainer>
					<StyledWhiteButton
						iconSrc={RedArrowLeft}
						whiteBackground={true}
						handleClick={goBack}
					>
						Go Back
					</StyledWhiteButton>
				</StyledButtonContainer>
			</StyledContainer>
		</ModalContainer>
	);
};

const StyledContainer = styled.div`
	width: 600px;
	padding: 24px;
	box-sizing: border-box;
	@media (max-width: 719px) {
		width: 100vw;
	}
`;

const StyledText = styled.p`
	padding-top: 40px;
	padding-bottom: 32px;
	font-weight: 700;
	font-size: 16px;
	line-height: 21px;
	color: #333;
`;

const StyledButtonContainer = styled.div`
	display: flex;
	justify-content: flex-end;
`;

const StyledWhiteButton = styled(IconButtonWhite)`
	width: auto;
	float: right;
	@media (max-width: 719px){
		width: 100%;
	}
`;
