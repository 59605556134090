import React from "react";
import styled from "styled-components";
import Heading from "../Heading";
import Notifications from "./Notifications";
import Parent from "./Parent";
import EventsCarousel from "./EventsCarousel";

interface Props {
	fairId: number;
	bookfairAccountId: number;
	startDate: string;
	endDate: string;
	url: string;
	eventsData?: any;
	language: string;
}

const BeforeTheFairComponent = ({
	fairId,
	bookfairAccountId,
	startDate,
	endDate,
	eventsData,
	url,
	language,
}: Props) => {
	const events = eventsData.sort(function(a: any, b: any) { return new Date(`${a.date} ${a.startTime}`).valueOf() - new Date(`${b.date} ${b.startTime}`).valueOf() });
	
	return (
		<BeforeTheFair>
			<SectionContainer>
				<StyledHeading>
					{language === "en"
						? "Before the Fair"
						: "Avant le Festival"}
				</StyledHeading>
				<FlexRow>
					<InlineElement>
						<Parent language={language} />
					</InlineElement>
					<InlineElement>
						<Notifications
							language={language}
							fairId={fairId}
							bookfairAccountId={bookfairAccountId}
						/>
					</InlineElement>
				</FlexRow>
			</SectionContainer>

			<StyledHeading>
				{language === "en" ? "At The Fair" : "Au Festival"}
			</StyledHeading>
      
			<EventsCarousel
				eventsData={events}
				startDate={startDate}
				endDate={endDate}
				url={url}
        		language={language}
			/>
		</BeforeTheFair>
	);
};

const BeforeTheFair = styled.div`
	width: 100%;
	position: relative;
	background-color: #1226aa;
`;

const SectionContainer = styled.div`
	padding: 32px 0px 64px;
	display: flex;
	flex-direction: column;
	align-items: center;
	@media (max-width: 959px) {
		padding: 48px 40px;
	}
	@media (max-width: 519px) {
		padding: 32px 20px;
	}
`;

const FlexRow = styled.div`
	display: inline-flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	gap: 40px;
	@media (max-width: 1279px) {
		gap: 30px;
	}
	@media (max-width: 959px) {
		display: flex;
		flex-direction: column;
		gap: 48px;
	}
`;

const StyledHeading = styled(Heading)`
	text-transform: uppercase;
	font-size: 32px;
	font-style: normal;
	font-weight: 600;
	line-height: 35px;
	letter-spacing: 1px;
	text-align: center;
	color: #ffffff;
	margin-top: 32px;
	padding-bottom: 24px;
	@media (max-width: 670px) {
		margin-top: 54px;
	}
`;

const InlineElement = styled.div`
	height: 100%;
	@media (max-width: 959px) {
		width: 100%;
	}
`;

export default BeforeTheFairComponent;
