import "./App.css";
import { useEffect, useState } from "react";
import { ProvideConfig } from "./providers/ConfigContext";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import Navigation from "./components/Nav";
import Footer from "./components/Footer/Footer";
import Login from "./components/Login/Login";
import { Builder } from "./components/HomepageBuilder/pages/Builder";
import { CustomizePage } from "./components/HomepageBuilder/pages/CustomizePage";
import { Homepage } from "./components/HomepageBuilder/pages/Homepage";
import { VolunteerPage } from "./components/HomepageBuilder/pages/VolunteerPage";
import { ContactPage } from "./components/HomepageBuilder/pages/ContactPage";
import { InformationPage } from "./components/HomepageBuilder/pages/InformationPage";
import { ShareHomepagePage } from "./components/HomepageBuilder/pages/ShareHomepagePage";
import { ScheduleActivitiesPage } from "./components/HomepageBuilder/pages/ScheduleActivitiesPage";
import { GoalPage } from "./components/HomepageBuilder/pages/GoalPage";
import { FairSelectorContent } from './components/FairSelector/FairSelectorContent';
import { ConfirmYourFair } from "./components/ConfirmYourFair/ConfirmYourFair";
import PrintCoaPage from "./components/ConfirmYourFair/PrintCoaPage";
import FairDetailsPage from "./components/FairDetailsPage/pages/FairDetailsPage";
import ErrorPage from "./components/ErrorPage";

function App() {
	const lang = window.sessionStorage.getItem("bf_lang") || "en";
	const [language, setLanguage] = useState<string>(lang);
	const [builderNavDisabled, setBuilderNavDisabled] = useState<boolean>(false);
	const [builderNextLocation, setBuilderNextLocation] = useState<string>("");
	const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);

	useEffect(() => {
		setIsLoggedIn(window.sessionStorage.getItem("bf_profile") === "show");
	}, [isLoggedIn, setIsLoggedIn]);

	return (
		<ProvideConfig>
			<div className="App">
				<Navigation language={language} setLanguage={setLanguage} isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />
				<div className="innerPages">
					<Router>
						<Routes>
							<Route
								path="/bf/:homepageURL"
								element={<Homepage language={language} setLanguage={setLanguage} />}
							/>
							<Route
								path="/bf-fr/:homepageURL"
								element={<Homepage language={language} setLanguage={setLanguage} />}
							/>
							<Route path="/" element={<Navigate to="/login" replace />} />
							<Route
								path="/login"
								element={
									<Login
										language={language}
										setLanguage={setLanguage}
										setIsLoggedIn={setIsLoggedIn}
									/>
								}
							>
								<Route
									path=":lang"
									element={
										<Login
											language={language}
											setLanguage={setLanguage}
											setIsLoggedIn={setIsLoggedIn}
										/>
									}
								/>
							</Route>
							<Route
								path="/"
								element={
									<Builder
										language={language}
										builderNavDisabled={builderNavDisabled}
										builderNextLocation={
											builderNextLocation
										}
										setBuilderNextLocation={
											setBuilderNextLocation
										}
									/>
								}
							>
								<Route
								path="customize"
								element={<CustomizePage language={language} />}
							/>
								<Route
									path="customize/contact"
									element={
										<ContactPage
											language={language}
											builderNavDisabled={
												builderNavDisabled
											}
											setBuilderNavDisabled={
												setBuilderNavDisabled
											}
											builderNextLocation={
												builderNextLocation
											}
											setBuilderNextLocation={
												setBuilderNextLocation
											}
										/>
									}
								/>
								<Route
									path="customize/goals"
									element={
										<GoalPage
											language={language}
											builderNavDisabled={
												builderNavDisabled
											}
											setBuilderNavDisabled={
												setBuilderNavDisabled
											}
											builderNextLocation={
												builderNextLocation
											}
											setBuilderNextLocation={
												setBuilderNextLocation
											}
										/>
									}
								/>
								<Route
									path="customize/volunteer"
									element={
										<VolunteerPage
											language={language}
											builderNavDisabled={
												builderNavDisabled
											}
											setBuilderNavDisabled={
												setBuilderNavDisabled
											}
											builderNextLocation={
												builderNextLocation
											}
											setBuilderNextLocation={
												setBuilderNextLocation
											}
										/>
									}
								/>
     							<Route
									path="customize/schedule"
									element={<ScheduleActivitiesPage language={language}
												builderNavDisabled={builderNavDisabled}
												setBuilderNavDisabled={setBuilderNavDisabled}
												builderNextLocation={builderNextLocation}
												setBuilderNextLocation={setBuilderNextLocation} />}
     							/>
								<Route
									path="customize/information"
									element={
										<InformationPage
											language={language}
											builderNavDisabled={
												builderNavDisabled
											}
											setBuilderNavDisabled={
												setBuilderNavDisabled
											}
											builderNextLocation={
												builderNextLocation
											}
											setBuilderNextLocation={
												setBuilderNextLocation
											}
										/>
									}
								/>
								<Route
      								path="customize/share"
      								element={<ShareHomepagePage language={language} 
                      builderNavDisabled={
												builderNavDisabled
											}
											setBuilderNavDisabled={
												setBuilderNavDisabled
											}
											builderNextLocation={
												builderNextLocation
											}
											setBuilderNextLocation={
												setBuilderNextLocation
											} />}
      						/>
							</Route>
      						<Route
     							path="/fair-selector"
     							element={<FairSelectorContent isModal={false} language={language} />}
      						></Route>
      						<Route
     							path="/confirm-fair"
     							element={<ConfirmYourFair language={language} />}
      						></Route>
      						<Route
     							path="/print-coa.html"
     							element={<PrintCoaPage />}
      						></Route>
							<Route
     							path="/fair-details"
     							element={<FairDetailsPage language={language} />}
      						></Route>
      						<Route
      							path="error"
      							element={<ErrorPage language={language} />}
      						></Route>
						</Routes>
					</Router>
				</div>
        <Footer language={language} />
			</div>
		</ProvideConfig>
	);
}

export default App;
