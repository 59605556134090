import React from "react";
import FairDetailsCard from "./FairDetailsCard";
import styled from "styled-components";
import { trackClickToEmail } from "../../DumbleData";

interface Props {
	firstName: string;
	lastName: string;
	email: string;
	language: string;
}

const MyContactsCard = ({ firstName, lastName, email, language }: Props) => {
	return (
		<FairDetailsCard title={language === "en" ? "My Contact" : "Mes personnes-ressources"}>
			<Body>{language === "en" ? "Book Fairs Consultant" : "Conseiller·ère du Festival du Livre"}</Body>
			<Body>
				{firstName} {lastName}
			</Body>
			<RedTextLink
				href={`mailto:${email}`}
				onClick={() => {
					trackClickToEmail();
				}}
			>
				{email}
			</RedTextLink>
		</FairDetailsCard>
	);
};

const Body = styled.div`
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
`;

const RedTextLink = styled.a`
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
	color: #e81111;
	display: inline;
	text-decoration: none;
	&:hover {
		font-style: unset;
		text-decoration: underline;
		cursor: pointer;
	}
	&:focus-visible {
		outline: 2px solid #e81111;
		color: #e81111;
		outline-offset: 1px;
		margin-top: 1px;
	}
`;

export default MyContactsCard;