import React, { useState } from "react";
import axios from "axios";
import styled from "styled-components";
import { trackTopNavLanguageToggle } from "../DumbleData";

// images
import Logo from "../images/nav/logo.svg";
import ProfileIcon from "../images/nav/profile.svg";
import Hamburger from "../images/nav/hamburger.svg";
import HamburgerClose from "../images/nav/nav-close.svg";
import BookfairsEn from "../images/nav/bookfairs_en.svg";
import BookfairsFr from "../images/nav/bookfairs_fr.svg";

interface Props {
	language: string;
	setLanguage: any;
	isLoggedIn: any;
	setIsLoggedIn: any;
}

const Navigation = ({
	language,
	setLanguage,
	isLoggedIn,
	setIsLoggedIn
}: Props) => {
	const [isProfileDropdownOpen, setIsProfileDropdownOpen] = useState<Boolean>(false);
  	const dropdownOpenClassname = "profile-dropdown-open";
	const lhp = document.location.pathname.includes("/bf/") || document.location.pathname.includes("/bf-fr/");
	const printCOA = document.location.pathname.includes("print-coa.html");

	async function handleSignOut(){
			try {
				await  axios({
					method: 'post',
					url: '/api/user/logout',
					data: {}
        		})
				.then((response) => {
					if (response.status === 200) {
						window.sessionStorage.removeItem('bf_profile');
						setIsLoggedIn(false);
						window.location.href = 'https://www2.scholastic.ca/bookfairs/';
					}
				})
				.catch((err) => {
            		console.log(err);
				});
			} catch (err: any) {console.log(err);}
	
	}

	function toggleLanguage(language: string){
		trackTopNavLanguageToggle();
		window.sessionStorage.setItem('bf_lang', language); 
		setLanguage(language);

		// Live homepage redirects
		if(document.location.pathname.includes("/bf/")) {
			document.location.href = document.location.href.replace("/bf/", "/bf-fr/");
		} else if (document.location.pathname.includes("/bf-fr/")) {
			document.location.href = document.location.href.replace("/bf-fr/", "/bf/");
		}
	}

	if (printCOA) {
		return null;
	}
	return(
		<NavBar>
			<TopNav>
				<a className="logo" href="https://www2.scholastic.ca/bookfairs/"><img src={Logo} alt="Scholastic"/></a>
        {(isLoggedIn && !lhp) && <>
				<img
					className={"profile-icon " + (isProfileDropdownOpen ? dropdownOpenClassname : "")}
					src={ProfileIcon}
					alt="Profile"
					onMouseOver={() => setIsProfileDropdownOpen(true)}
					onMouseOut={() => setIsProfileDropdownOpen(false)}/>
				<ProfileDropdown
					className={"profile-dropdown " + (isProfileDropdownOpen ? dropdownOpenClassname : "")}
					onMouseOver={() => setIsProfileDropdownOpen(true)}
					onMouseOut={() => setIsProfileDropdownOpen(false)}>
					<span>{language === "en" ? "Hello" : "Bonjour"}</span>
					<button onClick={() => handleSignOut()}> {language === "en" ? "Sign Out" : "Me déconnecter"}</button>
				</ProfileDropdown>
				<div className={"profile-dropdown-overlay " + (isProfileDropdownOpen ? dropdownOpenClassname : "")}/>
				<img
					className={"menu-icon "  + (isProfileDropdownOpen ? dropdownOpenClassname : "")}
					src={isProfileDropdownOpen ? HamburgerClose : Hamburger}
					onClick={() => isProfileDropdownOpen ? setIsProfileDropdownOpen(false) : setIsProfileDropdownOpen(true)}
					alt="View Menu"/>
          </>}
			</TopNav>
			<LanguageNav>
				{language === 'en' ? <img className="bf-logo-en" src={BookfairsEn} alt="Bookfairs"/> : <img className="bf-logo-fr" src={BookfairsFr} alt="Festival Du Livre"/> }
				<SegmentedButtonContainer>
					<button className={"segmented-button en-selector " + (language === 'en' ? "selected" : "")} onClick={() => toggleLanguage('en')}>
						EN
					</button>
					<button className={"segmented-button fr-selector " + (language === 'fr' ? "selected" : "")} onClick={() => toggleLanguage('fr')}>
						FR
					</button>
				</SegmentedButtonContainer>
			</LanguageNav>
		</NavBar>
	)
};

const NavBar = styled.div`
	position: sticky;
	top: 0;
	z-index: 110;
`;
const TopNav = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 20px;
	background-color: #E81111;
	color: white;
	height: 70px;
	.logo {
		margin-left: 20px;
	}
	.profile-icon {
		padding: 20px;
	}
	.profile-dropdown-overlay,
	.menu-icon {
		display: none;
	}
	.profile-icon.profile-dropdown-open {
		background-color: #B90000;
	}
	.profile-dropdown-overlay.profile-dropdown-open {
		position: fixed;
		display: block;
		top: 70px;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 1;
		background-color: rgba(0,0,0,.5);
	}
	@media (max-width: 959px) {
		justify-content: flex-end;
		.logo {
			position: absolute;
			left: 0;
			right: 0;
			width: fit-content;
			margin: 0 auto;
		}
	}
	@media (max-width: 719px) {
		flex-direction: row-reverse;
		padding: 0;
		height: 45px;
		.profile-icon { display: none; }
		.menu-icon { 
			display: inline;
			padding: 11px 20px;
		}
		.menu-icon.profile-dropdown-open {
			background-color: #B90000;
		}
	}
`;
const ProfileDropdown = styled.div`
	text-transform: uppercase;
	position: absolute;
	top: 70px;
	right: 20px;
	width: 264px;
	padding: 30px 20px;
	display: none;
	flex-direction: column;
	align-items: flex-start;
	gap: 20px;
	background-color: #fff;
	color: #333;
	font-size: 13px;
	font-weight: 700;
	line-height: 15px;
	span {
		width: 100%;
		padding-bottom: 20px;
		border-bottom: 1px solid #D7D7D7;
	}
	button {
		background: none;
		padding: 0;
		border: none;
		color: #333;
		font-size: 16px;
		font-style: normal;
		font-weight: 700;
		line-height: 19px;
		cursor: pointer;
	}
	button:hover {
		text-decoration: underline;
	}
	&.profile-dropdown-open {
		display: flex;
		z-index: 2;
	}
	@media (max-width: 719px) {
		&.profile-dropdown-open {
			span {
				letter-spacing: 1.5px;
				border-bottom: none;
				padding: 0;
			}
			top: 45px;
			right: 0;
			width: 100%;
			box-sizing: border-box;
			padding: 20px;
		}
	}
`;
const LanguageNav = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 15px 40px;
	background-color: white;
	border-bottom: 1px solid #D7D7D7;
	@media (max-width: 719px) {
		flex-direction: column;
		align-items: flex-start;
		gap: 16px;
		padding: 16px 20px;
		.bf-logo-en,
		.bf-logo-fr {
			max-width: 100%;
		}
	}	
`;
const SegmentedButtonContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 3px;
	gap: 3px;
	border-radius: 50px;
	background-color: #919191;
	.segmented-button {
		height: 26px;
		width: 26px;
		border-radius: 50%;
		padding: 0;
		background-color: transparent;
		color: white;
		border: none;
		text-align: center;
		font-family: museo-sans;
		font-size: 11px;
		font-weight: 600;
		line-height: 12px;
		letter-spacing: 2px;
		text-transform: uppercase;
	}
	.segmented-button.selected {
		background-color: #FFFFFF;
		color: #333333;
	}
	.segmented-button.selected:focus {
		outline: 1px solid #0D6EFD;
	}
	.segmented-button:not(.selected):hover,
	.segmented-button:not(.selected):focus {
		background-color: #ECECEC;
		outline: 1px solid #0D6EFD;
		color: #0D6EFD;
	}
`;

export default Navigation;
