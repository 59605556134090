import styled from "styled-components";
import { WhiteButton } from "../Button";
import CloseIcon from "../../images/Close.svg";
import Heading from "../Heading";
import BodyText from "../BodyText";
import { useConfig } from "../../providers/ConfigContext";
import EventCategories from "../HomepageBuilder/AddEvent/EventCategories.json";
import { ReactComponent as AddToCalendarIcon } from "../../images/AddToCalendar-red.svg";
import { ReactComponent as ShareIcon } from "../../images/Share-red.svg";
import useCustomAlert from "../../hooks/HomepageBuilder/useCustomAlert";
import moment from "moment";

interface Props {
	language: string;
	handleModal(): any;
	eventCategory: string;
	eventName: string;
	date: string;
	time: string;
	description?: string;
	url: string;
	handleAddToCalendar(): any;
}

const MoreDetailsModal = ({
	language,
	handleModal,
	eventCategory,
	eventName,
	date,
	time,
	description,
	url,
	handleAddToCalendar,
}: Props) => {
	const { damAssetsOrigin } = useConfig();

	const { activateAlert, alert } = useCustomAlert(
		"Homepage URL is copied to your clipboard"
	);

	const handleCopyToClipboard = async () => {
		const clipboardUrl = language === "en" ? `${process.env.REACT_APP_ENV_BASE_URL}/bf/${url}` : `${process.env.REACT_APP_ENV_BASE_URL}/bf-fr/${url}`;
		try {
			await navigator.clipboard.writeText(
				clipboardUrl
			);
			activateAlert();
		} catch (err) {
			console.error(err);
		}
	};

	const formattedDate = moment(date).format('dddd, MMMM D');

	let imageLink;
	const eventInfo: any = EventCategories.find(
		(event) => event.englishType === eventCategory
	);
	if (eventInfo) {
		imageLink = eventInfo.liveHomepageImage;
	}

	return (
		<StyledOverlay>
			<StyledModal>
				{alert}
				<Wrapper>
					<StyledHeading>{eventName}</StyledHeading>
					<DateInfo>
						{formattedDate}
						<br />
						{time}
					</DateInfo>
					{ description && <StyledBodyText>{description}</StyledBodyText> }
					<CenteredContainer>
						<StyledImg
							src={`${damAssetsOrigin}canadatoolkit/LiveHomepage/Schedule/364x250px/${imageLink}`}
						/>
					</CenteredContainer>
					<ButtonsRow>
						<StyledWhiteButton
							whiteBackground={true}
							className="addToCalendar"
							handleClick={handleAddToCalendar}
						>
							<ButtonContent>
								<AddToCalendar />
								<ButtonBodyText>{language === "en" ? "Add to Calendar" : "Ajouter Au Calendrier"}</ButtonBodyText>
							</ButtonContent>
						</StyledWhiteButton>
						<StyledWhiteButton
							whiteBackground={true}
							handleClick={handleCopyToClipboard}
						>
							<ButtonContent>
								<Share />
								<ButtonBodyText>{language === "en" ? "Copy URL" : "Copier l’URL"}</ButtonBodyText>
							</ButtonContent>
						</StyledWhiteButton>
					</ButtonsRow>
				</Wrapper>
				<StyledCloseButton onClick={handleModal}>
					<img alt="Close the More Details Modal" src={CloseIcon} />
				</StyledCloseButton>
			</StyledModal>
		</StyledOverlay>
	);
};
const Wrapper = styled.div`
	position: relative;
	@media (max-width: 719px) {
		width: 100%;
		padding: 24px;
		box-sizing: border-box;
	}
`;
const ButtonContent = styled.div`
	display: flex;
	flex: row;
	align-items: center;
	justify-content: center;
`;
const ButtonBodyText = styled(BodyText)`
	color: #e81111;
	font-size: 11px;
	font-weight: 600;
	line-height: 12px;
	letter-spacing: 1.5px;
	white-space: nowrap;
	text-transform: uppercase;
	@media (max-width: 519px) {
		font-weight: 500;
	}
`;
const AddToCalendar = styled(AddToCalendarIcon)`
	margin-right: 8px;
`;

const Share = styled(ShareIcon)`
	margin-right: 8px;
`;

const StyledWhiteButton = styled(WhiteButton)`
	border: 1px solid #e81111;
	padding: 10px 20px 10px;
	display: inline-flex;
	height: 40px;
	justify-content: center;
	pointer-events: auto;
	&.addToCalendar {
		padding: 10px 20px 8px;
		margin-right: 16px;
	}
	@media (max-width: 719px) {
		width: 100%;
		&.addToCalendar {
			margin-right: unset;
		}
	}
`;
const ButtonsRow = styled.div`
	margin-top: 26.8px;
	position: relative;
	@media (max-width: 719px) {
		margin-top: 26px;
		margin-left: auto;
		margin-right: auto;
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 16px;
	}
`;

const StyledHeading = styled(Heading)`
	color: #94002a;
	font-size: 32px;
	font-weight: 600;
	line-height: 35px;
	letter-spacing: 1px;
	margin-top: 48px;
	padding-bottom: 0;
	word-wrap: break-word;
	white-space: break-spaces;
	@media (max-width: 719px) {
		font-size: 28px;
		line-height: 32px;
		letter-spacing: 1.5px;
		width: 248px;
		margin-left: auto;
		margin-right: auto;
	}
`;

const CenteredContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`;

const StyledImg = styled.img`
	@media (max-width: 719px) {
		width: 100%;
		height: auto;
	}
`;

const DateInfo = styled(BodyText)`
	font-size: 18px;
	font-weight: 600;
	line-height: 21px;
	color: #94002a;
	margin-top: 6px;
	@media (max-width: 719px) {
		letter-spacing: 0px;
	}
`;
const StyledBodyText = styled(BodyText)`
	font-size: 16px;
	font-weight: 400;
	line-height: 19px;
	letter-spacing: 0px;
	color: #333333;
	margin-top: 13.2px;
	margin-bottom: 22px;
	position: relative;
	word-wrap: break-word;
	white-space: break-spaces;
	@media (max-width: 719px) {
		margin-bottom: 19px;
	}
`;

const StyledOverlay = styled.div`
	z-index: 1001;
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	background: rgba(0, 0, 0, 0.5);
	display: flex;
	justify-content: center;
	align-items: center;
`;

const StyledModal = styled.div`
	position: absolute;
	width: 640px;
	height: auto;
	background: #fff2ea;
	display: inline-flex;
	flex-direction: column;
	align-items: center;
	padding-bottom: 60px;
	@media (max-width: 719px) {
		width: 100%;
		height: 100%;
		padding-bottom: unset;
	}
`;

const StyledCloseButton = styled.button`
	position: absolute;
	right: 20px;
	top: 22px;
	background: none;
	border: none;
	cursor: pointer;
	pointer-events: auto;
	height: 20px;
	@media (max-width: 640px) {
		right: 18px;
		top: 18px;
	}
`;

export default MoreDetailsModal;
