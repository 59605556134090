import React from "react";
import "../../styles/fatfooter.css";
import { useConfig } from "../../providers/ConfigContext";
import { trackBottomNavSocialButton, trackBottomNavReturnToBookFairs, trackBottomNavContactUs, trackBottomNavLink } from "../../DumbleData";

interface Props {
	language: string;
}

const FatFooter = ({ language }: Props) => {
	const { damAssetsOrigin } = useConfig();
	return (
		<div className="fatFooter">
			<footer
				className="cmp-fatFooter site-nav"
				id="enterprise-fatFooter"
			>
				<h2 className="sr-only">Footer</h2>

				<div className="cmp-fatFooter__return">
					{language === "en" ? (
						<a href="https://www2.scholastic.ca/bookfairs/" onClick={trackBottomNavReturnToBookFairs}>
							<img
								src={`${damAssetsOrigin}canadatoolkit/global/chevron-left.svg`}
								alt=""
							/>{" "}
							Return to Book Fairs
						</a>
					) : (
						<a href="https://www2.scholastic.ca/bookfairs/accueil/" onClick={trackBottomNavReturnToBookFairs}>
							<img
								src={`${damAssetsOrigin}canadatoolkit/global/chevron-left.svg`}
								alt=""
							/>{" "}
							Retour aux Festivals du Livre
						</a>
					)}
				</div>

				<div className="cmp-fatFooter__socialLinks">
					<div className="cmp-fatFooter__socialLinksInner">
						{language === "en" ? (
							<a href="https://www2.scholastic.ca/bookfairs/" onClick={trackBottomNavReturnToBookFairs}>
								<img
									src={`${damAssetsOrigin}canadatoolkit/global/LogoFatFooterEN.svg`}
									alt="Book Fairs"
								/>
							</a>
						) : (
							<a href="https://www2.scholastic.ca/bookfairs/accueil/" onClick={trackBottomNavReturnToBookFairs}>
								<img
									src={`${damAssetsOrigin}canadatoolkit/global/LogoFatFooterFR.svg`}
									alt="Festival du Livre"
								/>
							</a>
						)}
						<a
							href="https://www.facebook.com/ScholasticBookFairsCanada/"
							className="cmp-fatFooter__socialLink cmp-fatFooter__socialLinks--facebook"
							target="_blank"
							rel="noopener noreferrer"
							data-element-linkname="footer_socialicon"
							onClick={trackBottomNavSocialButton}
						>
							<img
								src={`${damAssetsOrigin}enterprise/asset.jpg/footer/socialIcons/facebook.svg`}
								alt="facebook"
							/>
						</a>
						<a
							href="https://www.instagram.com/scholasticbookfairs.canada/?hl=en"
							className="cmp-fatFooter__socialLink cmp-fatFooter__socialLinks--instagram"
							target="_blank"
							rel="noopener noreferrer"
							data-element-linkname="footer_socialicon"
							onClick={trackBottomNavSocialButton}
						>
							<img
								src={`${damAssetsOrigin}enterprise/asset.jpg/footer/socialIcons/instagram.svg`}
								alt="instagram"
							/>
						</a>
					</div>
				</div>

				<div className="cmp-fatFooter__links">
					<div className="cmp-fatFooter__column" id="section1">
						<div className="cmp-fatFooter__initialLinks">
							<div
								className="column-link columnOne"
								data-element-linkname="footer_links"
							>
								<h3 className="column-link-text-hr">
									{language === "en"
										? "Contact Us"
										: "Communiquez avec nous"}
								</h3>
							</div>
							<h3 className="column-link-text-p">
								{language === "en" ? (
									<>
										Your Consultant is always happy to help.
										Please reach out via phone or email at:
										1-866-234-7319 and{" "}
										<a href="mailto:bookfairs@scholastic.ca" onClick={trackBottomNavContactUs}>
											bookfairs@scholastic.ca
										</a>
									</>
								) : (
									<>
										Notre équipe de conseiller·ères est
										enthousiaste à l’idée de vous aider.
										Communiquez avec nous par téléphone au
										1-866-234-7319 ou envoyez-nous un
										courriel à l’adresse{" "}
										<a href="mailto:festivaldulivre@scholastic.ca" onClick={trackBottomNavContactUs}>
											festivaldulivre@scholastic.ca
										</a>
									</>
								)}
							</h3>
						</div>
					</div>
					<div className="cmp-fatFooter__column" id="section2">
						<div className="cmp-fatFooter__initialLinks">
							<a
								className="column-link columnTwo"
								onClick={() => trackBottomNavLink("faq")}
								href={
									language === "en"
										? "https://www2.scholastic.ca/bookfairs/faq/"
										: "https://www2.scholastic.ca/bookfairs/questions/"
								}
								data-element-linkname="footer_links"
							>
								<h3 className="column-link-text">
									{language === "en"
										? "FAQ"
										: "Foire aux questions"}
								</h3>
							</a>
							<a
								className="column-link columnTwo"
								onClick={() => trackBottomNavLink("privacy-policy")}
								href={
									language === "en"
										? "https://www.scholastic.ca/aboutscholastic/privacy.php"
										: "https://www.scholastic.ca/editions/lentreprise/confidentialite.php"
								}
								data-element-linkname="footer_links"
							>
								<h3 className="column-link-text">
									{language === "en"
										? "Privacy Policy"
										: "Politique de confidentialité"}
								</h3>
							</a>
							<a
								className="column-link columnTwo"
								onClick={() => trackBottomNavLink("accessibility")}
								href="https://www.scholastic.ca/aboutscholastic/accessibility.php"
								data-element-linkname="footer_links"
							>
								<h3 className="column-link-text">
									{language === "en"
										? "Accessibility"
										: "Accessibilité"}
								</h3>
							</a>
							<a
								className="column-link columnTwo"
								onClick={() => trackBottomNavLink("web-terms-of-use")}
								href={
									language === "en"
										? "https://www.scholastic.ca/aboutscholastic/webterms.php"
										: "https://www.scholastic.ca/editions/lentreprise/modalites-dutilisation.php"
								}
								data-element-linkname="footer_links"
							>
								<h3 className="column-link-text">
									{language === "en"
										? "Web Terms of Use"
										: "Modalités d’utilisation du site Web"}
								</h3>
							</a>
						</div>
					</div>
				</div>
			</footer>
		</div>
	);
};

export default FatFooter;
