import React, { useState } from "react";
import styled from "styled-components";
import axios from "axios";
import { DatePickerComponent } from "../../DatePicker";
import { RedButton, WhiteButton } from "../../Button";
import { ModalContainer } from "../../ModalContainer";
import { convertDateToString } from "../../../utils/DateConversion";
import {
	handleGenericErrors,
	handleGenericSuccess,
} from "../../../utils/ErrorUtility";

interface Props {
	language: string;
	closeModal: () => void;
	setDateChangeSubmitOpen: any;
	currStartDate: Date;
	currEndDate: Date;
}

export const DateChangeModal = ({
	language,
	closeModal,
	setDateChangeSubmitOpen,
	currStartDate,
	currEndDate,
}: Props) => {
	const [startDate, setStartDate] = useState<Date>(currStartDate);
	const [endDate, setEndDate] = useState<Date>(currEndDate);
	const [isStartCalendarOpen, setIsStartCalendarOpen] =
		useState<boolean>(false);
	const [isEndCalendarOpen, setIsEndCalendarOpen] = useState<boolean>(false);
	const [startError, setStartError] = useState<string>();
	const [endError, setEndError] = useState<string>();

	async function handleSend() {
		try {
			await axios({
				method: "put",
				url: `${process.env.REACT_APP_ENV_BASE_URL}/api/user/fairs/current/fair-dates`,
				withCredentials: true,
				data: {
					fairStartDate: convertDateToString(startDate),
					fairEndDate: convertDateToString(endDate),
				},
			})
				.then((response) => {
					if (
						response.status === 200 &&
						response?.data?.message === "Successful"
					) {
						closeModal();
						setDateChangeSubmitOpen(true);
					} else {
						handleGenericSuccess(response);
					}
				})
				.catch((err) => {
					console.log(err);
					handleGenericErrors(err);
				});
		} catch (err: any) {
			console.log(err);
		}
	}

	const getDefaultStartDate = () => {
		const fallStart = new Date(currStartDate.getFullYear(), 7, 1);
		if (currStartDate < fallStart) {
			return new Date(currStartDate.getFullYear(), 0, 1);
		} else {
			return new Date(currStartDate.getFullYear(), 7, 1);
		}
	};

	const getDefaultEndDate = () => {
		const fallStart = new Date(currStartDate.getFullYear(), 7, 1);
		if(currStartDate < fallStart) {
			return new Date(currStartDate.getFullYear(), 5, 30);
		} else {
			return new Date(currStartDate.getFullYear(), 11, 31);
		}
	};

	const handleStartChange = (date: Date) => {
		if (date > endDate) {
			setStartDate(date);
			setStartError(language === "en" ? "Event start date should be before the end date." : "La date de début de l’événement devrait être avant la date de fin.");
		} else if (date < endDate) {
			setStartDate(date);
			setStartError(undefined);
			setEndError(undefined);
		} else {
			setStartDate(date);
		}
	};

	const handleEndChange = (date: Date) => {
		if (date < startDate) {
			setEndDate(date);
			setEndError(language === "en" ? "Event end date should be after the start date." : "La date de fin de l’événement devrait être après la date de début.");
		} else if (date > startDate) {
			setEndDate(date);
			setStartError(undefined);
			setEndError(undefined);
		} else {
			setEndDate(date);
		}
	};

	return (
		<ModalContainer closeModal={closeModal}>
			<StyledContent>
				<StyledHeadSec>
					<StyledHeading>
						{language === "en"
							? "Requesting a Date Change"
							: "Demander un changement de date"}
					</StyledHeading>
				</StyledHeadSec>
				<StyledHeadText>
					{language === "en"
						? "Let us know where you'd like to make changes or get more information. A member of our team will reach out soon."
						: "Indiquez-nous les modifications que vous voulez apporter ou les informations que vous souhaitez obtenir. Un membre de notre équipe vous contactera bientôt."}
				</StyledHeadText>
				<StyledHeadText>
					{language === "en"
						? "By requesting a date change, any saved changes to your customized Book Fair homepage may be reset and a new Book Fair Acknowledgement will be created for the new dates."
						: "En demandant un changement de dates, toutes les modifications enregistrées sur votre page d’accueil personnalisée du Festival du Livre pourraient être réinitialisées et un nouvel Accord du Festival du Livre sera créé pour les nouvelles dates."}
				</StyledHeadText>
				<StyledHeadText>
					{language === "en"
						? "Note: Fair dates must stay within a season. A fall Fair can only occur between 08/01 and 12/31. A spring Fair can only occur between 01/01 and 06/30. Subject to branch availability."
						: "Remarque : Les nouvelles dates du Festival doivent demeurer dans la même saison que celles du Festival initialement prévu. La saison d’automne s’étend du 1ᵉʳ août au 31 décembre, tandis que la saison du printemps va du 1ᵉʳ janvier au 30 juin. Sous réserve de la disponibilité des succursales."}
				</StyledHeadText>
				<StyledDateSection>
					<DateContainer onFocus={() => setIsStartCalendarOpen(true)}>
						<DatePickerComponent
							language={language}
							label={
								language === "en"
									? "Start Date (MM/DD/YYYY)"
									: "Date de début (AAAA-MM-JJ)"
							}
							value={startDate}
							error={startError}
							onChange={handleStartChange}
							defaultStart={getDefaultStartDate()}
							defaultEnd={getDefaultEndDate()}
							onClick={() => setIsStartCalendarOpen(true)}
							isOpen={isStartCalendarOpen}
						/>
					</DateContainer>
					<DateContainer onFocus={() => setIsEndCalendarOpen(true)}>
						<DatePickerComponent
							language={language}
							label={
								language === "en"
									? "End Date (MM/DD/YYYY)"
									: "Date de fin (AAAA-MM-JJ)"
							}
							value={endDate}
							onChange={handleEndChange}
							error={endError}
							defaultStart={getDefaultStartDate()}
							defaultEnd={getDefaultEndDate()}
							isOpen={isEndCalendarOpen}
							onClick={() => setIsEndCalendarOpen(true)}
						/>
					</DateContainer>
				</StyledDateSection>
				<ButtonContainer>
					<StyledWhiteButton handleClick={closeModal}>
						{language === "en" ? "Cancel" : "Annuler"}
					</StyledWhiteButton>
					<StyledRedButton handleClick={handleSend}>
						{language === "en" ? "Send" : "Envoyer"}
					</StyledRedButton>
				</ButtonContainer>
			</StyledContent>
		</ModalContainer>
	);
};

const StyledContent = styled.div`
	background-color: white;
	max-width: 600px;
	padding: 24px;
	box-sizing: border-box;
	@media (max-width: 719px) {
		width: 100%;
		height: 100%;
	}
`;

const StyledHeadSec = styled.div`
	box-sizing: border-box;
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	margin-bottom: 16px;
`;

const StyledHeading = styled.p`
	font-size: 16px;
	line-height: 19px;
	font-weight: 700;
	padding-top: 40px;
	color: #333;
`;

const StyledHeadText = styled.p`
	font-size: 17px;
	line-height: 25px;
	font-weight: 300;
	margin-bottom: 16px;
	color: #333;
`;

const StyledDateSection = styled.div`
	display: flex;
	justify-content: space-evenly;
	gap: 32px;

	@media (max-width: 1279px) {
		flex-direction: column;
		gap: 16px;
	}
`;

const DateContainer = styled.div`
	flex-basis: 50%;
	@media (max-width: 1279px) {
		flex-basis: 100%;
	}
`;

const ButtonContainer = styled.div`
	margin-top: 32px;
	gap: 16px;
	display: flex;
	justify-content: flex-end;
	@media (max-width: 719px) {
		flex-direction: column;
		width: 100%;
	}
`;

const StyledWhiteButton = styled(WhiteButton)`
	width: 130px;
	height: 40px;
	border: 1px solid #e81111;
	@media (max-width: 719px) {
		width: 100%;
	}
`;

const StyledRedButton = styled(RedButton)`
	width: 130px;
	height: 40px;
	border-radius: 100px;
	@media (max-width: 719px) {
		width: 100%;
	}
`;
