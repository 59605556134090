import React from "react";
import styled from "styled-components";
import NavElement from "./NavElement";

interface Props {
	handleNextLocation(nextLocation: string): any;
	isVirtual: boolean;
	isDisabled: boolean;
	fairType: string;
	language: string;
}

const Navigation = ({
	handleNextLocation,
	isVirtual,
	isDisabled,
	fairType,
	language,
}: Props) => {
	return (
		// prettier-ignore
		<>
			{ isDisabled ? "" :
				<Wrapper>
					<NavElement to="/customize/contact" handleNextLocation={handleNextLocation}>{language === 'en' ? 'Contact Information' : 'Vos coordonnées'}</NavElement>
					<NavElement to="/customize/goals" handleNextLocation={handleNextLocation}>{language === 'en' ? "Set or update goal" : "Fixer ou modifier l’objectif de votre Festival"}</NavElement>
					<NavElement to="/customize/information" handleNextLocation={handleNextLocation}>{language === 'en' ? "Fair information" : "Informations sur le Festival"}</NavElement>
					{!isVirtual && fairType !== "after"
						&&
						<>
						<NavElement to="/customize/schedule" handleNextLocation={handleNextLocation}>{language === 'en' ? 'Schedule activities' : 'Planifier des activités'}</NavElement>
						<NavElement to="/customize/volunteer" handleNextLocation={handleNextLocation}>{language === 'en' ? 'Recruit volunteers' : 'Recruter des bénévoles'}</NavElement>
						</>
					}
					<NavElement to="/customize/share" handleNextLocation={handleNextLocation}>{language === 'en' ? 'View & share homepage' : `Partager votre page d’accueil`}</NavElement>
				</Wrapper>
			}
		</>
	);
};

const Wrapper = styled.div`
	position: fixed;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 19px;
	padding-top: 36px;
	padding-left: 47px;
	z-index: 10;
	@media (max-width: 1020px) {
		display: inline-flex;
		flex-direction: row;
		justify-content: space-between;
		padding: 24px 0 24px 40px;
		background-color: #ffffff;
		overflow-x: auto;
		overflow-y: hidden;
		white-space: nowrap;
		width: 100%;
		width: -moz-available; /* WebKit-based browsers */
		width: -webkit-fill-available; /* Mozilla-based browsers */
		width: fill-available;
		/* Hide scrollbar on mobile menu */
		-ms-overflow-style: none; /* Internet Explorer 10+ */
		scrollbar-width: none; /* Firefox */
		&::-webkit-scrollbar {
			display: none; /* Safari and Chrome */
		}
		/* pseudo element for full-width bottom border */
		&::before {
			content: " ";
			position: absolute;
			display: inline-block;
			width: 100%;
			z-index: 1;
			left: 0px;
			right: 0px;
			bottom: 0px;
			border-bottom: 2px solid #e8e8e8;
		}
	}
	@media (max-width: 719px) {
		&::before {
			width: 838px;
		}
	}
	@media (min-width: 1021px) {
		max-width: 153px;
	}
`;

export default Navigation;
