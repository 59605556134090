import React from "react";
import styled from "styled-components";

interface Props {
	title?: string;
	subtitle?: string;
	linkText?: string;
	linkHref?: string;
	className?: string;
	handleLinkClicked?: React.MouseEventHandler<HTMLAnchorElement> | undefined;
	children?: any;
	FullHeight?: boolean;
}
const FairDetailsCard = ({
	children,
	title,
	subtitle,
	linkText,
	linkHref,
	FullHeight,
	className,
	handleLinkClicked,
}: Props) => {
	return (
		<Container FullHeight={FullHeight}>
			<GrayHeaderBar linkText={linkText}>
				<HeaderTextContainer linkText={linkText}>
					<LeftFlexContainer>
						<HeaderText>{title}</HeaderText>
						<Subtitle>{subtitle}</Subtitle>
					</LeftFlexContainer>
					{linkText && (
						<>
							{linkHref || handleLinkClicked ? (
								<HeaderLink
									tabIndex={1}
									href={linkHref}
									onClick={handleLinkClicked}
									className={className}
								>
									{linkText}
								</HeaderLink>
							) : (
								<HeaderSubText tabIndex={1}>
									{linkText}
								</HeaderSubText>
							)}
						</>
					)}
				</HeaderTextContainer>
			</GrayHeaderBar>
			<Body>{children}</Body>
		</Container>
	);
};

interface GrayHeaderBarProps {
	linkText?: string;
}

export const GrayHeaderBar = styled.div<GrayHeaderBarProps>`
	display: flex;
	flex-direction: row;
	align-items: center;
	box-sizing: border-box;
	width: 100%;
	background: #f4f4f4;
	border-bottom: 1px solid #d7d7d7;
	height: 51px;
	@media (max-width: 475px) {
		height: ${(props) => (props.linkText ? "74px" : "51px")};
	}
`;

const Subtitle = styled.div`
	font-weight: 500;
	font-size: 11px;
	line-height: 12px;
	color: #000000;
	padding-top: 3px;
`;

interface HeaderTextContainerProps {
	linkText?: string;
}

const HeaderTextContainer = styled.div<HeaderTextContainerProps>`
	display: flex;
	width: 100%;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
	margin: 0px 16px;
	@media (max-width: 475px) {
		flex-direction: ${(props) => (props.linkText ? "column" : "row")};
		align-items: flex-start;
		row-gap: 8px;
	}
`;

const HeaderText = styled.div`
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	color: #333333;
	line-height: 19px;
`;

const HeaderLink = styled.a`
	font-style: normal;
	color: #e81111;
	font-weight: 700;
	font-size: 13px;
	line-height: 15px;
	text-align: right;
	letter-spacing: 1.5px;
	text-transform: uppercase;
	cursor: pointer;
	
	&:hover {
		font-style: unset;
		text-decoration: underline;
		cursor: pointer;
	}
	&:focus-visible {
		outline: 2px solid #e81111;
		color: #e81111;
		outline-offset: 1px;
		margin-top: 1px;
	}
`;

const HeaderSubText = styled.div`
	font-style: normal;
	color: #333333;
	font-weight: 700;
	font-size: 13px;
	line-height: 15px;
	text-align: right;
	letter-spacing: 1.5px;
	text-transform: uppercase;
`;

const LeftFlexContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 16px;
`;

const Body = styled.div`
	margin: 24px;
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
	color: #333333;
	overflow-wrap: anywhere;
`;

interface ContainerProps {
	FullHeight?: boolean;
}

const Container = styled.div<ContainerProps>`
	background: #ffffff;
	border: 1px solid #d7d7d7;
	width: 100%;
	font-family: museo-sans;
	margin-top: 48px;
	height: ${(props) => (props.FullHeight ? "calc(100% - 48px)" : "auto")};
`;

// Common Styles

export const StyledNumber = styled.div`
	font-weight: 900;
	font-size: 36px;
	line-height: 36px;
`;

export const NumberCaption = styled.div`
	font-weight: 700;
	font-size: 13px;
	line-height: 15px;
	margin-bottom: 4px;
`;

export const StyledDisableButton = styled.div`
	font-weight: 700;
	font-size: 11px;
	line-height: 12px;
	letter-spacing: 1.5px;
	text-transform: uppercase;
	&:hover {
		font-style: unset;
		text-decoration: underline;
		cursor: pointer;
	}
`;

export default FairDetailsCard;