import styled from "styled-components";
import { RedButton, WhiteButton } from "../Button";
import CloseIcon from "../../images/Close.svg";
import Heading from "../Heading";
import LimitedTextArea from "../LimitedTextArea";
import TextInput from "../TextInput";
import { useState } from "react";
import { validateEmail, validatePhoneNumber } from "../../utils/InputValidations";
import axios from "axios";

interface Props {
	handleModal(): any;
	contactData: {
		email: string;
	};
	fairInfoData: {
		schoolName: string;
		startDate: Date;
		endDate: Date;
	};
	fairId: number;
	language: string;
}

const ContactChairpersonForm = ({
	handleModal,
	fairId,
	contactData,
	fairInfoData,
	language
}: Props) => {
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [email, setEmail] = useState("");
	const [phone, setPhone] = useState("");
	const [message, setMessage] = useState("");
	const [firstNameError, setFirstNameError] = useState<string>();
	const [lastNameError, setLastNameError] = useState<string>();
	const [emailError, setEmailError] = useState<string>();
	const [phoneNumberError, setPhoneNumberError] = useState<string>();
	const [messageError, setMessageError] = useState<string>();

	const dataValidate = () => {
		let valid = true;
		if (!firstName) {
			setFirstNameError(language === 'en' ? "Please enter a first name." : "Ce champ est obligatoire.");
			valid = false;
		} else {
			setFirstNameError(undefined);
		}
		
		if (!lastName) {
			setLastNameError(language === 'en' ? "Please enter a last name." : "Ce champ est obligatoire.");
			valid = false;
		} else {
			setLastNameError(undefined);
		}
		if (!validateEmail(email)) {
			setEmailError(language === 'en' ? "Please enter a valid email address." : "Veuillez inscrire une adresse courriel valide.");
			valid = false;
		} else {
			setEmailError(undefined);
		}
		if (phone.length) {
			if (!validatePhoneNumber(phone)) {
				setPhoneNumberError(language === 'en' ? "Please enter a valid phone number." : "Veuillez inscrire un numéro de téléphone à 10 chiffres.");
				valid = false;
			} else {
				setPhoneNumberError(undefined);
			}
		}
		if (!message) {
			setMessageError(language === 'en' ? "Please enter a message" : "Ce champ est obligatoire.");
			valid = false;
		} else {
			setMessageError(undefined);
		}
		return valid;
	};

	const sendMessage = async () => {
		if (dataValidate()) {
			try {
				await axios.post(
					`${process.env.REACT_APP_ENV_BASE_URL}/api/public/fairs/${fairId}/emails/parent-to-chairperson`,
					{
						fairId: fairId.toString(),
						firstName: firstName,
						lastName: lastName,
						email: email,
						phone: phone,
						textMessage: message,
						language: language === "en" ? "english" : "french"
					},
					{ withCredentials: true }
				);
			} catch (err) {
				console.error(err);
			}
			handleModal();
		}
	};

	return (
		<StyledOverlay>
			<StyledModal>
				<StyledCloseButton onClick={handleModal}>
					<img
						alt="Close contact chairperson modal"
						src={CloseIcon}
					/>
				</StyledCloseButton>

				<Content>
					<StyledHeading>{ language === 'en' ? 'Contact Host' : 'Contacter Le/La Responsable' }</StyledHeading>
					<InputContainer>
						<FlexRow>
							<InlineElement>
								<TextInput
									label={language === "en" ? "First name" : "Prénom"}
									value={firstName}
									onChange={setFirstName}
									error={firstNameError}
								/>
							</InlineElement>
							<InlineElement>
								<TextInput
									label={language === "en" ? "Last Name" : "Nom"}
									value={lastName}
									onChange={setLastName}
									error={lastNameError}
								/>
							</InlineElement>
						</FlexRow>
						<FlexRow>
							<InlineElement>
								<TextInput
									label={language === "en" ? "Email Address" : "Courriel"}
									value={email}
									onChange={setEmail}
									error={emailError}
								/>
							</InlineElement>
							<InlineElement>
								<TextInput
									label={language === "en" ? "Phone Number (optional)" : "Numéro de téléphone (facultatif)"}
									value={phone}
									onChange={setPhone}
									error={phoneNumberError}
								/>
							</InlineElement>
						</FlexRow>
						<FlexRow>
							<StyledLimitedTextArea
								language={language}
								label={language === "en" ? "Your Message" : "Votre message"}
								value={message}
								onChange={setMessage}
								error={messageError}
								limit={250}
							/>
						</FlexRow>
					</InputContainer>
					<ButtonsRow>						
						<StyledWhiteButton handleClick={handleModal}>
							{language === "en" ? "Cancel" : "Annuler"}
						</StyledWhiteButton>
						<StyledRedButton handleClick={sendMessage}>
							{language === "en" ? "Send Message" : "Envoyer"}
						</StyledRedButton>
					</ButtonsRow>
				</Content>
			</StyledModal>
		</StyledOverlay>
	);
};

const InputContainer = styled.div`	
	display: flex;
	flex-direction: column;
	gap: 16px;
	@media (max-width: 519px) {
		width: 100%;
	}
`;
const StyledRedButton = styled(RedButton)`
	margin-left: 16px;
	@media (max-width: 519px) {
		width: 100%;
		margin-left: unset;
	}
`;
const StyledWhiteButton = styled(WhiteButton)`
	width: 120px;
	border: 1px solid red;
	@media (max-width: 519px) {
		width: 100%;
	}
`;
const ButtonsRow = styled.div`
	margin-top: 29px;
	display: inline-flex;
	width: 100%;
	justify-content: flex-end;
	@media (max-width: 519px) {
		flex-direction: column;
		align-items: center;
		gap: 16px;
	}
`;

const Content = styled.div`
	padding: 24px;
	margin-top: 66px;
	@media (max-width: 519px) {
		padding: 30px;
		height: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
	}
`;
const StyledHeading = styled(Heading)`
	font-size: 32px;
	line-height: 35px;
	font-weight: 500;
	text-transform: uppercase;
	margin-bottom: 32px;
	display: block;
`;

const StyledLimitedTextArea = styled(LimitedTextArea)`
	textarea {
		height: 70px;
	}
`;

const StyledOverlay = styled.div`
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	z-index: 170;
	background: rgba(0, 0, 0, 0.5);
	display: flex;
	justify-content: center;
	align-items: center;
`;

const StyledModal = styled.div`
	background: #ffffff;
	position: relative;
	width: 640px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	overflow-y: auto;
	overflow-x: hidden;
	@media (max-width: 519px) {
		width: 100%;
		height: 100%;
	}
`;

const FlexRow = styled.div`
	display: inline-flex;
	text-align: left;
	width: 100%;
	gap: 40px;
	@media (max-width: 519px) {
		flex-direction: column;
		gap: 7px;
	}
`;

const InlineElement = styled.div`
	flex: 1 auto;
	width: calc(50% - 20px);
	@media (max-width: 519px) {
		width: 100%;
	}
`;

const StyledCloseButton = styled.button`
	position: absolute;
	right: 20px;
	top: 22px;
	background: none;
	border: none;
	cursor: pointer;
	height: 20px;
	z-index: 1;
	@media (max-width: 519px) {
		right: 18px;
		top: 18px;
	}
`;

export default ContactChairpersonForm;
