import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Page } from "../Page";
import ShareIcon from "../../../images/ViewAndShare.svg";
import Heading from "../../Heading";
import PageIcon from "../PageIcon";
import BodyText from "../../BodyText";
import TextInput from "../../TextInput";
import { RedButton, WhiteButton } from "../../Button";
import TextLink from "../../TextLink";
import useCustomAlert from "../../../hooks/HomepageBuilder/useCustomAlert";
import FacebookShareButton from "../FacebookShareButton";
import TwitterShareButton from "../TwitterShareButton";
import SaveChangesModal from "../SaveChangesModal";
import Prompt from "../Prompt";
import { validateURL } from "../../../utils/InputValidations";
import { trackCopyHomepageUrlEnglish, trackCopyHomepageUrlFrench, trackCustomizeUrlLink, trackDiscardChanges, trackViewLiveHomepageUrlEnglish, trackViewLiveHomepageUrlFrench, updatePageType, updateUserLoginStatus, updateUserFairId, updateUserAccountId } from "../../../DumbleData";
import {
	handleGenericErrors,
	handleGenericSuccess,
} from "../../../utils/ErrorUtility";

interface Props {
	language: string;
  	builderNavDisabled: boolean;
	setBuilderNavDisabled: any;
	builderNextLocation: string;
	setBuilderNextLocation: any;
}

export const ShareHomepagePage = ({ language, builderNavDisabled, 
	setBuilderNavDisabled,
	builderNextLocation,
	setBuilderNextLocation }: Props) => {
	const [customUrl, setCustomUrl] = useState<string>("");
	const { activateAlert, alert } = useCustomAlert(
		language === "en" ? "Homepage URL is copied to your clipboard" : "L'URL de la page d’accueil est copiée dans votre presse-papiers"
	);
	const navigate = useNavigate();
	const [customUrlError, setCustomUrlError] = useState<string>("");
	const [cachedUrl, setCachedUrl] = useState<string>("");
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const [saveChangesModalOpen, setSaveChangesModalOpen] = useState(false);
	const [allChangesSaved, setAllChangesSaved] = useState(true);
	const [allowNavigation, setAllowNavigation] = useState(false);
	const firstRender = useRef(true);
	const fromCancel = useRef(false);

	const englishErrorText = "Please enter a unique web address.";
	const frenchErrorText = "Veuillez inscrire une adresse Web (URL) unique.";


	// get request
	useEffect(() => {
		window.scrollTo(0, 0);
		const getSharePageData = async () => {
			try {
				await axios
					.get(
						`${process.env.REACT_APP_ENV_BASE_URL}/api/user/fairs/current/homepage/fair-url`,
						{
							withCredentials: true,
						}
					)
					.then((response) => {
						if (response.status === 200) {
							const custom = response.data.fairUrl.english
								.split("/")
								.pop() || "";
						if ( custom ) {
							setCustomUrl(custom);
							setCachedUrl(custom);
							setAllChangesSaved(true);
							setAllowNavigation(true);
						}
			 			updateUserLoginStatus(true);
			 			updateUserFairId(response.data.fairId);
			 			updateUserAccountId(response.data.bookfairAccountId)
						updatePageType("homepage-builder");
						} else {
							handleGenericSuccess(response);
						}
					})
					.catch((err) => {
						handleGenericErrors(err);
					});
			} catch (err: any) {
				handleGenericErrors(err);
			}
		};
		getSharePageData();
	}, []);

	useEffect(() => {
		if (customUrlError) {
			const errorMsg = language === "en" ? englishErrorText : frenchErrorText;
			setCustomUrlError(errorMsg);
		}
	}, [language, customUrlError, setCustomUrlError]);

	const handleOpenCustomizeWindow = () => {
		trackCustomizeUrlLink();
		setIsOpen(true);
		setCachedUrl(customUrl);
	};

	const cancel = () => {
		fromCancel.current = true;
		setCustomUrl(cachedUrl);
		setCustomUrlError("");
		setIsOpen(false);
		setAllowNavigation(false);
		setAllChangesSaved(true);
	};

	const handleInput = (e: string) => {
		setCustomUrl(e.replace(/[^a-z0-9]/ig, ""));
		setAllChangesSaved(false);
	};

	const handleCopyToClipboard = async (which: string) => {
		try {
			await navigator.clipboard.writeText(
				`${window.location.origin}/${
					which === "en" ? "bf" : "bf-fr"
				}/${cachedUrl}`
			);
			activateAlert();
			which === "en" ? trackCopyHomepageUrlEnglish() : trackCopyHomepageUrlFrench();
		} catch (err) {
			console.error(err);
		}
	};

	const saveUrl = () => {
		if (!customUrl.length) {
			const errorMsg = language === "en" ? englishErrorText : frenchErrorText;
			setCustomUrlError(errorMsg);
			setSaveChangesModalOpen(false);
		} else if (!validateURL(customUrl)) {
			setCustomUrlError("Only alphanumeric characters are allowed.");
			setSaveChangesModalOpen(false);
		} else {
			setIsOpen(false);
			submitCustomUrl();
		}
	};

	const submitCustomUrl = async () => {
		if (customUrl !== cachedUrl) {
			try {
				await axios
					.put(
						`${process.env.REACT_APP_ENV_BASE_URL}/api/user/fairs/current/homepage/fair-url`,
						{
							fairUrl: customUrl,
						},
						{ withCredentials: true }
					)
					.then((response) => {
						if (response.status === 200) {
							setCachedUrl(customUrl);
						} 
						else if(response.status === 208) {
							setCustomUrlError(language === "en" ? englishErrorText : frenchErrorText);
							// close save changes modal if address is not unique
							setSaveChangesModalOpen(false);
							setIsOpen(true);
						}
						else {
							handleGenericSuccess(response);
						}
					})
					.then(() => {
						setAllChangesSaved(true);
					});
			} catch (err: any) {
				if (err.response?.status === 400) {
					setCustomUrlError(language === "en" ? englishErrorText : frenchErrorText);
					// close save changes modal if address is not unique
					setSaveChangesModalOpen(false);
					setIsOpen(true);
				} else {
					console.error(err);
					setAllChangesSaved(true);
					handleGenericErrors(err);
				}
			}
		}
	};


	//Tracks changes made
	useEffect(() => {
		if (firstRender.current) {
			firstRender.current = false;
		} else if (fromCancel.current) {
			fromCancel.current = false;
		} else {
			setAllChangesSaved(false);
		}
	}, [customUrl]);

  //Allows for navigation to next page after clicking discard changes on save changes modal
	useEffect(() => {
		if (allChangesSaved && allowNavigation) {
			navigate(builderNextLocation);
		}
	}, [allChangesSaved,
		saveChangesModalOpen,
		allowNavigation,
		navigate,
		builderNextLocation]);



	return (
		<>
			<StyledInformationPage>
				{alert}
				<PageIcon
					src={ShareIcon}
					alt="Share Icon"
					className="shareHP"
				/>
				<StyledHeading>
					{language === "en"
						? "Share Your Homepage"
						: "Partager votre page d’accueil"}
				</StyledHeading>
				<StyledBodyTextSubheading>
					{language === "en"
						? `Now that you've customized your Book Fair homepage, it's ready to share! Don't forget to copy your homepage link to paste into all your Book Fair communications.`
						: `Maintenant que vous avez personnalisé la page d’accueil de votre Festival du Livre, vous pouvez la partager! N’oubliez pas de copier le lien de votre page d’accueil afin de le coller dans toutes vos communications relatives à votre Festival.`}
				</StyledBodyTextSubheading>
				<Wrapper>
					<FlexRow className="customUrl">
						{!isOpen && <URLText>https://bookfairs.scholastic.ca/{language === "en" ? "bf" : "bf-fr"}/{customUrl}</URLText>}
						
						{isOpen && (
							<>
							<InlineElement>
								<URLText>
									https://bookfairs.scholastic.ca/{language === "en" ? "bf" : "bf-fr"}/
								</URLText>
							</InlineElement>
							<InlineElement>
								<StyledTextInput
									label=""
									value={customUrl}
									onChange={handleInput}
									fontSize={"13px"}
									error={
										customUrlError.length
											? customUrlError
											: undefined
									}
								/>
							</InlineElement>
							</>
						)}
					</FlexRow>
					{!isOpen && (
						<StyledTextLink
							className="customizeLink"
							handleClick={handleOpenCustomizeWindow}
						>
							{language === "en" ? "Customize URL" : "Personnaliser l'URL"}
						</StyledTextLink>
					)}
					{isOpen && (
						<FlexRow>
							<InlineElement>
								<StyledTextLink handleClick={saveUrl}>
									{language === "en" ? "Save" : "Enregistrer"}
								</StyledTextLink>
							</InlineElement>
							<InlineElement>
								<StyledTextLink handleClick={cancel}>
									{language === "en" ? "Cancel" : "Annuler"}
								</StyledTextLink>
							</InlineElement>
						</FlexRow>
					)}
					<Row>
						<FlexRow className="langHeading">
							<StyledLangTextSubheading>
								{language === "en" ? "English Homepage" : "Page d’accueil en anglais"}
							</StyledLangTextSubheading>
						</FlexRow>
						<FlexRow className="buttons">
							<InlineElement $dir="right">
								<StyledWhiteButton
									whiteBackground={true}
									handleClick={() => handleCopyToClipboard("en")}
								>
									{language === "en" ? "Copy Homepage URL" : "Copier l’URL de la page d’accueil"}
								</StyledWhiteButton>
							</InlineElement>
							<InlineElement>
								<StyledRedButton
									handleClick={() => {
										// remove google chrome save changes modal
										window.onbeforeunload = null;
										if (!allChangesSaved) {
											setSaveChangesModalOpen(
												!saveChangesModalOpen
											);
										} else {
											window.open(
												`/bf/${cachedUrl}`,
												"_blank"
											);
											trackViewLiveHomepageUrlEnglish();
										}
									}}
								>
									{language === "en" ? "View Live Homepage" : "Voir la page d’accueil en ligne"}
								</StyledRedButton>
							</InlineElement>
						</FlexRow>
						<FlexRow>
							<InlineElement $dir="right">
								<FacebookShareButton
									homepageURL={`${window.location.origin}/bf/${cachedUrl}`}
									pageName="shareHomepage"
									language={"en"}
								/>
							</InlineElement>
							<InlineElement>
								<TwitterShareButton
									customUrl={cachedUrl}
									pageName="shareHomepage"
									language={"en"}
								/>
							</InlineElement>
						</FlexRow>
					</Row>
					<Row>
						<FlexRow className="langHeading">
							<StyledLangTextSubheading>
								{language === "en" ? "French Homepage" : "Page d’accueil en français"}
							</StyledLangTextSubheading>
						</FlexRow>
						<FlexRow className="buttons">
							<InlineElement $dir="right">
								<StyledWhiteButton
									whiteBackground={true}
									handleClick={() => handleCopyToClipboard("fr")}
								>
									{language === "en" ? "Copy Homepage URL" : "Copier l'URL de la page d’accueil"}
								</StyledWhiteButton>
							</InlineElement>
							<InlineElement>
								<StyledRedButton
									handleClick={() => {
										// remove google chrome save changes modal
										window.onbeforeunload = null;
										if (!allChangesSaved) {
											setSaveChangesModalOpen(
												!saveChangesModalOpen
											);
										} else {
											window.open(
												`/bf-fr/${cachedUrl}`,
												"_blank"
											);
											trackViewLiveHomepageUrlFrench();

										}
									}}
								>
									{language === "en" ? "View Live Homepage" : "Voir la page d'accueil en ligne"}
								</StyledRedButton>
							</InlineElement>
						</FlexRow>
						<FlexRow>
							<InlineElement $dir="right">
								<FacebookShareButton
									homepageURL={`${window.location.origin}/bf-fr/${cachedUrl}`}
									pageName="shareHomepage"
									language={"fr"}
								/>
							</InlineElement>
							<InlineElement>
								<TwitterShareButton
									customUrl={cachedUrl}
									pageName="shareHomepage"
									language={"fr"}
								/>
							</InlineElement>
						</FlexRow>
					</Row>
				</Wrapper>
			</StyledInformationPage>

			<Prompt
				message={() => {
					setSaveChangesModalOpen(true);
				}}
				when={!allChangesSaved}
			/>
			<SaveChangesModal
				language={language}
				saveAndPublish={() => {
					saveUrl();
					setAllowNavigation(true);
				}}
				discardChanges={() => {
					setAllChangesSaved(true);
          			setBuilderNavDisabled(false);
					setAllowNavigation(true);
					trackDiscardChanges();
				}}
				modalIsOpen={saveChangesModalOpen}
				handleModal={() => {
					setSaveChangesModalOpen(!saveChangesModalOpen);
				}}
			/>
		</>
	);
};

/*
	Page Specific Styles
*/

const StyledHeading = styled(Heading)`
	padding-bottom: 14px;
`;
const StyledBodyTextSubheading = styled(BodyText)`
	margin-bottom: 20px;
	max-width: 294px;
	font-weight: 400;
	font-size: 14px;
	line-height: 18px;
`;
const StyledLangTextSubheading = styled(BodyText)`
	font-weight: 800;
	font-size: 14px;
	line-height: 18px;
	text-align: center;
	width: 100%;
`;
const URLText = styled(BodyText)`
	font-size: 13px;
	font-style: normal;
	font-weight: 400;
	line-height: 15px;
	letter-spacing: 0px;
	margin: 0 auto;
`;
const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	max-width: 600px;
`;
const StyledRedButton = styled(RedButton)``;

const StyledWhiteButton = styled(WhiteButton)`
	border: 1px solid #e81111;
	border-radius: 100px;
`;

const StyledInformationPage = styled(Page)`
	padding-bottom: 95px;
	@media (max-width: 719px) {
		padding-bottom: 103px;
	}
	@media (max-width: 530px) {
		padding-bottom: 74px;
	}
`;
const Row = styled("div")`
	border: 1px solid #949494;
	border-radius: 15px;
	margin-bottom: 24px;
	width: 100%;
	padding-bottom: 32px;
	padding-top: 32px;
`;

const FlexRow = styled.div`
	display: flex;
	gap: 16px;
	&.buttons {
		margin: 16px 0 24px 0;
		@media (max-width: 719px) {
			display: flex;
			flex-direction: column;
			width: 100%;
			gap: 25px;
			align-items: center;
		}
	}
	&.customUrl {
		gap: 3px;
		align-items: center;
		margin: 10px 0px 20px;
		width: 450px;
		max-width: 100%;
	}
	&.langHeading {
		gap: 3px;
		align-items: center;
	}
`;

const InlineElement = styled.div<{ $dir?: string }>`
	display: inline-flex;
	width: 50%;
	justify-content: ${(props) =>
		props.$dir === "right" ? "flex-end" : "flex-start"};
	@media (max-width: 719px) {
		.buttons & {
			width: 100%;
			justify-content: center;
		}
	}
`;

const StyledTextLink = styled(TextLink)`
	margin: 7px 0 38px 0;
	&.customizeLink {
		margin: 12px 0 54px 0;
		&:hover {
			margin-bottom: 50px;
		}
		&:focus {
			margin-bottom: 48px;
		}
	}
	&:hover {
		margin-bottom: 34px;
	}
	&:focus {
		margin-bottom: 32px;
	}
`;

const StyledTextInput = styled(TextInput)`
	font-family: museo-sans;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 15px;
	letter-spacing: 0px;
	text-align: left;
	margin-bottom: 8px;
	& #textInputErrorLabel {
		position: absolute;
		top: 40px;
		font-size: 13px;
	}
`;

export default ShareHomepagePage;
