import React from "react";
import styled from "styled-components";
import { useOutletContext } from "react-router-dom";
import Heading from "../../Heading";
import { RedButton } from "../../Button";
import { IconList } from "../../HomepageBuilder/IconList";
import BodyText from "../../BodyText";
import { Page } from "../../HomepageBuilder/Page";
import { trackHomepageBuilderContinueButton } from "../../../DumbleData";

interface Props {
	language: string;
}

export const CustomizePage = ( {language} : Props ) => {
	const { after } = useOutletContext<any>();

	return (
				<>
						{language === 'en' ? 
							<StyledCustomizePage>
								<StyledHeading>
									<Heading>Customize Your Homepage</Heading>
								</StyledHeading>
								<StyledBodyText>
									<BodyText>
										You're just a few clicks away from
										customizing your Book Fair homepage, which
										you'll use in promoting and sharing key
										information about your Fair. Update each
										section of your homepage and publish when
										you're done.
									</BodyText>
									<StyledGreyBodyText>
										Note: Any customized information does not have an effect on the physical Fair.
									</StyledGreyBodyText>
								</StyledBodyText>
								<IconList afterFair={after} language={language} />
								<a href="/customize/contact">
									<StyledRedButton handleClick={trackHomepageBuilderContinueButton}>Continue</StyledRedButton>
								</a>
							</StyledCustomizePage> :
							<StyledCustomizePage>
								<StyledHeading>
									<Heading>Personnaliser votre page d’accueil</Heading>
								</StyledHeading>
								<StyledBodyText>
									<BodyText>
										Il suffit de quelques clics pour personnaliser la page
										d’accueil de votre Festival du Livre, que vous utiliserez 
										pour promouvoir et partager des informations sur votre 
										Festival. Mettez à jour chaque section de votre page 
										d’accueil, puis publiez-la lorsque vous avez terminé.
									</BodyText>
									<StyledGreyBodyText>
										Remarque : Les informations personnalisées n'ont pas 
										d’effet sur le Festival en personne.
									</StyledGreyBodyText>
								</StyledBodyText>
								<IconList afterFair={after} language={language} />
								<a href="/customize/contact">
									<StyledRedButton>Continuer</StyledRedButton>
								</a>
							</StyledCustomizePage>
						}
				</>
	);
};

const StyledHeading = styled.div`
	p { 
		margin: 0;
		padding-bottom: 0;
	}
`;
const StyledRedButton = styled(RedButton)`
	width: 150px;
	@media (max-width: 719px) {
		width: 100%;
	}
`;
const StyledBodyText = styled.div`
	max-width: 349px;
	margin-top: 8px;
	p { 
		margin-top: 0; 
		margin-bottom: 8px;
	}
`;
const StyledGreyBodyText = styled(BodyText)`
	color: #666666;
`
const StyledCustomizePage = styled(Page)`
	padding-top: 52px;
	padding-bottom: 48px;

	@media (max-width: 959px) {
		padding-top: 80px;
	}

	@media (max-width: 719px) {
		padding: 48px 20px;
		a {
			width: 100%;
		}
	}
`;
