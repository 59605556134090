// Converts a Date object to a date-string formatted: YYYY-MM-DD
const convertDateToString = (dateObj: Date) => {
	const year = dateObj.getFullYear();
	const month = (dateObj.getMonth() + 1).toString().padStart(2, "0");
	const day = dateObj.getDate().toString().padStart(2, "0");
	return `${year}-${month}-${day}`;
};

// Converts a Date object to a date-string formatted: MM/DD/YYYY
const convertDateToMDY = (dateObj: Date) => {
	const year = dateObj.getFullYear();
	const month = (dateObj.getMonth() + 1).toString().padStart(2, "0");
	const day = dateObj.getDate().toString().padStart(2, "0");
	return `${month}/${day}/${year}`;
};

// Converts date object into year-month-day
// ex. date 12-30-2022 -> 2022-12-30
const convertDateToYMD = (dateObj: Date) => {
	const year = dateObj.getFullYear();
	const month = (dateObj.getMonth() + 1).toString().padStart(2, "0");
	const day = dateObj.getDate().toString().padStart(2, "0");
	return `${year}-${month}-${day}`;
};

// Converts a Date object to a date-string formatted: MM/DD/YY
const convertDateToMDYShort = (dateObj: Date) => {
	const year = dateObj.getFullYear().toString().substring(2);
	const month = (dateObj.getMonth() + 1).toString();
	const day = dateObj.getDate().toString();
	return `${month}/${day}/${year}`;
};

// Converts a date-string (YYYY-MM-DD Format) into a Date object
const convertStringToDate = (dateStr: string) => {
	const [year, month, day] = dateStr.split("-");
	const newDate = new Date(
		parseInt(year),
		parseInt(month) - 1,
		parseInt(day)
	);
	return newDate;
};

// Converts a date-string (YYYY-MM-DD Format) into a Date object
const convertYMDStringToDate = (dateStr: string) => {
	const [year, month, day] = dateStr.split("-");
	const newDate = new Date(
		parseInt(year),
		parseInt(month) - 1,
		parseInt(day)
	);
	return newDate;
};

// Converts a date-string (YYYY-MM-DD Format) into a string MM/DD/YY
const convertYMDToMDY = (dateStr: string | undefined) => {
	if (typeof dateStr === "string") {
		const [year, month, day] = dateStr.split("-");
		const MDY = `${month}/${day}/${year}`;
		return MDY;
	}
};

// Converts a date-string (MM-DD-YYYY Format) into a string
const convertMDYStringToDate = (dateStr: string) => {
	const [month, day, year] = dateStr.split("-");
	const newDate = new Date(
		parseInt(year),
		parseInt(month) - 1,
		parseInt(day)
	);
	return newDate;
};

// Converts date object into short month name and day
// ex. date 09-01-2022 -> Sept. 1
const convertDateToMonthDay = (dateObj: Date) => {
	const monthNameShort = dateObj.toLocaleString("en-US", { month: "short" });
	const dayNum = dateObj.getDate();
	return `${monthNameShort}. ${dayNum}`;
};

// Converts date object into short month name, day and year
// ex. date 09-01-2022 -> Sept 1, 2022
const convertDateToMonthDayYear = (dateObj: Date) => {
	const monthNameShort = dateObj.toLocaleString("en-US", { month: "short" });
	const day = dateObj.getDate();
	const year = dateObj.getFullYear();
	return `${monthNameShort} ${day}, ${year}`;
};

// Creates date object from string containing date (Year-Month-Day) and string containing time (H:M:S)
const combineStringsToSingleDate = (
	inputDate: string,
	inputTime: string | undefined
) => {
	const datetime = convertStringToDate(inputDate);
	if (inputTime !== undefined) {
		const time = inputTime?.split(":");
		datetime.setHours(Number(time[0]));
		datetime.setMinutes(Number(time[1]));
		datetime.setSeconds(Number(time[2]));
	}
	return datetime;
};

const convertStringArrayToDateArray = (stringArray: Array<string>) => {
	let dateArray: Date[] = [];
	for (let x in stringArray) {
		dateArray.push(convertStringToDate(stringArray[x]));
	}
	return dateArray;
};

export {
	convertDateToString,
	convertStringToDate,
	combineStringsToSingleDate,
	convertDateToMDY,
	convertDateToYMD,
	convertDateToMDYShort,
	convertDateToMonthDay,
	convertYMDStringToDate,
	convertMDYStringToDate,
	convertYMDToMDY,
	convertDateToMonthDayYear,
	convertStringArrayToDateArray,
};
