import React, { useEffect, useState } from "react";
import FairDetailsCard from "./FairDetailsCard";
import styled from "styled-components";
import RedTextButton from "../RedTextButton";
import { WhiteButton } from "../Button";
import "../../styles/misc.css";
import { trackCopyUrl, trackCustomizeHomepage, trackViewHomepage } from "../../DumbleData";

type Props = {
	homepageUrl: any;
	language: string;
};

const BookFairHomepageCard = ({ homepageUrl, language }: Props) => {
	const [formattedUrl, setFormattedUrl] = useState<string>();
	const [formattedUrlFR, setFormattedUrlFR] = useState<string>();
	const [showCopiedEN, setShowCopiedEN] = useState(false);
	const [showCopiedFR, setShowCopiedFR] = useState(false);

	const handleCustomizeUrl = () => {
		trackCustomizeHomepage();
		window.open(`${window.location.origin}/customize`)
	};

	const handleView = (url: string, language: string) => {
		window.open(
			url,
			"_blank"
		);
		language === "en" ? trackViewHomepage("en") : trackViewHomepage("fr");
	};

	const handleCopyToClipboard = async (which: string) => {
    const url = which === "english" ? `${window.location.origin}/bf/` : `${window.location.origin}/bf-fr/`;
		try {
			await navigator.clipboard.writeText(
				url + homepageUrl[which]
			);
			// Displays the alert, then hides it after 2 seconds
			if ( which === "english" ) {
				setShowCopiedEN(true);
				setTimeout(() => {
					setShowCopiedEN(false);
				}, 2000);
			} else {
				setShowCopiedFR(true);
				setTimeout(() => {
					setShowCopiedFR(false);
				}, 2000);
			}
		} catch (err) {
			console.error(err);
		}
	};

	// trim url if longer than 10 characters (36 including https://scholastic.com/bf)
	useEffect(() => {
		if (homepageUrl.english) {
			const formattedUrl = homepageUrl.english;
			setFormattedUrl(formattedUrl);
		}
		if (homepageUrl.french) {
			const formattedUrlFR = homepageUrl.french;
			setFormattedUrlFR(formattedUrlFR);
		}
	}, [homepageUrl]);

	return (
		<FairDetailsCard
			title={language === "en" ? "Book Fair Homepage" : "Page d’accueil du Festival"}
			linkText={language === "en" ? "Customize" : "Personnaliser"}
			className="customize"
			handleLinkClicked={handleCustomizeUrl}
		>
			<Row><Body>{language === "en" ? "English Homepage" : "Page d’accueil en anglais"}</Body></Row>
			<OuterContainer>
				<LeftContainer>
					<StyledUrl>{`https://bookfairs.scholastic.ca/bf/${formattedUrl}`}</StyledUrl>
					
				</LeftContainer>
				<RightContainer>
				<RedTextButton handleClick={() => {
					handleCopyToClipboard('english'); 
					trackCopyUrl('en');
				}}>
						{showCopiedEN ? `${language === "en" ? "URL Copied!" : "URL copié!"}` : `${language === "en" ? "Copy URL" : "Copier l’URL"}`}
					</RedTextButton>
				</RightContainer>
			</OuterContainer>
			<OuterContainer>
			<WhiteButton handleClick={() => handleView(`/bf/${homepageUrl.english}`, "en")} className="fds-view__homepage" whiteBackground={true}>{language === "en" ? "View English Homepage" : "Voir la page d’accueil en anglais"}</WhiteButton>
			</OuterContainer>
			<Separator />
			<Row><Body>{language === "en" ? "French Homepage" : "Page d’accueil en français"}</Body></Row>
			<OuterContainer>
				<LeftContainer>
					<StyledUrl>{`https://bookfairs.scholastic.ca/bf-fr/${formattedUrlFR}`}</StyledUrl>
					
				</LeftContainer>
				<RightContainer>
				<RedTextButton handleClick={() => {
					handleCopyToClipboard('french'); 
					trackCopyUrl('fr');
				}}>
				{showCopiedFR ? `${language === "en" ? "URL Copied!" : "URL copié!"}` : `${language === "en" ? "Copy URL" : "Copier l’URL"}`}
					</RedTextButton>
				</RightContainer>
			</OuterContainer>
			
			<WhiteButton handleClick={() => handleView(`/bf-fr/${homepageUrl.french}`, "fr")} className="fds-view__homepage" whiteBackground={true}>{language === "en" ? "View French Homepage" : "Voir la page d’accueil en français"}</WhiteButton>
			
		</FairDetailsCard>
	);
};

const Row = styled.div``;

const Body = styled.p`
	font-size: 16px;
	font-weight: 600;
	line-height: 19px;
	margin-bottom: 8px;
`;

const Separator = styled.hr`
	border: 0;
    height: 1px;
    background: #d7d7d7;
    background-image: linear-gradient(to right, #ccc, #d7d7d7, #ccc);
	margin: 24px 0;
`;

const OuterContainer = styled.div`
	display: flex;
	justify-content: space-between;
	row-gap: 16px;
	flex-wrap: wrap;
	column-gap: 15px;
`;

const LeftContainer = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
`;

const RightContainer = styled.div``;

const StyledUrl = styled.div`
	font-weight: 400;
	font-size: 16px;
	line-height: 19px;
	margin-right: 5px;
`;

export default BookFairHomepageCard;
