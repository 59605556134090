import React from "react";
import styled from "styled-components";

interface Props {
	className?: any;
	value: any;
	onChange?: (value: string) => void;
	onBlur?: (value: string) => void;
	label?: string;
	type?: any;
	disabled?: boolean;
	error?: string;
	fontSize?: string;
	placeholder?: string;
	id?: string;
	required?: boolean;
	tabIndex?: number;
  height?: string;
}

const TextInput = ({
	className,
	value,
	onChange,
	onBlur,
	label,
	type,
	disabled,
	error,
	fontSize,
	placeholder,
	id,
	required,
	tabIndex,
  height,
}: Props) => {
	return (
		<StyledContainer data-disabled={disabled} className={className}>
			{label && (
				<StyledLabel htmlFor={id} id="inputLabel" className={className}>
					{label}
				</StyledLabel>
			)}
			<StyledInput
				type={type}
				id={id}
				value={value}
				onChange={(e) => {
					onChange && onChange(e.target.value);
				}}
				onBlur={(e) => {
					onBlur && onBlur(e.target.value);
				}}
				disabled={disabled}
				data-error={error}
				fontSize={fontSize}
				placeholder={placeholder}
				required={required}
				className={className}
				tabIndex={tabIndex}
        height={height}
			></StyledInput>
			{error && <ErrorLabel id="textInputErrorLabel">{error}</ErrorLabel>}
		</StyledContainer>
	);
};

interface FontProps {
	fontSize?: string;
  height?: string;
}

const StyledContainer = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	width: 100%;
	&[data-disabled=true] > #inputLabel {
		opacity: 0.2;
		cursor: not-allowed;

		&.flyers {
			opacity: 1;
		}
	}
`;

const StyledInput = styled.input<FontProps>`
	height: ${(props) => (props.height ? props.height : "40px")};
	background: #ffffff;
	border: 1px solid #919191;
	box-sizing: border-box;
	border-radius: 5px;
	font-family: museo-sans;
	font-size: ${(props) => (props.fontSize ? props.fontSize : "16px")};
	line-height: 19px;
	color: #333333;
	padding: 0px 16px;
	&:focus {
		outline: 2px auto #116eee;
	}
	&:disabled {
		opacity: 0.2;
		border: 1px solid #333333;
		cursor: not-allowed;

		&.flyers{
			opacity: 1;
		}
	}
	&[data-error] {
		border: 2px solid #e81111;
	}
	&.virtual {
		border: none;
		pointer-events: none;
		padding: 0;
	}
`;

const StyledLabel = styled.label`
	display: block;
	font-family: museo-sans;
	font-style: normal;
	font-weight: normal;
	font-size: 13px;
	line-height: 15px;
	color: #333333;
	padding-bottom: 8px;
	&.virtual {
		padding: 0px;
	}
`;

const ErrorLabel = styled(StyledLabel)`
	padding: 0;
	margin-top: 8px;
	color: #e81111;
	width: 105%;
`;

export default TextInput;
