import React, { useEffect, useState } from "react";
import styled from "styled-components";
import axios from "axios";
import LegalCopyToPrint from "./LegalCopy/LegalCopy";
import { PrintableComponent } from "../../components/PrintableComponent";
import { handleGenericErrors, handleGenericSuccess } from "../../utils/ErrorUtility";

const PrintCoaPage = () => {
	const [data, setData] = useState<any>();
	const [language, setLanguage] = useState<any>();

	// GET request
	useEffect(() => {
		const params = new URLSearchParams(document.location.search)
		if (params.get("language") === "english") {
			setLanguage("en");
		} else {
			setLanguage("fr");
		}
		axios
			.get(`${process.env.REACT_APP_ENV_BASE_URL}/api/public/coa/pdf-links/${params.get("token")}`)
			.then((res) => {
				if(res.status === 200) {
					setData(res.data);
				} else {
					handleGenericSuccess(res);
				}
			})
			.catch((error) => {
				handleGenericErrors(error);
			});
	}, []);


	if (!data) {
		return null;
	}
	return (
		<>
			<PrintCoaPageContainer>
				<PrintableComponent 
					emailedPage={true} 
					language={language}
					fairInfoData={data.fairInfo}
					chairpersonData={data.chairperson}
					organizationData={data.organization} />
				<LegalCopyToPrint 
					emailedPage={true} 
					language={language}
					fairInfoData={data.fairInfo}
					chairpersonData={data.chairperson}
					organizationData={data.organization} />
			</PrintCoaPageContainer>
		</>
	);
};

const PrintCoaPageContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 1in;
`;

export default PrintCoaPage;