import React from "react";
import styled from "styled-components";

interface Props {
	className?: string;
	children?: any;
	lang?: string;
}
const Card = ({ children, className, lang }: Props) => {
	return (
		<CardContainer $lang={lang} className={className}>
			{children}
		</CardContainer>
	);
};
const CardContainer = styled.div<{ $lang?: string }>`
	padding: 0 24px 32px;	
	box-sizing: border-box;	
	border-radius: 0px;
	background: #ffffff;
	box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
	position: relative;
	height: ${(props) => (props.$lang === "fr" ? "629px" : "511px")};
	width: 440px;
	@media (max-width: 1279px) {
		height: ${(props) => (props.$lang === "fr" ? "672px" : "585px")};
		width: 325px;
	}
	@media (max-width: 959px) {
		height: auto;
		width: unset;
	}
`;
export default Card;
