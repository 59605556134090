import styled from "styled-components";
import FairDetailsCard from "./FairDetailsCard";
import moment from "moment";

type Props = {
	startDate: string;
	endDate: string;
	setupDate: string;
	language: string;
};

const FairDatesCard = ({ startDate, endDate, setupDate, language }: Props) => {
	const englishDateFormat = "MM/DD/YY";
	const frenchDateFormat = "YYYY-MM-DD";
	
	if ( language === "en" ) {
		return (
				<FairDetailsCard title={"Fair Dates"}>
						<Body>Set-Up Date: <span>{moment(setupDate).format(englishDateFormat).toString()}</span></Body>
						<Body>Fair Start Date: <span>{moment(startDate).format(englishDateFormat).toString()}</span></Body>
						<Body>Fair End Date: <span>{moment(endDate).format(englishDateFormat).toString()}</span></Body>
				</FairDetailsCard>	
		);
	} else {
		return (
			<FairDetailsCard title={"Dates du Festival"}>
				<Body>Date d’installation : <span>{moment(setupDate).format(frenchDateFormat).toString()}</span></Body>
				<Body>Date de début du Festival : <span>{moment(startDate).format(frenchDateFormat).toString()}</span></Body>
				<Body>Date de fin du Festival : <span>{moment(endDate).format(frenchDateFormat).toString()}</span></Body>
			</FairDetailsCard>
		)
	}
};

const Body = styled.div`
	font-weight: 600;
	font-size: 16px;
	line-height: 19px;
	color: #333333;
	overflow: hidden;
	margin-bottom: 16px;
	& span {
		font-weight: 400;
		display: block;
	}
`;

export default FairDatesCard;