import React from "react";
import styled from "styled-components";
import TwitterIcon from "../../images/Twitter.svg";
import { trackSocialButtonClick, trackSocialButtonEnglish, trackSocialButtonFrench } from "../../DumbleData";

interface Props {
	customUrl: string;
	pageName?: string;
	language?: string;
}

const TwitterShareButton = ({ customUrl, pageName, language }: Props) => {
	const msg =
		language === "en"
			? "Get ready! The Scholastic Book Fair is coming our way. Choosing their own books inspires kids to become lifelong readers. AND—as always—every purchase at the Fair benefits our school. Visit our Book Fair homepage to learn more!"
			: "Préparez-vous! Le Festival du Livre Scholastic arrive à grands pas. Le fait de choisir leurs propres livres incite les enfants à devenir des lecteur·rices pour la vie. Et, comme toujours, chaque achat effectué au Festival profite à notre école. Visitez la page d'accueil du Festival pour en savoir plus!";
	return (
		<TwitterShareButtonStyled
			href={`https://x.com/share?ref_src=twsrc%5Etfw&text=${msg}&url=${window.location.origin}/bf/${customUrl}`}
			target="_blank"
			className={pageName}
			onClick={() => {
				if (pageName === "liveHomepage") {
					trackSocialButtonClick();
				} else if (language === "en") {
					trackSocialButtonEnglish();
				} else {
					trackSocialButtonFrench();
				}
			}}
		>
			<TwitterIconStyled
				src={TwitterIcon}
				className={pageName}
				alt="Share Live Homepage to Twitter"
			/>
		</TwitterShareButtonStyled>
	);
};

const TwitterShareButtonStyled = styled.a`
	background-color: #000000;
	border: none;
	border-radius: 56px;
	display: flex;
	align-items: center;
	width: 56px;
	&.liveHomepage {
		height: 40px;
		width: 40px;
	}
	&.shareHomepage {
		height: 32px;
		width: 32px;
	}
	&:focus {
		outline: 2px solid #333333;
		outline-offset: 2px;
	}
`;

const TwitterIconStyled = styled.img`
	&.liveHomepage {
		height: 40px;
		width: 20px;
		margin-left: 10px;
	}
	&.shareHomepage {
		height: 17px;
		width: 17px;
		margin-left: 8px;
	}
	width: 100%;
	height: 25px;
`;

export default TwitterShareButton;
