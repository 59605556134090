import React from "react";
import "../../styles/globalfooter.css";
import { useConfig } from "../../providers/ConfigContext";

interface Props {
	language: string;
}

const GlobalFooter = ({
	language
}: Props) => {
	const { damAssetsOrigin } = useConfig();
	return (
		<div className="footer">
			<footer className="global-footer site-nav">
				<a
					href="https://www.scholastic.ca/"
					className="scholastic-logo"
					data-element-linkname="footer_schoLogo"
				>
					<img
						src={`${damAssetsOrigin}enterprise/asset.jpg/footer/scholastic-logo-white-outline.png`}
						alt="Scholastic"
					/>
				</a>
				<div className="footer-links">
					<a
						href={language === "en" ? "https://www.scholastic.ca/aboutscholastic/privacy.php" : "https://www.scholastic.ca/editions/lentreprise/confidentialite.php"}
						target="_blank"
						rel="noopener  noreferrer"
						className="footer-link footer-link--privacy-policy"
						data-element-linkname="footer_links"
					>
						<span className="footer-link--privacy-policy__underline">
							{language === "en" ? "Privacy Policy" : "Politique de confidentialité"}
						</span>
					</a>
					<a
						href={language === "en" ? "https://www.scholastic.ca/aboutscholastic/webterms.php" : "https://www.scholastic.ca/editions/lentreprise/modalites-dutilisation.php"}
						target="_blank"
						rel="noopener noreferrer"
						className="footer-link"
						data-element-linkname="footer_links"
					>
						{language === "en" ? "Terms of Use" : "Modalités d'utilisation"}
					</a>
					<a
						href="https://www.scholastic.com/content/corp-home/donotsell.html"
						rel="noopener noreferrer"
						referrerPolicy="no-referrer-when-downgrade"
						target="_blank"
						className="footer-link"
						data-element-linkname="footer_links"
					>
						Do Not Sell My Info
					</a>
					<a
						href={language === "en" ? "https://www.scholastic.ca/aboutscholastic/index.php" : "https://www.scholastic.ca/editions/lentreprise/"}
						className="footer-link"
						data-element-linkname="footer_links"
					>
						{language === "en" ? "About Scholastic" : "L'entreprise"}
					</a>
					<p
						className="footer-description"
						data-element-linkname="footer_description"
					>
						™ &reg; &amp; &copy;{" "}
						<span id="copyright">{new Date().getFullYear()}</span>{" "}
						Scholastic Inc. All Rights Reserved.
					</p>
				</div>
			</footer>
		</div>
	);
};

export default GlobalFooter;
