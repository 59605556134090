import { disableScrolling, enableScrolling } from "../../utils/ToggleScrolling";
import { WhiteButton } from "../Button";
import Heading from "../Heading";
import { useState } from "react";
import styled from "styled-components";
import LeavingScholasticModal from "./LeavingScholasticModal";
import { trackSignUpVolunteersButton } from "../../DumbleData";

interface Props {
	volunteerForm: string;
	language?: string;
}

const VolunteerSection = ({ volunteerForm, language }: Props) => {
	const [modalOpen, setModalOpen] = useState<boolean>(false);

	// Opens the modal
	const openModal = (e: any) => {
		e.preventDefault();
		setModalOpen(true);
		disableScrolling();
		trackSignUpVolunteersButton();
	};

	// Closes the modal
	const closeModal = () => {
		setModalOpen(false);
		enableScrolling();
	};

	return (
		<>
			<StyledVolunteerSection>
				<StyledHeading>
					{language === "en"
						? "We're Looking for Volunteers!"
						: "Nous cherchons des bénévoles!"}
				</StyledHeading>
				<StyledWhiteButton
					href={volunteerForm}
					handleClick={openModal}
					target="_blank"
				>
					{language === "en"
						? "Sign Up"
						: "M'inscrire"}
				</StyledWhiteButton>
			</StyledVolunteerSection>
			{modalOpen && (
				<LeavingScholasticModal
					closeModal={closeModal}
					volunteerForm={volunteerForm}
				/>
			)}
		</>
	);
};

const StyledVolunteerSection = styled.div`
	box-sizing: border-box;
	background-color: #1226aa;
	display: flex;
	gap: 16px;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding: 48px 40px;
`;

const StyledHeading = styled(Heading)`
	color: #ffffff;
	font-size: 32px;
	font-weight: 600;
	text-transform: uppercase;
	letter-spacing: 1px;
	padding-bottom: 0;
	margin: 0;
	@media (max-width: 719px) {
		font-size: 28px;
		letter-spacing: 1.5px;
	}
`;

const StyledWhiteButton = styled(WhiteButton)`
	@media (max-width: 719px) {
		width: 100%;
		width: -webkit-fill-available;
		width: -moz-fill-available;
	}
`;

export default VolunteerSection;
